var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard-editor-container" },
    [
      _c(
        "div",
        { staticClass: "data-analysis-wrapper" },
        [
          _c("i", { staticClass: "el-icon-view" }),
          _vm._v(" "),
          _c("div", { staticClass: "title" }, [_vm._v("数据概览")]),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "danger" }, on: { click: _vm.gotoData } },
            [_vm._v("进入班车数据作战中心")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "danger" }, on: { click: _vm.gotoCarpoolData } },
            [_vm._v("进入拼车数据作战中心")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("panel-group", {
        attrs: {
          "show-more": true,
          "count-data": _vm.countData,
          "sms-detail": _vm.smsDetail
        }
      }),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "count", attrs: { gutter: 30 } },
        [
          _c("el-col", { staticClass: "count-item", attrs: { span: 4 } }, [
            _c("div", { staticClass: "_div _item" }, [
              _c(
                "div",
                { staticClass: "title" },
                [
                  _c("h5", [_vm._v("今日客量")]),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text", icon: "el-icon-refresh" },
                      on: { click: _vm.getKeLiang }
                    },
                    [_vm._v("刷新")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("div", [
                _c("label", [_vm._v("扫码购票")]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.jinRiKeLiang.扫码购票))])
              ]),
              _vm._v(" "),
              _c("div", [
                _c("label", [_vm._v("APP购票")]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.jinRiKeLiang.APP购票))])
              ]),
              _vm._v(" "),
              _c("div", [
                _c("label", [_vm._v("公众号购票")]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.jinRiKeLiang.公众号购票))])
              ]),
              _vm._v(" "),
              _c("div", [
                _c("label", [_vm._v("小件快递")]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.jinRiKeLiang.小件快递))])
              ]),
              _vm._v(" "),
              _c("div", [
                _c("label", [_vm._v("总计")]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.jinRiKeLiang.总计))])
              ])
            ])
          ]),
          _vm._v(" "),
          _c(
            "el-col",
            {
              staticStyle: { position: "relative", "box-sizing": "border-box" },
              attrs: { span: 20 }
            },
            [
              _c(
                "el-select",
                {
                  staticClass: "select-filter",
                  staticStyle: { width: "100px" },
                  attrs: { size: "mini", placeholder: "请选择" },
                  on: { change: _vm.handleSetLineChartData1 },
                  model: {
                    value: _vm.value1,
                    callback: function($$v) {
                      _vm.value1 = $$v
                    },
                    expression: "value1"
                  }
                },
                _vm._l(_vm.options, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c("line-chart", { attrs: { "chart-data": _vm.chartData } })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "count", attrs: { gutter: 30 } },
        [
          _c("el-col", { staticClass: "count-item", attrs: { span: 4 } }, [
            _c("div", { staticClass: "_div _item" }, [
              _c(
                "div",
                { staticClass: "title" },
                [
                  _c("h5", [_vm._v("今日收益")]),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text", icon: "el-icon-refresh" },
                      on: { click: _vm.getShouYi }
                    },
                    [_vm._v("刷新")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("div", [
                _c("label", [_vm._v("扫码购票")]),
                _vm._v(" "),
                _c("span", [_vm._v("¥" + _vm._s(_vm.jinRiKeShouYi.扫码购票))])
              ]),
              _vm._v(" "),
              _c("div", [
                _c("label", [_vm._v("APP购票")]),
                _vm._v(" "),
                _c("span", [_vm._v("¥" + _vm._s(_vm.jinRiKeShouYi.APP购票))])
              ]),
              _vm._v(" "),
              _c("div", [
                _c("label", [_vm._v("公众号购票")]),
                _vm._v(" "),
                _c("span", [_vm._v("¥" + _vm._s(_vm.jinRiKeShouYi.公众号购票))])
              ]),
              _vm._v(" "),
              _c("div", [
                _c("label", [_vm._v("小件快递")]),
                _vm._v(" "),
                _c("span", [_vm._v("¥" + _vm._s(_vm.jinRiKeShouYi.小件快递))])
              ]),
              _vm._v(" "),
              _c("div", [
                _c("label", [_vm._v("总计")]),
                _vm._v(" "),
                _c("span", [_vm._v("¥" + _vm._s(_vm.jinRiKeShouYi.总计))])
              ])
            ])
          ]),
          _vm._v(" "),
          _c(
            "el-col",
            {
              staticStyle: { position: "relative", "box-sizing": "border-box" },
              attrs: { span: 20 }
            },
            [
              _c(
                "el-select",
                {
                  staticClass: "select-filter",
                  staticStyle: { width: "100px" },
                  attrs: { size: "mini", placeholder: "请选择" },
                  on: { change: _vm.handleSetLineChartData2 },
                  model: {
                    value: _vm.value2,
                    callback: function($$v) {
                      _vm.value2 = $$v
                    },
                    expression: "value2"
                  }
                },
                _vm._l(_vm.options, function(item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              ),
              _vm._v(" "),
              _c("line-chart", { attrs: { "chart-data": _vm.chartData2 } })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }