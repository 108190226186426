"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  props: {
    openLinePriceDialog: {
      type: Boolean,
      default: false
    },
    rawData: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      // rawData: {
      //   money: undefined,
      //   specialAreaIsMarkup: 0,
      //   cancelMoneyIsCharge: 0
      // },
      rules: {
        money: [{
          required: true,
          message: "请输入寄件费用",
          trigger: "blur"
        }]
      }
    };
  },
  methods: {
    handleDialogClose: function handleDialogClose() {
      this.$emit("closepop"); //通知父组件改变。
    },
    onSubmit: function onSubmit() {
      var _this = this;

      console.log(this.rawData);
      this.$refs["rawData"].validate(function (valid) {
        if (valid) {
          _this.$emit("submit", _this.rawData);

          _this.handleDialogClose();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }
  }
};
exports.default = _default2;