var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-width": "150px" }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "发放人群", prop: "couponSendType" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.couponSendType,
                    callback: function($$v) {
                      _vm.couponSendType = $$v
                    },
                    expression: "couponSendType"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("指定用户")]),
                  _vm._v(" "),
                  _c("el-radio", { attrs: { label: 2 } }, [_vm._v("表格导入")])
                ],
                1
              ),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _vm.couponSendType == 1
                ? _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: {
                      placeholder: "请输入指定用户手机号",
                      type: "number"
                    },
                    model: {
                      value: _vm.form.phone,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "phone", $$v)
                      },
                      expression: "form.phone"
                    }
                  })
                : _c(
                    "el-upload",
                    {
                      ref: "upload",
                      staticClass: "upload-demo",
                      attrs: {
                        action: "https://jsonplaceholder.typicode.com/posts/",
                        "on-success": _vm.handleChange,
                        "file-list": _vm.fileList,
                        limit: 1,
                        accept: ".xls,.xlsx"
                      }
                    },
                    [
                      _c(
                        "el-button",
                        { attrs: { size: "small", type: "primary" } },
                        [_vm._v("点击上传")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "el-upload__tip",
                          attrs: { slot: "tip" },
                          slot: "tip"
                        },
                        [
                          _vm._v(
                            "\n          只能上传.xls、.xlsx文件，且不超过500行\n        "
                          )
                        ]
                      )
                    ],
                    1
                  )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "优惠券礼包选择", prop: "couponList" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "选择优惠券礼包" },
                  on: { change: _vm.selectCouponChange },
                  model: {
                    value: _vm.couponList,
                    callback: function($$v) {
                      _vm.couponList = $$v
                    },
                    expression: "couponList"
                  }
                },
                _vm._l(_vm.listAllCoupon, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "发送推送", prop: "sms" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.sms,
                    callback: function($$v) {
                      _vm.sms = $$v
                    },
                    expression: "sms"
                  }
                },
                [_c("el-radio", { attrs: { label: 1 } }, [_vm._v("短信")])],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "sms-style" }, [
                _vm._v(
                  "\n        恭喜您获得" +
                    _vm._s(_vm.form.service) +
                    "乘车优惠券，快打开新干线哈喽优行公众号查看吧\n      "
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "备注", prop: "remark" } },
            [
              _c("el-input", {
                staticStyle: { width: "200px" },
                attrs: { placeholder: "请输入备注" },
                model: {
                  value: _vm.form.remark,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "remark", $$v)
                  },
                  expression: "form.remark"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.submitForm } },
            [_vm._v("发 送")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }