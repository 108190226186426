var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 10 } },
        [
          _c(
            "el-col",
            { attrs: { span: 4, xs: 24 } },
            [
              _c("el-card", { staticClass: "box-card" }, [
                _c(
                  "div",
                  {
                    staticClass: "clearfix",
                    attrs: { slot: "header" },
                    slot: "header"
                  },
                  [_c("span", [_vm._v("用户详细信息")])]
                ),
                _vm._v(" "),
                _c("div", [
                  _c("div", { staticClass: "text-center" }, [
                    _c("img", { attrs: { src: _vm.user.avatar, alt: "" } }),
                    _vm._v(" "),
                    _c("div", { staticClass: "user_name" }, [
                      _vm._v(_vm._s(_vm.user.netName))
                    ])
                  ]),
                  _vm._v(" "),
                  _c("ul", { staticClass: "list-group list-group-striped" }, [
                    _c("li", { staticClass: "list-group-item" }, [
                      _vm._v("\n                账号状态\n                "),
                      _c("div", { staticClass: "pull-right" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("userStatusFilter")(_vm.user.userStatus)
                          )
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "list-group-item" }, [
                      _vm._v("\n                ID\n                "),
                      _c("div", { staticClass: "pull-right" }, [
                        _vm._v(_vm._s(_vm.user.id))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "list-group-item" }, [
                      _vm._v("\n                订单量\n                "),
                      _c("div", { staticClass: "pull-right" }, [
                        _vm._v(_vm._s(_vm.user.orderNum || "--"))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "list-group-item" }, [
                      _vm._v("\n                手机号码\n                "),
                      _c("div", { staticClass: "pull-right" }, [
                        _vm._v(_vm._s(_vm.user.phone || "--"))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "list-group-item" }, [
                      _vm._v("\n                身份证\n                "),
                      _c("div", { staticClass: "pull-right" }, [
                        _vm._v(_vm._s(_vm.user.idcard || "--"))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "list-group-item" }, [
                      _vm._v(
                        "\n                最近登录时间\n                "
                      ),
                      _c("div", { staticClass: "pull-right" }, [
                        _vm._v(_vm._s(_vm.user.latestLandingTime || "--"))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "list-group-item" }, [
                      _vm._v("\n                注册时间\n                "),
                      _c("div", { staticClass: "pull-right" }, [
                        _vm._v(_vm._s(_vm.user.createdMsg))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "list-group-item" }, [
                      _vm._v("\n                手机型号\n                "),
                      _c("div", { staticClass: "pull-right" }, [
                        _vm._v(_vm._s(_vm.user.mobilePhoneModel || "--"))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", { staticClass: "list-group-item" }, [
                      _vm._v("\n                APP版本\n                "),
                      _c("div", { staticClass: "pull-right" }, [
                        _vm._v(_vm._s(_vm.user.appVersion || "--"))
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "close_account" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.disableAccount }
                        },
                        [_vm._v("封号")]
                      )
                    ],
                    1
                  )
                ])
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 20, xs: 24 } },
            [
              _c(
                "el-card",
                [
                  _c(
                    "el-tabs",
                    {
                      model: {
                        value: _vm.activeTab,
                        callback: function($$v) {
                          _vm.activeTab = $$v
                        },
                        expression: "activeTab"
                      }
                    },
                    [
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "订单记录", name: "userinfo" } },
                        [
                          _c(
                            "el-table",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { data: _vm.tableData, border: "" }
                            },
                            [
                              _c("el-table-column", {
                                attrs: { prop: "orderNo", label: "订单编号" }
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: { prop: "channel", label: "来源" }
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: { prop: "address", label: "司机/电话" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(scope.row.driverNickName) +
                                            "/\n" +
                                            _vm._s(scope.row.driverPhone) +
                                            "\n                  "
                                        )
                                      ]
                                    }
                                  }
                                ])
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "businessType",
                                  label: "订单类型"
                                }
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "startStationName",
                                  label: "上车点"
                                }
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "endStationName",
                                  label: "下车点"
                                }
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: { prop: "status", label: "状态" }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("pagination", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.total > 0,
                                expression: "total>0"
                              }
                            ],
                            attrs: {
                              total: _vm.total,
                              page: _vm.queryParams.pageNum,
                              limit: _vm.queryParams.pageSize
                            },
                            on: {
                              "update:page": function($event) {
                                return _vm.$set(
                                  _vm.queryParams,
                                  "pageNum",
                                  $event
                                )
                              },
                              "update:limit": function($event) {
                                return _vm.$set(
                                  _vm.queryParams,
                                  "pageSize",
                                  $event
                                )
                              },
                              pagination: _vm.getList
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "常用乘车人", name: "resetPwd" } },
                        [
                          _c(
                            "el-table",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { data: _vm.tableData2, border: "" }
                            },
                            [
                              _c("el-table-column", {
                                attrs: { prop: "friendName", label: "姓名" }
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: { prop: "friendType", label: "类型" }
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "friendIdcard",
                                  label: "身份证号"
                                }
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: { prop: "friendPhone", label: "手机号" }
                              }),
                              _vm._v(" "),
                              _c("el-table-column", {
                                attrs: { prop: "createdMsg", label: "添加时间" }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }