"use strict";

var _interopRequireDefault = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireDefault");

require("D:\\Projects\\hello-free-back\\node_modules\\core-js\\modules\\es6.array.iterator.js");

require("D:\\Projects\\hello-free-back\\node_modules\\core-js\\modules\\es6.promise.js");

require("D:\\Projects\\hello-free-back\\node_modules\\core-js\\modules\\es6.object.assign.js");

require("D:\\Projects\\hello-free-back\\node_modules\\core-js\\modules\\es7.promise.finally.js");

var _vue = _interopRequireDefault(require("vue"));

var _elementUi = _interopRequireDefault(require("element-ui"));

var _jsCookie = _interopRequireDefault(require("js-cookie"));

var _vueClipboard = _interopRequireDefault(require("vue-clipboard2"));

require("normalize.css/normalize.css");

require("./assets/styles/element-variables.scss");

require("./assets/styles/iconfont.css");

require("./mixin");

require("@/assets/styles/index.scss");

require("@/assets/styles/ruoyi.scss");

var _App = _interopRequireDefault(require("./App"));

var _store = _interopRequireDefault(require("./store"));

var _router = _interopRequireDefault(require("./router"));

var _permission = _interopRequireDefault(require("./directive/permission"));

require("./assets/icons");

require("./permission");

var _data = require("@/api/system/dict/data");

var _config = require("@/api/system/config");

var _ruoyi2 = require("@/utils/ruoyi");

var _Pagination = _interopRequireDefault(require("@/components/Pagination"));

var _watermark = _interopRequireDefault(require("./utils/watermark.js"));

// a modern alternative to CSS resets
// 公共混入
// global css
// ruoyi css
// icon
// permission control
// 水印
_vue.default.prototype.$watermark = _watermark.default; // 全局方法挂载

_vue.default.prototype.getDicts = _data.getDicts;
_vue.default.prototype.getConfigKey = _config.getConfigKey;
_vue.default.prototype.parseTime = _ruoyi2.parseTime;
_vue.default.prototype.resetForm = _ruoyi2.resetForm;
_vue.default.prototype.addDateRange = _ruoyi2.addDateRange;
_vue.default.prototype.selectDictLabel = _ruoyi2.selectDictLabel;
_vue.default.prototype.download = _ruoyi2.download;
_vue.default.prototype.handleTree = _ruoyi2.handleTree;
_vue.default.prototype.lastSubstring = _ruoyi2.lastSubstring;
_vue.default.prototype.phoneFormat = _ruoyi2.phoneFormat;

_vue.default.prototype.msgSuccess = function (msg) {
  this.$message({
    showClose: true,
    message: msg,
    type: "success"
  });
};

_vue.default.prototype.msgError = function (msg) {
  this.$message({
    showClose: true,
    message: msg,
    type: "error"
  });
};

_vue.default.prototype.msgInfo = function (msg) {
  this.$message.info(msg);
}; // 全局组件挂载


_vue.default.component('Pagination', _Pagination.default);

_vue.default.use(_vueClipboard.default);

_vue.default.use(_permission.default);
/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online! ! !
 */


_vue.default.use(_elementUi.default, {
  size: _jsCookie.default.get('size') || 'medium' // set element-ui default size

});

_vue.default.config.productionTip = false;
new _vue.default({
  el: '#app',
  router: _router.default,
  store: _store.default,
  render: function render(h) {
    return h(_App.default);
  }
});