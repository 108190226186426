"use strict";

var _interopRequireDefault = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.listType = listType;
exports.getType = getType;
exports.addType = addType;
exports.updateType = updateType;
exports.delType = delType;
exports.exportType = exportType;
exports.optionselect = optionselect;

var _request = _interopRequireDefault(require("@/utils/request"));

// 查询字典类型列表
function listType(query) {
  return (0, _request.default)({
    url: '/system/dict/type/list',
    method: 'get',
    params: query
  });
} // 查询字典类型详细


function getType(dictId) {
  return (0, _request.default)({
    url: '/system/dict/type/' + dictId,
    method: 'get'
  });
} // 新增字典类型


function addType(data) {
  return (0, _request.default)({
    url: '/system/dict/type',
    method: 'post',
    data: data
  });
} // 修改字典类型


function updateType(data) {
  return (0, _request.default)({
    url: '/system/dict/type',
    method: 'put',
    data: data
  });
} // 删除字典类型


function delType(dictId) {
  return (0, _request.default)({
    url: '/system/dict/type/' + dictId,
    method: 'delete'
  });
} // 导出字典类型


function exportType(query) {
  return (0, _request.default)({
    url: '/system/dict/type/export',
    method: 'get',
    params: query
  });
} // 获取字典选择框列表


function optionselect() {
  return (0, _request.default)({
    url: '/system/dict/type/optionselect',
    method: 'get'
  });
}