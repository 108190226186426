"use strict";

var _interopRequireDefault = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime-corejs2/helpers/objectSpread2"));

require("core-js/modules/es6.regexp.split");

var _carpool = require("@/api/business/carpool");

var _utils = require("@/utils");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    openLinePriceDialog: {
      type: Boolean,
      default: false
    },
    operationData: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      showTimePriceConfig: false,
      timeConfigLists: [],
      loading: false,
      activeName: "城际拼车",
      formDialog: {
        name: "",
        radio: 2,
        carpoolLineConfigCJPC: {},
        carpoolLinePayConfigListCJPC: [],
        carpoolLineRefundConfigListCJPC: [],
        carpoolLinePayConfigTimeListCJPC: [],
        carpoolLinePayConfigTimeIsLadderListCJPC: [],
        carpoolLineConfigCJBC: {},
        carpoolLinePayConfigListCJBC: [],
        carpoolLineRefundConfigListCJBC: [],
        carpoolLinePayConfigTimeListCJBC: []
      }
    };
  },
  created: function created() {
    this.getPriceConfig();
    this.activeName = this.operationData.serviceType.split(",")[0];
    console.log(this.operationData.serviceType);
  },
  methods: {
    showTimePriceConfigFn: function showTimePriceConfigFn() {
      this.showTimePriceConfig = true;
    },
    addTimeItemJieTi: function addTimeItemJieTi() {
      this.formDialog.carpoolLinePayConfigTimeIsLadderListCJPC.push({
        startTime: "",
        endTime: "",
        dataString: [{
          personNum: 1
        }]
      });
    },
    delTimeItemJieTi: function delTimeItemJieTi(idx) {
      this.formDialog.carpoolLinePayConfigTimeIsLadderListCJPC.splice(idx, 1);
    },
    addTimeItemConfigJieTi: function addTimeItemConfigJieTi(i) {
      console.log(i, "----");
      this.formDialog.carpoolLinePayConfigTimeIsLadderListCJPC[i].dataString.push({
        personNum: "",
        unitPrice: ""
      });
    },
    delTimeItemConfigJieTi: function delTimeItemConfigJieTi(i, ii) {
      this.formDialog.carpoolLinePayConfigTimeIsLadderListCJPC[i].dataString.splice(ii, 1);
    },
    addTimeItemConfig: function addTimeItemConfig(i) {
      this.formDialog.carpoolLinePayConfigTimeListCJPC[i].dataString.push({
        personNum: "",
        unitPrice: ""
      });
    },
    addTimeItem: function addTimeItem() {
      this.formDialog.carpoolLinePayConfigTimeListCJPC.push({
        startTime: "",
        endTime: "",
        dataString: [{
          personNum: 1
        }]
      });
    },
    delTimeItem: function delTimeItem(idx) {
      this.formDialog.carpoolLinePayConfigTimeListCJPC.splice(idx, 1);
    },
    delTimeItemConfig: function delTimeItemConfig(i, ii) {
      this.formDialog.carpoolLinePayConfigTimeListCJPC[i].dataString.splice(ii, 1);
    },
    addTimeItemBc: function addTimeItemBc() {
      this.formDialog.carpoolLinePayConfigTimeListCJBC.push({
        startTime: "",
        endTime: "",
        dataString: [{
          personNum: 1
        }]
      });
    },
    delTimeItemBc: function delTimeItemBc(idx) {
      this.formDialog.carpoolLinePayConfigTimeListCJBC.splice(idx, 1);
    },
    addTimeItemConfigBc: function addTimeItemConfigBc(i) {
      console.log(i, this.formDialog.carpoolLinePayConfigTimeListCJBC, "this.formDialog.carpoolLinePayConfigTimeListCJBC");
      this.formDialog.carpoolLinePayConfigTimeListCJBC[i].dataString.push({
        unitPrice: ""
      });
    },
    delTimeItemConfigBc: function delTimeItemConfigBc(i, ii) {
      this.formDialog.carpoolLinePayConfigTimeListCJBC[i].dataString.splice(ii, 1);
    },
    getPriceConfig: function getPriceConfig() {
      var _this = this;

      this.loading = true;
      (0, _carpool.getPayConfigAndRefundConfig)({
        lineId: this.operationData.id,
        carpoolLineConfigCjpcVersion: this.operationData.carpoolLineConfigCjpcVersion,
        carpoolLineConfigCjbcVersion: this.operationData.carpoolLineConfigCjbcVersion
      }).then(function (res) {
        if (res.code === 200) {
          var generateLists = ["carpoolLinePayConfigListCJBC", "carpoolLinePayConfigListCJPC", "carpoolLineConfigCJBC", "carpoolLineConfigCJPC", "carpoolLineRefundConfigListCJBC", "carpoolLinePayConfigTimeListCJBC", "carpoolLineRefundConfigListCJPC", "carpoolElectricFenceListDownCJPC", "carpoolElectricFenceListUpCJPC", "carpoolElectricFenceListDownCJBC", "carpoolElectricFenceListUpCJBC", "carpoolLinePayConfigTimeListCJPC"];
          generateLists.forEach(function (item) {
            if (!res.data[item] || res.data[item].length === 0) {
              if (item === "carpoolLineRefundConfigListCJBC" || item === "carpoolLineRefundConfigListCJPC") {
                res.data[item] = [{}];
              } else if (item === "carpoolLinePayConfigListCJPC") {
                res.data[item] = [{
                  personNum: 1
                }];
              } else if (item === "carpoolLineConfigCJBC") {
                res.data[item] = {
                  money: null
                };
              } else {
                res.data[item] = [];
              }
            }
          });
          res.data.carpoolLinePayConfigTimeIsLadderListCJPC = _this.generateDataString(res.data.carpoolLinePayConfigTimeIsLadderListCJPC);
          res.data.carpoolLinePayConfigTimeListCJPC = _this.generateDataString(res.data.carpoolLinePayConfigTimeListCJPC);
          res.data.carpoolLinePayConfigTimeListCJBC = _this.generateDataString(res.data.carpoolLinePayConfigTimeListCJBC);
          _this.formDialog = res.data;
          console.log(_this.formDialog, "赋值操作：=》》》");
          _this.loading = false;
        } else {
          _this.$message.error(res.msg);

          _this.loading = false;
        }
      });
    },
    generateConfigInitData: function generateConfigInitData(obj) {
      if (!obj || obj.length === 0) {
        obj = [{}];
        return obj;
      }
    },
    generateDataString: function generateDataString(obj) {
      if (obj) {
        obj.map(function (item, index) {
          if ("dataString" in item) {
            item.dataString = JSON.parse(item.dataString);
          } else {
            item.dataString = [];
          }
        });
      } else {
        // obj = [{ dataString: [{ personNum: 1 }] }];
        obj = [];
      }

      return obj;
    },
    checkeValue: function checkeValue(value, idx) {
      var len = 0;
      this.formDialog.carpoolLinePayConfigListCJPC.map(function (item) {
        if (item.personNum * 1 === value * 1) {
          len++;
        }
      });
      console.log(value, len, this.formDialog.carpoolLinePayConfigListCJPC, "this.formDialog.carpoolLinePayConfigListCJPC");

      if (len > 1) {
        this.formDialog.carpoolLinePayConfigListCJPC[idx].personNum = undefined;
        this.$message.warning("阶梯人数已存在，请勿重复添加！");
      }
    },
    addItem: function addItem() {
      this.formDialog.carpoolLinePayConfigListCJPC.push({
        unitPrice: "",
        personNum: ""
      });
    },
    delItem: function delItem(idx) {
      this.formDialog.carpoolLinePayConfigListCJPC.splice(idx, 1);
    },
    addRefundItem: function addRefundItem() {
      this.formDialog.carpoolLineRefundConfigListCJPC.push({
        startTime: "",
        endTime: "",
        fee: ""
      });
    },
    delRefundItem: function delRefundItem(idx) {
      this.formDialog.carpoolLineRefundConfigListCJPC.splice(idx, 1);
    },
    addRefundItemBc: function addRefundItemBc() {
      this.formDialog.carpoolLineRefundConfigListCJBC.push({
        startTime: "",
        endTime: "",
        fee: ""
      });
    },
    delRefundItemBc: function delRefundItemBc(idx) {
      this.formDialog.carpoolLineRefundConfigListCJBC.splice(idx, 1);
    },
    handleDialogClose: function handleDialogClose() {
      this.$emit("closepop"); //通知父组件改变。
    },
    // 打开加载层
    openLoading: function openLoading() {
      this.loading = this.$loading({
        lock: true,
        text: "加载中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
    },
    onSubmit: function onSubmit() {
      var _this2 = this;

      // this.openLoading();
      this.loading = true;
      var copyFormDialog = (0, _utils.deepClone)(this.formDialog);
      console.log(copyFormDialog, "this.formDialog");
      copyFormDialog.carpoolLinePayConfigTimeListCJBC.map(function (item, index) {
        if ("dataString" in item) {
          item.dataString = JSON.stringify(item.dataString);
        }
      });
      copyFormDialog.carpoolLinePayConfigTimeListCJPC.map(function (item, index) {
        if ("dataString" in item) {
          item.dataString = JSON.stringify(item.dataString);
        }
      });
      copyFormDialog.carpoolLinePayConfigTimeIsLadderListCJPC.map(function (item, index) {
        if ("dataString" in item) {
          item.dataString = JSON.stringify(item.dataString);
        }
      });
      (0, _carpool.updatePayConfigAndPayRefundConfig)((0, _objectSpread2.default)({}, this.formDialog, {}, copyFormDialog, {
        lineId: this.operationData.id,
        serviceType: this.activeName
      })).then(function (res) {
        if (res.code === 200) {
          if (_this2.activeName === "城际拼车") {
            _this2.operationData.carpoolLineConfigCjpcVersion = res.data.carpoolLineConfigCjpcVersion;
          }

          if (_this2.activeName === "城际包车") {
            _this2.operationData.carpoolLineConfigCjbcVersion = res.data.carpoolLineConfigCjbcVersion;
          }

          _this2.$message.success(res.msg);

          _this2.handleDialogClose();
        } else {
          _this2.$message.error(res.msg);
        }

        _this2.loading = false;
      });
    }
  }
};
exports.default = _default;