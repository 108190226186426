"use strict";

var _interopRequireDefault = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.listDriver = listDriver;
exports.getDriver = getDriver;
exports.addDriver = addDriver;
exports.updateDriver = updateDriver;
exports.delDriver = delDriver;
exports.exportDriver = exportDriver;
exports.addUpdateDriverWithdrawalConfig = addUpdateDriverWithdrawalConfig;
exports.getDriverWithdrawalConfigByCompanyId = getDriverWithdrawalConfigByCompanyId;
exports.getApplyList = getApplyList;
exports.passApply = passApply;
exports.getDriverInfo = getDriverInfo;
exports.getDriverOrder = getDriverOrder;
exports.getDriverBillList = getDriverBillList;
exports.freezeDriver = freezeDriver;

var _request = _interopRequireDefault(require("@/utils/request"));

// 查询司机列表
function listDriver(query) {
  return (0, _request.default)({
    url: '/system/driver/list',
    method: 'get',
    params: query
  });
} // 查询司机详细


function getDriver(id) {
  return (0, _request.default)({
    url: '/system/driver/' + id,
    method: 'get'
  });
} // 新增司机


function addDriver(data) {
  return (0, _request.default)({
    url: '/system/driver',
    method: 'post',
    data: data
  });
} // 修改司机


function updateDriver(data) {
  return (0, _request.default)({
    url: '/system/driver',
    method: 'put',
    data: data
  });
} // 删除司机


function delDriver(id) {
  return (0, _request.default)({
    url: '/system/driver/' + id,
    method: 'delete'
  });
} // 导出司机


function exportDriver(query) {
  return (0, _request.default)({
    url: '/system/driver/export',
    method: 'get',
    params: query
  });
}
/**
 * 新增或更新司机体现配置
 */


function addUpdateDriverWithdrawalConfig(data) {
  return (0, _request.default)({
    url: "/system/carpoolDriverWithdrawalConfig/addUpdateConfig",
    method: "post",
    data: data
  });
}
/**
 * 通过公司id获取提现配置
 */


function getDriverWithdrawalConfigByCompanyId(query) {
  return (0, _request.default)({
    url: "/system/carpoolDriverWithdrawalConfig/getConfigByCompanyId",
    method: "get",
    params: query
  });
} // 查询拼车司机提现申请记录列表


function getApplyList(query) {
  return (0, _request.default)({
    url: '/system/carpoolDriverWithdrawalApply/getApply',
    method: 'get',
    params: query
  });
} // 通过提现申请


function passApply(query) {
  return (0, _request.default)({
    url: '/system/carpoolDriverWithdrawalApply/passApply',
    method: 'get',
    params: query
  });
} // 获取司机详情


function getDriverInfo(query) {
  return (0, _request.default)({
    url: '/order/driverBillOrder/getDriverInfo',
    method: 'get',
    params: query
  });
} // 获取司机订单


function getDriverOrder(query) {
  return (0, _request.default)({
    url: '/order/driverBillOrder/getDriverOrder',
    method: 'get',
    params: query
  });
} // 获取司机账单


function getDriverBillList(query) {
  return (0, _request.default)({
    url: '/order/driverBillOrder/getDriverBillList',
    method: 'get',
    params: query
  });
} // 更改司机账户状态


function freezeDriver(query) {
  return (0, _request.default)({
    url: '/system/driver/freezeDriver',
    method: 'get',
    params: query
  });
}