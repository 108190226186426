"use strict";

var _interopRequireDefault = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.loadView = void 0;

require("core-js/modules/web.dom.iterable");

var _router = require("@/router");

var _menu = require("@/api/menu");

var _index = _interopRequireDefault(require("@/layout/index"));

var _utils = require("@/utils");

var permission = {
  state: {
    routes: [],
    addRoutes: []
  },
  mutations: {
    SET_ROUTES: function SET_ROUTES(state, routes) {
      state.addRoutes = routes;
      state.routes = _router.constantRoutes.concat(routes);
      var aaa = (0, _utils.deepClone)(state.routes);
      aaa.forEach(function (item) {
        return item.component ? delete item.component : '';
      }); // console.log(JSON.stringify(aaa,null,2));
    }
  },
  actions: {
    // 生成路由
    GenerateRoutes: function GenerateRoutes(_ref) {
      var commit = _ref.commit;
      return new Promise(function (resolve) {
        // 向后端请求路由数据
        (0, _menu.getRouters)().then(function (res) {
          var accessedRoutes = filterAsyncRouter(res.data);
          accessedRoutes.push({
            path: '*',
            redirect: '/404',
            hidden: true
          });
          commit('SET_ROUTES', accessedRoutes);
          resolve(accessedRoutes);
        });
      });
    }
  }
}; // 遍历后台传来的路由字符串，转换为组件对象

function filterAsyncRouter(asyncRouterMap) {
  return asyncRouterMap.filter(function (route) {
    if (route.component) {
      // Layout组件特殊处理
      if (route.component === 'Layout') {
        route.component = _index.default;
      } else {
        route.component = loadView(route.component);
      }
    }

    if (route.children != null && route.children && route.children.length) {
      route.children = filterAsyncRouter(route.children);
    }

    return true;
  });
}

var loadView = function loadView(view) {
  // 路由懒加载
  return function (resolve) {
    return require(["@/views/".concat(view)], resolve);
  };
};

exports.loadView = loadView;
var _default = permission;
exports.default = _default;