"use strict";

var _interopRequireDefault = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCity = getCity;
exports.getCarpoolLine = getCarpoolLine;
exports.getCarpoolLineLists = getCarpoolLineLists;
exports.delCarpoolLine = delCarpoolLine;
exports.addLine = addLine;
exports.addCarpoolElectricFence = addCarpoolElectricFence;
exports.carpoolElectricFenceList = carpoolElectricFenceList;
exports.updateCarpoolElectricFence = updateCarpoolElectricFence;
exports.deleteCarpoolElectricFence = deleteCarpoolElectricFence;
exports.updateLine = updateLine;
exports.listStartCityToEndCity = listStartCityToEndCity;
exports.getStartCityToEndCity = getStartCityToEndCity;
exports.addStartCityToEndCity = addStartCityToEndCity;
exports.updateStartCityToEndCity = updateStartCityToEndCity;
exports.delStartCityToEndCity = delStartCityToEndCity;
exports.exportStartCityToEndCity = exportStartCityToEndCity;
exports.lineConfigList = lineConfigList;
exports.editLineConfigList = editLineConfigList;
exports.addLineConfig = addLineConfig;
exports.updateLineConfig = updateLineConfig;
exports.getPayConfigAndRefundConfig = getPayConfigAndRefundConfig;
exports.updatePayConfigAndPayRefundConfig = updatePayConfigAndPayRefundConfig;
exports.addCarpoolLineUsrs = addCarpoolLineUsrs;
exports.updateCarpoolLineUsrs = updateCarpoolLineUsrs;
exports.getCarpoolDispacherLists = getCarpoolDispacherLists;
exports.getCarpoolLineUsrs = getCarpoolLineUsrs;
exports.deleteCarpoolLineUsrs = deleteCarpoolLineUsrs;
exports.carpoolScheduleLists = carpoolScheduleLists;
exports.addCarpoolScheduleS = addCarpoolScheduleS;
exports.carpoolScheduleDeputyLists = carpoolScheduleDeputyLists;
exports.addCarpoolScheduleDeputyS = addCarpoolScheduleDeputyS;
exports.editCarpoolScheduleDeputyS = editCarpoolScheduleDeputyS;
exports.carpoolScheduleAddList = carpoolScheduleAddList;
exports.carpoolOrderLineAndNumList = carpoolOrderLineAndNumList;
exports.carpoolOrderDetails = carpoolOrderDetails;
exports.carpoolOrderLineList = carpoolOrderLineList;
exports.carpoolTravelHotList = carpoolTravelHotList;
exports.carpoolTripList = carpoolTripList;
exports.carpoolOrderList = carpoolOrderList;
exports.carpoolOrderDetail = carpoolOrderDetail;
exports.carpoolTravelHotDetails = carpoolTravelHotDetails;
exports.carpollTripDetail = carpollTripDetail;
exports.getLineDriverList = getLineDriverList;
exports.getUpDownListByLineId = getUpDownListByLineId;
exports.getCarpoolSchedule = getCarpoolSchedule;
exports.comfortList = comfortList;
exports.calculateDynamicCost = calculateDynamicCost;
exports.calculateBaseCost = calculateBaseCost;
exports.addOrderByCUSTOM = addOrderByCUSTOM;
exports.setOrderToDriver = setOrderToDriver;
exports.setOrderToDriverChange = setOrderToDriverChange;
exports.cancelExpressOrderHot = cancelExpressOrderHot;
exports.cancelExpressOrder = cancelExpressOrder;
exports.customerServiceRefund = customerServiceRefund;
exports.getCarType = getCarType;
exports.insertScheduleAndConfig = insertScheduleAndConfig;
exports.insertScheduleAndConfigList = insertScheduleAndConfigList;
exports.selectCarConfigByScheduleId = selectCarConfigByScheduleId;
exports.selectCarpoolScheduleCarByLineId = selectCarpoolScheduleCarByLineId;
exports.insertScheduleAndConfigTemplate = insertScheduleAndConfigTemplate;
exports.selectCarpoolScheduleCarById = selectCarpoolScheduleCarById;
exports.deleteTemplateById = deleteTemplateById;
exports.insertScheduleCarByTemplateIds = insertScheduleCarByTemplateIds;
exports.carpoolOrderExport = carpoolOrderExport;
exports.lineCarpoolOperation = lineCarpoolOperation;
exports.carpoolScheduleListSeat = carpoolScheduleListSeat;
exports.carpoolScheduleCarEdit = carpoolScheduleCarEdit;
exports.getServiceCharge = getServiceCharge;
exports.queryTravel = queryTravel;
exports.crossTravelAssign = crossTravelAssign;
exports.changeTravelAssign = changeTravelAssign;
exports.getScheduleDate = getScheduleDate;
exports.getEnableSchedule = getEnableSchedule;
exports.scanOrderConfigQuery = scanOrderConfigQuery;
exports.scanOrderConfigAddOrUpdate = scanOrderConfigAddOrUpdate;

var _request = _interopRequireDefault(require("@/utils/request"));

// 查询城市信息，重要-请不要删除数据详细
function getCity(id) {
  return (0, _request.default)({
    url: "/bus/city/" + id,
    method: "get"
  });
}

function getCarpoolLine(id) {
  return (0, _request.default)({
    url: "/carpool/line/" + id,
    method: "get"
  });
}

function getCarpoolLineLists(params) {
  return (0, _request.default)({
    url: "/carpool/line/list",
    method: "get",
    params: params
  });
}

function delCarpoolLine(id) {
  return (0, _request.default)({
    url: "/carpool/line/" + id,
    method: "delete"
  });
} // 新增线路


function addLine(data) {
  return (0, _request.default)({
    url: "/carpool/line",
    method: "post",
    data: data
  });
} // 新增电子围栏


function addCarpoolElectricFence(data) {
  return (0, _request.default)({
    url: "/carpool/carpoolElectricFence",
    method: "post",
    data: data
  });
}

function carpoolElectricFenceList(params) {
  return (0, _request.default)({
    url: "/carpool/carpoolElectricFence/list",
    method: "get",
    params: params
  });
}

function updateCarpoolElectricFence(data) {
  return (0, _request.default)({
    url: "/carpool/carpoolElectricFence",
    method: "put",
    data: data
  });
}

function deleteCarpoolElectricFence(id) {
  return (0, _request.default)({
    url: "/carpool/carpoolElectricFence/" + id,
    method: "delete"
  });
}

function updateLine(data) {
  return (0, _request.default)({
    url: "/carpool/line",
    method: "put",
    data: data
  });
} // 查询出发城市到到达城市关系列表


function listStartCityToEndCity(query) {
  return (0, _request.default)({
    url: "carpool/StartCityToEndCity/list",
    method: "get",
    params: query
  });
} // 查询出发城市到到达城市关系详细


function getStartCityToEndCity(id) {
  return (0, _request.default)({
    url: "carpool/StartCityToEndCity/" + id,
    method: "get"
  });
} // 新增出发城市到到达城市关系


function addStartCityToEndCity(data) {
  return (0, _request.default)({
    url: "carpool/StartCityToEndCity",
    method: "post",
    data: data
  });
} // 修改出发城市到到达城市关系


function updateStartCityToEndCity(data) {
  return (0, _request.default)({
    url: "carpool/StartCityToEndCity",
    method: "put",
    data: data
  });
} // 删除出发城市到到达城市关系


function delStartCityToEndCity(id) {
  return (0, _request.default)({
    url: "carpool/StartCityToEndCity/" + id,
    method: "delete"
  });
} // 导出出发城市到到达城市关系


function exportStartCityToEndCity(query) {
  return (0, _request.default)({
    url: "carpool/StartCityToEndCity/export",
    method: "get",
    params: query
  });
}

function lineConfigList(query) {
  return (0, _request.default)({
    url: "/carpool/lineConfig/getCarpoolLineConfigMap",
    method: "get",
    params: query
  });
}

function editLineConfigList(data) {
  return (0, _request.default)({
    url: "/carpool/lineConfig/editCarpoolLineConfigMap",
    method: "put",
    data: data
  });
} // 线路配置相关接口


function addLineConfig(data) {
  return (0, _request.default)({
    url: "/carpool/lineConfig",
    method: "post",
    data: data
  });
}

function updateLineConfig(data) {
  return (0, _request.default)({
    url: "/carpool/lineConfig",
    method: "put",
    data: data
  });
} // 获取线路配置详情


function getPayConfigAndRefundConfig(query) {
  return (0, _request.default)({
    url: "/carpool/line/getPayConfigAndRefundConfig",
    method: "get",
    params: query
  });
} // 更新线路配置详情


function updatePayConfigAndPayRefundConfig(data) {
  return (0, _request.default)({
    url: "/carpool/line/updatePayConfigAndPayRefundConfig",
    method: "post",
    data: data
  });
}

function addCarpoolLineUsrs(data) {
  return (0, _request.default)({
    url: "/carpool/carpoolLineUsrs",
    method: "post",
    data: data
  });
}

function updateCarpoolLineUsrs(data) {
  return (0, _request.default)({
    url: "/carpool/carpoolLineUsrs",
    method: "put",
    data: data
  });
}

function getCarpoolDispacherLists(params) {
  return (0, _request.default)({
    url: "carpool/carpoolLineUsrs/list",
    method: "get",
    params: params
  });
}

function getCarpoolLineUsrs(id) {
  return (0, _request.default)({
    url: "carpool/carpoolLineUsrs/" + id,
    method: "get"
  });
}

function deleteCarpoolLineUsrs(id) {
  return (0, _request.default)({
    url: "carpool/carpoolLineUsrs/" + id,
    method: "delete"
  });
} // 班次模板列表


function carpoolScheduleLists(params) {
  return (0, _request.default)({
    url: "/carpool/carpoolSchedule/list",
    method: "get",
    params: params
  });
}

function addCarpoolScheduleS(data) {
  return (0, _request.default)({
    url: "/carpool/carpoolSchedule/addCarpoolScheduleS",
    method: "post",
    data: data
  });
}

function carpoolScheduleDeputyLists(params) {
  return (0, _request.default)({
    url: "/carpool/carpoolScheduleDeputy/list",
    method: "get",
    params: params
  });
}

function addCarpoolScheduleDeputyS(data) {
  return (0, _request.default)({
    url: "/carpool/carpoolScheduleDeputy/addCarpoolScheduleDeputyS",
    method: "post",
    data: data
  });
}

function editCarpoolScheduleDeputyS(data) {
  return (0, _request.default)({
    url: "/carpool/carpoolScheduleDeputy/editCarpoolScheduleDeputyS",
    method: "put",
    data: data
  });
}

function carpoolScheduleAddList(data) {
  return (0, _request.default)({
    url: "/carpool/carpoolSchedule/addList",
    method: "post",
    data: data
  });
}

function carpoolOrderLineAndNumList(params) {
  return (0, _request.default)({
    url: "/carpool/carpoolOrderHot/getLineAndNumList",
    method: "get",
    params: params
  });
}

function carpoolOrderDetails(id) {
  return (0, _request.default)({
    url: "/carpool/carpoolOrderHot/" + id,
    method: "get"
  });
}

function carpoolOrderLineList(params) {
  return (0, _request.default)({
    url: "/carpool/carpoolOrderHot/list",
    method: "get",
    params: params
  });
} // 行程列表


function carpoolTravelHotList(params) {
  return (0, _request.default)({
    url: "/carpool/carpoolTravelHot/list",
    method: "get",
    params: params
  });
} //行程列表-new


function carpoolTripList(params) {
  return (0, _request.default)({
    url: "/carpool/carpoolTravel/list",
    method: "get",
    params: params
  });
} // 订单列表


function carpoolOrderList(params) {
  return (0, _request.default)({
    url: "/carpool/carpoolOrder/list",
    method: "get",
    params: params
  });
} //订单详情


function carpoolOrderDetail(params) {
  return (0, _request.default)({
    url: "/carpool/carpoolOrder/getCarpoolOrderDetails",
    method: "get",
    params: params
  });
} // 形成详情


function carpoolTravelHotDetails(id) {
  return (0, _request.default)({
    url: "/carpool/carpoolTravelHot/" + id,
    method: "get"
  });
} //行程详情-new


function carpollTripDetail(params) {
  return (0, _request.default)({
    url: "/carpool/carpoolTravel/getCarpoolTravelDetails",
    method: "get",
    params: params
  });
}

function getLineDriverList(params) {
  return (0, _request.default)({
    url: "/carpool/carpoolOrderHot/getLineDriverList",
    method: "get",
    params: params
  });
}

function getUpDownListByLineId(params) {
  return (0, _request.default)({
    url: "/carpool/carpoolElectricFence/getUpDownListByLineId",
    method: "get",
    params: params
  });
}

function getCarpoolSchedule(params) {
  return (0, _request.default)({
    url: "/carpool/carpoolSchedule/getCarpoolSchedule",
    method: "get",
    params: params
  });
}

function comfortList(params) {
  return (0, _request.default)({
    url: "/carpool/carpoolScheduleCar/comfortList",
    method: "get",
    params: params
  });
}

function calculateDynamicCost(params) {
  return (0, _request.default)({
    url: "/carpool/carpoolOrderHot/calculateDynamicCost",
    method: "get",
    params: params
  });
}

function calculateBaseCost(params) {
  return (0, _request.default)({
    url: "/carpool/carpoolOrderHot/calculateBaseCost",
    method: "get",
    params: params
  });
}

function addOrderByCUSTOM(data) {
  return (0, _request.default)({
    url: "/carpool/carpoolOrderHot/addOrderByCUSTOM",
    method: "post",
    data: data
  });
}

function setOrderToDriver(data) {
  return (0, _request.default)({
    url: "/carpool/carpoolOrderHot/setOrderToDriver",
    method: "post",
    data: data
  });
}

function setOrderToDriverChange(data) {
  return (0, _request.default)({
    url: "/carpool/carpoolOrderHot/setOrderToDriverChange",
    method: "post",
    data: data
  });
} //取消调度订单


function cancelExpressOrderHot(data) {
  return (0, _request.default)({
    url: "/carpool/carpoolOrderHot/cancelExpressOrderHot",
    method: "post",
    data: data
  });
} //取消订单


function cancelExpressOrder(data) {
  return (0, _request.default)({
    url: "/carpool/carpoolOrder/cancelExpressOrder",
    method: "post",
    data: data
  });
} // 订单退款


function customerServiceRefund(data) {
  return (0, _request.default)({
    url: "/carpool/carpoolOrder/customerServiceRefund",
    method: "post",
    data: data
  });
} //查询公司下所有车型


function getCarType(params) {
  return (0, _request.default)({
    url: "/system/car/getCarType",
    method: "get",
    params: params
  });
} // 新增车型配置


function insertScheduleAndConfig(data) {
  return (0, _request.default)({
    url: "/carpool/carpoolScheduleCar/insertScheduleAndConfig",
    method: "post",
    data: data
  });
} // 批量新增车型配置


function insertScheduleAndConfigList(data) {
  return (0, _request.default)({
    url: "/carpool/carpoolScheduleCar/insertScheduleAndConfigList",
    method: "post",
    data: data
  });
} // 通过班次id查询该班次下配置的所有车型价格信息


function selectCarConfigByScheduleId(params) {
  return (0, _request.default)({
    url: "/carpool/carpoolScheduleCar/selectCarConfigByScheduleId",
    method: "get",
    params: params
  });
} // 通过线路id查询车型价格模板列表


function selectCarpoolScheduleCarByLineId(params) {
  return (0, _request.default)({
    url: "/carpool/carpoolScheduleCarPriceTemplate/selectCarpoolScheduleCarByLineId",
    method: "get",
    params: params
  });
} // 线路下-新增车型价格配置模板


function insertScheduleAndConfigTemplate(data) {
  return (0, _request.default)({
    url: "/carpool/carpoolScheduleCarPriceTemplate/insertScheduleAndConfig",
    method: "post",
    data: data
  });
} // 通过模板id查询模板信息


function selectCarpoolScheduleCarById(params) {
  return (0, _request.default)({
    url: "/carpool/carpoolScheduleCarPriceTemplate/selectCarpoolScheduleCarById",
    method: "get",
    params: params
  });
} // 通过模板id删除模板信息


function deleteTemplateById(params) {
  return (0, _request.default)({
    url: "/carpool/carpoolScheduleCarPriceTemplate/deleteTemplateById",
    method: "delete",
    params: params
  });
} // 线路下-通过车辆价格模板新增班次车辆价格配置数据


function insertScheduleCarByTemplateIds(params) {
  return (0, _request.default)({
    url: "/carpool/carpoolScheduleCar/insertScheduleCarByTemplateIds",
    method: "get",
    params: params
  });
} // 拼车订单列表导出


function carpoolOrderExport(params) {
  return (0, _request.default)({
    url: "/carpool/carpoolOrder/export",
    method: "get",
    params: params
  });
}
/**
 * 拼车线路（根据登录者公司id）（传数组）
 * 不传默认当前登录者的所有公司id
 * @param accessibleCompanyIds 传公司id数组
 * @param isAll 1查询拼车下所有线路不限公司  0 传公司id（对应公司id线路）/不传公司id（当前登录者所属公司的线路）
 * @returns 
 */


function lineCarpoolOperation(params) {
  return (0, _request.default)({
    url: "/carpool/line/listOperation",
    method: "get",
    params: params
  });
}
/**
 * 拼车排班查询余座
 * @returns 
 */


function carpoolScheduleListSeat(params) {
  return (0, _request.default)({
    url: "/carpool/carpoolSchedule/listSeat",
    method: "get",
    params: params
  });
}
/**
 * 拼车排班修改车型余座
 */


function carpoolScheduleCarEdit(data) {
  return (0, _request.default)({
    url: "/carpool/carpoolScheduleCar/edit",
    method: "post",
    data: data
  });
}
/**
 * 拼车调度获取取消手续费
 */


function getServiceCharge(data) {
  return (0, _request.default)({
    url: "/carpool/carpoolOrderHot/getServiceCharge",
    method: "post",
    data: data
  });
}
/**
 * 跨行程指派-获取司机的行程
 */


function queryTravel(params) {
  return (0, _request.default)({
    url: "/carpool/carpoolOrderHot/queryTravel",
    method: "get",
    params: params
  });
}
/**
 * 跨行程指派-指派到已有行程上
 */


function crossTravelAssign(params) {
  return (0, _request.default)({
    url: "/carpool/carpoolOrderHot/crossTravelAssign",
    method: "get",
    params: params
  });
}
/**
 * 跨行程指派-改派到已有行程上
 */


function changeTravelAssign(params) {
  return (0, _request.default)({
    url: "/carpool/carpoolOrderHot/changeTravelAssign",
    method: "get",
    params: params
  });
}
/**
 * 客服新建订单-获取班次的日期列表
 */


function getScheduleDate(params) {
  return (0, _request.default)({
    url: "/carpool/carpoolOrderHot/getScheduleDate",
    method: "get",
    params: params
  });
}
/**
 * 客服新建订单-获取某一天的班次列表
 */


function getEnableSchedule(params) {
  return (0, _request.default)({
    url: "/carpool/carpoolOrderHot/getEnableSchedule",
    method: "get",
    params: params
  });
}
/**
 * 拼车扫码下单配置查询
 */


function scanOrderConfigQuery(params) {
  return (0, _request.default)({
    url: "/carpool/scanOrderConfig/query",
    method: "get",
    params: params
  });
}
/**
 * 拼车扫码下单配置保存
 */


function scanOrderConfigAddOrUpdate(data) {
  return (0, _request.default)({
    url: "/carpool/scanOrderConfig/addOrUpdate",
    method: "post",
    data: data
  });
}