"use strict";

var _interopRequireDefault = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _vueCountTo = _interopRequireDefault(require("vue-count-to"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  components: {
    CountTo: _vueCountTo.default
  },
  props: {
    countData: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    showMore: {
      type: Boolean,
      default: false
    },
    smsDetail: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  mounted: function mounted() {
    console.log(this.countData);
  },
  methods: {
    handleSetLineChartData: function handleSetLineChartData(type) {
      this.$emit('handleSetLineChartData', type);
    },
    goPages: function goPages(type) {
      var route = '';

      switch (type) {
        case '总收益':
          route = "/chart/statistics1";
          break;

        case '总客量':
          route = "/chart/statistics2";
          break;

        case '总订单':
          route = "/chart/statistics3";
          break;
      }

      this.$router.push({
        path: route
      });
    }
  }
};
exports.default = _default2;