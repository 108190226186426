"use strict";

var _interopRequireDefault = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.moreCount = moreCount;
exports.stationLevel = stationLevel;
exports.timeLevelCount = timeLevelCount;
exports.scheduleByDayTimeCount = scheduleByDayTimeCount;
exports.busTicketOrderCount = busTicketOrderCount;
exports.busTicketLongLatitude = busTicketLongLatitude;
exports.passengerBehaviorCount = passengerBehaviorCount;
exports.busTicketListCount = busTicketListCount;
exports.scheduleByDayTimeCountExcel = scheduleByDayTimeCountExcel;
exports.timeLevelCountExcel = timeLevelCountExcel;

var _request = _interopRequireDefault(require("@/utils/request"));

// 查询乘客复购分析
function moreCount(data) {
  return (0, _request.default)({
    url: '/system/main/moreCount',
    method: 'get',
    params: data
  });
} // 查询线路站点排行榜


function stationLevel(data) {
  return (0, _request.default)({
    url: '/system/main/stationLevel',
    method: 'get',
    params: data
  });
} // 查询分时段购票数量


function timeLevelCount(data) {
  return (0, _request.default)({
    url: '/system/main/timeLevelCount',
    method: 'get',
    params: data
  });
} // 查询各班次售票分布分析


function scheduleByDayTimeCount(data) {
  return (0, _request.default)({
    url: '/system/main/scheduleByDayTimeCount',
    method: 'get',
    params: data
  });
} // 查询订单趋势


function busTicketOrderCount(data) {
  return (0, _request.default)({
    url: '/system/main/busTicketOrderCount',
    method: 'get',
    params: data
  });
} // 发单热力大盘


function busTicketLongLatitude(data) {
  return (0, _request.default)({
    url: '/system/main/busTicketLongLatitude',
    method: 'get',
    params: data
  });
} // 用户行为漏斗


function passengerBehaviorCount(data) {
  return (0, _request.default)({
    url: '/system/main/passengerBehaviorCount',
    method: 'get',
    params: data
  });
} // 乘客复购分析详情


function busTicketListCount(data) {
  return (0, _request.default)({
    url: '/system/main/busTicketListCount',
    method: 'get',
    params: data
  });
} // 查询各班次售票分布分析导出报表


function scheduleByDayTimeCountExcel(data) {
  return (0, _request.default)({
    url: '/system/main/scheduleByDayTimeCountExcel',
    method: 'get',
    params: data
  });
} // 查询分时段购票数量导出报表


function timeLevelCountExcel(data) {
  return (0, _request.default)({
    url: '/system/main/timeLevelCountExcel',
    method: 'get',
    params: data
  });
}