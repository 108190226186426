"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "BasicInfoForm",
  props: {
    info: {
      type: Object,
      default: null
    }
  },
  data: function data() {
    return {
      rules: {
        tableName: [{
          required: true,
          message: "请输入表名称",
          trigger: "blur"
        }],
        tableComment: [{
          required: true,
          message: "请输入表描述",
          trigger: "blur"
        }],
        className: [{
          required: true,
          message: "请输入实体类名称",
          trigger: "blur"
        }],
        functionAuthor: [{
          required: true,
          message: "请输入作者",
          trigger: "blur"
        }]
      }
    };
  }
};
exports.default = _default;