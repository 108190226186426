"use strict";

var _interopRequireDefault = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime-corejs2/helpers/objectSpread2"));

var _userAvatar = _interopRequireDefault(require("../user/profile/userAvatar"));

var _userInfo = _interopRequireDefault(require("../user/profile/userInfo"));

var _resetPwd = _interopRequireDefault(require("../user/profile/resetPwd"));

var _passenger = require("@/api/system/passenger");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "Profile",
  components: {
    userAvatar: _userAvatar.default,
    userInfo: _userInfo.default,
    resetPwd: _resetPwd.default
  },
  data: function data() {
    return {
      user: {},
      tableData: [],
      tableData2: [],
      // 总条数
      total: 0,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10
      },
      id: null,
      activeTab: "userinfo"
    };
  },
  created: function created() {
    this.id = this.$route.query.id;
    this.getUser();
    this.getList();
  },
  methods: {
    getUser: function getUser() {
      var _this = this;

      (0, _passenger.getPassenger)(this.id).then(function (response) {
        _this.user = response.data;
      });
    },
    getList: function getList() {
      var _this2 = this;

      (0, _passenger.orderList)((0, _objectSpread2.default)({}, this.queryParams, {
        id: this.id
      })).then(function (response) {
        _this2.tableData = response.rows;
        _this2.total = response.total;
      });
      (0, _passenger.friendList)({
        id: this.id
      }).then(function (response) {
        _this2.tableData2 = response.rows;
      });
    },
    disableAccount: function disableAccount() {
      var _this3 = this;

      this.user.userStatus = 1;
      (0, _passenger.updatePassenger)(this.user).then(function (response) {
        if (response.code === 200) {
          _this3.msgSuccess("操作成功");
        } else {
          _this3.msgError(response.msg);
        }
      });
    }
  }
};
exports.default = _default;