"use strict";

var _interopRequireDefault = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _login = require("@/api/login");

var _jsCookie = _interopRequireDefault(require("js-cookie"));

var _jsencrypt = require("@/utils/jsencrypt");

var _PanelGroup = _interopRequireDefault(require("@/views/dashboard/PanelGroup"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "Login",
  components: {
    IndexPage: _PanelGroup.default
  },
  data: function data() {
    return {
      codeUrl: "",
      cookiePassword: "",
      loginForm: {
        username: "",
        password: "",
        rememberMe: false,
        code: "",
        uuid: ""
      },
      loginRules: {
        username: [{
          required: true,
          trigger: "blur",
          message: "用户名不能为空"
        }],
        password: [{
          required: true,
          trigger: "blur",
          message: "密码不能为空"
        }],
        code: [{
          required: true,
          trigger: "change",
          message: "验证码不能为空"
        }]
      },
      loading: false,
      redirect: undefined
    };
  },
  watch: {
    $route: {
      handler: function handler(route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true
    }
  },
  created: function created() {
    this.getCode();
    this.getCookie();
  },
  methods: {
    getCode: function getCode() {
      var _this = this;

      (0, _login.getCodeImg)().then(function (res) {
        _this.codeUrl = "data:image/gif;base64," + res.img;
        _this.loginForm.uuid = res.uuid;
      });
    },
    getCookie: function getCookie() {
      var username = _jsCookie.default.get("username");

      var password = _jsCookie.default.get("password");

      var rememberMe = _jsCookie.default.get('rememberMe');

      this.loginForm = {
        username: username === undefined ? this.loginForm.username : username,
        password: password === undefined ? this.loginForm.password : (0, _jsencrypt.decrypt)(password),
        rememberMe: rememberMe === undefined ? false : Boolean(rememberMe)
      };
    },
    handleLogin: function handleLogin() {
      var _this2 = this;

      this.$refs.loginForm.validate(function (valid) {
        if (valid) {
          _this2.loading = true;

          if (_this2.loginForm.rememberMe) {
            _jsCookie.default.set("username", _this2.loginForm.username, {
              expires: 30
            });

            _jsCookie.default.set("password", (0, _jsencrypt.encrypt)(_this2.loginForm.password), {
              expires: 30
            });

            _jsCookie.default.set('rememberMe', _this2.loginForm.rememberMe, {
              expires: 30
            });
          } else {
            _jsCookie.default.remove("username");

            _jsCookie.default.remove("password");

            _jsCookie.default.remove('rememberMe');
          }

          _this2.$store.dispatch("Login", _this2.loginForm).then(function (res) {
            if (res.code === 200) {
              _this2.$router.push({
                path: _this2.redirect || "/"
              });
            } else {
              _this2.messageFn(res.msg, 'error');

              _this2.getCode();

              _this2.loading = false;
            }
          }).catch(function () {
            _this2.loading = false;

            _this2.getCode();
          });
        }
      });
    }
  }
};
exports.default = _default;