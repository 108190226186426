"use strict";

var _interopRequireDefault = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _store = _interopRequireDefault(require("@/store"));

var _vueCropper = require("vue-cropper");

var _user = require("@/api/system/user");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    VueCropper: _vueCropper.VueCropper
  },
  props: {
    user: {
      type: Object
    }
  },
  data: function data() {
    return {
      // 是否显示弹出层
      open: false,
      // 弹出层标题
      title: "修改头像",
      options: {
        img: _store.default.getters.avatar,
        //裁剪图片的地址
        autoCrop: true,
        // 是否默认生成截图框
        autoCropWidth: 200,
        // 默认生成截图框宽度
        autoCropHeight: 200,
        // 默认生成截图框高度
        fixedBox: true // 固定截图框大小 不允许改变

      },
      previews: {}
    };
  },
  methods: {
    // 编辑头像
    editCropper: function editCropper() {
      this.open = true;
    },
    // 覆盖默认的上传行为
    requestUpload: function requestUpload() {},
    // 向左旋转
    rotateLeft: function rotateLeft() {
      this.$refs.cropper.rotateLeft();
    },
    // 向右旋转
    rotateRight: function rotateRight() {
      this.$refs.cropper.rotateRight();
    },
    // 图片缩放
    changeScale: function changeScale(num) {
      num = num || 1;
      this.$refs.cropper.changeScale(num);
    },
    // 上传预处理
    beforeUpload: function beforeUpload(file) {
      var _this = this;

      if (file.type.indexOf("image/") == -1) {
        this.msgError("文件格式错误，请上传图片类型,如：JPG，PNG后缀的文件。");
      } else {
        var reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = function () {
          _this.options.img = reader.result;
        };
      }
    },
    uploadImgBack: function uploadImgBack() {
      this.$refs.cropper.getCropBlob(function (data) {
        var files = new window.File([data], data.size, {
          type: data.type
        });
        console.log(data, files, '--'); // let formData = new FormData();
        // formData.append("avatarfile", data);

        var formData = new FormData();
        formData.append('file', files);
        axios({
          method: 'post',
          url: 'http://uploadfile.hellouxing.com/uploadfile/upload',
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(function (res) {}).catch(function (err) {}); // axios.post('http://uploadfile.hellouxing.com/uploadfile/upload',formData,{
        //   headers: {
        //     'Content-Type': 'multipart/form-data'
        //   }
        // }).then(response => {
        //   this.msgSuccess("修改成功");
        //   console.log(response,'res');
        //   if (response.code === 200) {
        //     this.open = false;
        //     this.options.img = process.env.VUE_APP_BASE_API + response.imgUrl;
        //   } else {
        //     this.msgError(response.msg);
        //   }
        //   this.$refs.cropper.clearCrop();
        // });
      });
    },
    // 上传图片
    uploadImg: function uploadImg() {
      var _this2 = this;

      this.$refs.cropper.getCropBlob(function (data) {
        var formData = new FormData();
        formData.append("avatarfile", data);
        (0, _user.uploadAvatar)(formData).then(function (response) {
          if (response.code === 200) {
            _this2.open = false;
            _this2.options.img = process.env.VUE_APP_BASE_API + response.imgUrl;

            _this2.msgSuccess("修改成功");
          } else {
            _this2.msgError(response.msg);
          }

          _this2.$refs.cropper.clearCrop();
        });
      });
    },
    // 实时预览
    realTime: function realTime(data) {
      this.previews = data;
    }
  }
};
exports.default = _default;