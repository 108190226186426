"use strict";

var _interopRequireDefault = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.listJobLog = listJobLog;
exports.delJobLog = delJobLog;
exports.cleanJobLog = cleanJobLog;
exports.exportJobLog = exportJobLog;

var _request = _interopRequireDefault(require("@/utils/request"));

// 查询调度日志列表
function listJobLog(query) {
  return (0, _request.default)({
    url: '/monitor/jobLog/list',
    method: 'get',
    params: query
  });
} // 删除调度日志


function delJobLog(jobLogId) {
  return (0, _request.default)({
    url: '/monitor/jobLog/' + jobLogId,
    method: 'delete'
  });
} // 清空调度日志


function cleanJobLog() {
  return (0, _request.default)({
    url: '/monitor/jobLog/clean',
    method: 'delete'
  });
} // 导出调度日志


function exportJobLog(query) {
  return (0, _request.default)({
    url: '/monitor/jobLog/export',
    method: 'get',
    params: query
  });
}