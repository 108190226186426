"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _carpool = require("@/api/business/carpool");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    var _this = this;

    return {
      generalForm: {
        carpoolLineConfigCJBC: {},
        carpoolLineConfigCJPC: {}
      },
      rules: {
        "carpoolLineConfigCJPC.maxOrder": [{
          required: true,
          message: "请完善表单项",
          trigger: "blur"
        }],
        "carpoolLineConfigCJPC.carpoolCanDiffTimeOrder": [{
          required: true,
          validator: function validator(rules, value, cb) {
            var carpoolCanDiffTimeOrderTime = _this.generalForm.carpoolLineConfigCJPC.carpoolCanDiffTimeOrderTime;

            if (value !== 0 && !value) {
              return cb(new Error("请选择是否允许不同出发时间订单加入同一行程"));
            }

            if (carpoolCanDiffTimeOrderTime !== 0 && !carpoolCanDiffTimeOrderTime) {
              return cb(new Error("请填写时间"));
            }

            return cb();
          },
          trigger: "change"
        }],
        "carpoolLineConfigCJBC.maxOrder": [{
          required: true,
          message: "请完善表单项",
          trigger: "blur"
        }],
        "carpoolLineConfigCJPC.maxDayAfter": [{
          required: true,
          message: "请完善表单项",
          trigger: "blur"
        }],
        "carpoolLineConfigCJPC.payOrderBeforeType": [{
          required: true,
          validator: function validator(rules, value, cb) {
            var _this$generalForm$car = _this.generalForm.carpoolLineConfigCJPC,
                payOrderBeforeEndTime = _this$generalForm$car.payOrderBeforeEndTime,
                payOrderBeforeStartTime = _this$generalForm$car.payOrderBeforeStartTime;

            if (value !== 0 && !value) {
              return cb(new Error("请完善表单项1"));
            }

            if (value === 1 && payOrderBeforeStartTime !== 0 && !payOrderBeforeStartTime) {
              return cb(new Error("请完善表单项2"));
            }

            if (value === 0 && payOrderBeforeEndTime !== 0 && !payOrderBeforeEndTime) {
              return cb(new Error("请完善表单项3"));
            }

            return cb();
          },
          trigger: "change"
        }]
      }
    };
  },
  props: {
    openGeneralConfigDialog: {
      type: Boolean,
      default: false
    },
    operationData: {
      type: Object,
      required: true
    }
  },
  created: function created() {
    this.getConfig();
  },
  methods: {
    getConfig: function getConfig() {
      var _this2 = this;

      (0, _carpool.lineConfigList)({
        lineId: this.operationData.id
      }).then(function (res) {
        if (res.code === 200) {
          _this2.generalForm = res.data;
          console.log(_this2.generalForm, "----");
        } else {
          _this2.$message.error(res.msg);
        }
      });
    },
    onSubmit: function onSubmit() {
      console.log("submit!");
    },
    handleChange: function handleChange(e) {
      console.log(e, "submit!");
    },
    handleDialogClose: function handleDialogClose() {
      this.$emit("closepop"); //通知父组件改变。
    },
    handleSubmit: function handleSubmit() {
      var _this3 = this;

      this.$refs.generalForm.validate(function (valid) {
        if (valid) {
          // form表单中设置了拼车，同步更改包车
          _this3.generalForm.carpoolLineConfigCJBC.payOrderBeforeType = _this3.generalForm.carpoolLineConfigCJPC.payOrderBeforeType;
          _this3.generalForm.carpoolLineConfigCJBC.payOrderBeforeEndTime = _this3.generalForm.carpoolLineConfigCJPC.payOrderBeforeEndTime;
          _this3.generalForm.carpoolLineConfigCJBC.payOrderBeforeStartTime = _this3.generalForm.carpoolLineConfigCJPC.payOrderBeforeStartTime;
          _this3.generalForm.carpoolLineConfigCJBC.maxDayAfter = _this3.generalForm.carpoolLineConfigCJPC.maxDayAfter;
          _this3.generalForm.carpoolLineConfigCJBC.isStoppage = _this3.generalForm.carpoolLineConfigCJPC.isStoppage;
          _this3.generalForm.carpoolLineConfigCJBC.stoppageTime = _this3.generalForm.carpoolLineConfigCJPC.stoppageTime;
          (0, _carpool.editLineConfigList)(_this3.generalForm).then(function (res) {
            console.log(res, "----");

            if (res.code === 200) {
              _this3.$message.success(res.msg);
            } else {
              _this3.$message.error(res.msg);
            }

            _this3.$emit("closepop"); //通知父组件改变。

          });
        }
      });
    }
  }
};
exports.default = _default;