"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _user = require("@/api/system/user");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    var _this = this;

    var equalToPassword = function equalToPassword(rule, value, callback) {
      if (_this.user.newPassword !== value) {
        callback(new Error("两次输入的密码不一致"));
      } else {
        callback();
      }
    };

    return {
      test: "1test",
      user: {
        oldPassword: undefined,
        newPassword: undefined,
        confirmPassword: undefined
      },
      // 表单校验
      rules: {
        oldPassword: [{
          required: true,
          message: "旧密码不能为空",
          trigger: "blur"
        }],
        newPassword: [{
          required: true,
          message: "新密码不能为空",
          trigger: "blur"
        }, {
          min: 6,
          max: 20,
          message: "长度在 6 到 20 个字符",
          trigger: "blur"
        }],
        confirmPassword: [{
          required: true,
          message: "确认密码不能为空",
          trigger: "blur"
        }, {
          required: true,
          validator: equalToPassword,
          trigger: "blur"
        }]
      }
    };
  },
  methods: {
    submit: function submit() {
      var _this2 = this;

      this.$refs["form"].validate(function (valid) {
        if (valid) {
          (0, _user.updateUserPwd)(_this2.user.oldPassword, _this2.user.newPassword).then(function (response) {
            if (response.code === 200) {
              _this2.msgSuccess("修改成功");
            } else {
              _this2.msgError(response.msg);
            }
          });
        }
      });
    },
    close: function close() {
      this.$store.dispatch("tagsView/delView", this.$route);
      this.$router.push({
        path: "/index"
      });
    }
  }
};
exports.default = _default;