"use strict";

var _interopRequireDefault = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.moreCarpoolCount = moreCarpoolCount;
exports.timeCarpoolLevelCount = timeCarpoolLevelCount;
exports.scheduleCarpoolByDayTimeCount = scheduleCarpoolByDayTimeCount;
exports.carpoolTicketLongLatitude = carpoolTicketLongLatitude;
exports.passengerCarpoolBehaviorCount = passengerCarpoolBehaviorCount;
exports.carpoolTicketListCount = carpoolTicketListCount;
exports.scheduleCarpoolByDayTimeCountExcel = scheduleCarpoolByDayTimeCountExcel;
exports.timeCarpoolLevelCountExcel = timeCarpoolLevelCountExcel;
exports.carpoolTravelAnalyse = carpoolTravelAnalyse;
exports.carpoolOrderAnalyse = carpoolOrderAnalyse;

var _request = _interopRequireDefault(require("@/utils/request"));

// 查询乘客复购分析
function moreCarpoolCount(data) {
  return (0, _request.default)({
    url: '/system/main/moreCountCarpool',
    method: 'get',
    params: data
  });
} // 查询分时段购票数量


function timeCarpoolLevelCount(data) {
  return (0, _request.default)({
    url: '/system/main/timeLevelCountCarpool',
    method: 'get',
    params: data
  });
} // 查询各班次售票分布分析


function scheduleCarpoolByDayTimeCount(data) {
  return (0, _request.default)({
    url: '/system/main/scheduleByDayTimeCountCarpool',
    method: 'get',
    params: data
  });
} // 发单热力大盘


function carpoolTicketLongLatitude(data) {
  return (0, _request.default)({
    url: '/system/main/busTicketLongLatitudeCarpool',
    method: 'get',
    params: data
  });
} // 用户行为漏斗


function passengerCarpoolBehaviorCount(data) {
  return (0, _request.default)({
    url: '/system/mainCarpool/passengerCarpoolBehaviorCount',
    method: 'get',
    params: data
  });
} // 乘客复购分析详情


function carpoolTicketListCount(data) {
  return (0, _request.default)({
    url: '/system/main/busTicketListCountCarpool',
    method: 'get',
    params: data
  });
} // 查询各班次售票分布分析导出报表


function scheduleCarpoolByDayTimeCountExcel(data) {
  return (0, _request.default)({
    url: '/system/main/scheduleByDayTimeCountExcelCarpool',
    method: 'get',
    params: data
  });
} // 查询分时段购票数量导出报表


function timeCarpoolLevelCountExcel(data) {
  return (0, _request.default)({
    url: '/system/main/timeLevelCountExcelCarpool',
    method: 'get',
    params: data
  });
} // 查询行程数据分析


function carpoolTravelAnalyse(data) {
  return (0, _request.default)({
    url: '/system/mainCarpool/carpoolTravelAnalyse',
    method: 'get',
    params: data
  });
} // 查询订单数据分析


function carpoolOrderAnalyse(data) {
  return (0, _request.default)({
    url: '/system/mainCarpool/carpoolOrderAnalyse',
    method: 'get',
    params: data
  });
}