var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("div", { staticClass: "title" }, [_vm._v("统计")]),
      _vm._v(" "),
      _c("div", { staticClass: "statistics-wrapper" }, [
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "name" }, [_vm._v("预发数量")]),
          _vm._v(" "),
          _c("div", { staticClass: "num" }, [
            _vm._v(
              _vm._s(
                _vm.couponDetailData.coupon.couponNum == 0
                  ? "不限"
                  : _vm.couponDetailData.coupon.couponNum
              )
            ),
            _c("span", { staticClass: "unit" }, [_vm._v("次")])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "name" }, [_vm._v("领取数量")]),
          _vm._v(" "),
          _c("div", { staticClass: "num" }, [
            _vm._v(_vm._s(_vm.couponDetailData.receiveNum)),
            _c("span", { staticClass: "unit" }, [_vm._v("次")])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "name" }, [_vm._v("已使用数量")]),
          _vm._v(" "),
          _c("div", { staticClass: "num" }, [
            _vm._v(_vm._s(_vm.couponDetailData.useNum)),
            _c("span", { staticClass: "unit" }, [_vm._v("次")])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "name" }, [_vm._v("实际使用数量")]),
          _vm._v(" "),
          _c("div", { staticClass: "num" }, [
            _vm._v(_vm._s(_vm.couponDetailData.realUseNum)),
            _c("span", { staticClass: "unit" }, [_vm._v("次")])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "item" }, [
          _c("div", { staticClass: "name" }, [_vm._v("实际抵扣金额")]),
          _vm._v(" "),
          _c("div", { staticClass: "num" }, [
            _vm._v(_vm._s(_vm.couponDetailData.realDisMoney)),
            _c("span", { staticClass: "unit" }, [_vm._v("元")])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "title" }, [_vm._v("优惠券规则")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "detail-wrapper" },
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 8 } }, [
                _vm._v(
                  "优惠券名称:" + _vm._s(_vm.couponDetailData.coupon.couponName)
                )
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8 } }, [
                _vm._v(
                  "优惠券有效时期:" +
                    _vm._s(
                      _vm.couponDetailData.coupon.couponExpireType == 1
                        ? _vm.couponDetailData.coupon.couponExpireDate
                        : _vm.couponDetailData.coupon.couponExpireType == 2
                        ? "领取后" +
                          _vm.couponDetailData.coupon.couponExpireDay +
                          "天失效"
                        : "永久"
                    )
                )
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8 } }, [
                _vm._v(
                  "业务类型:" +
                    _vm._s(
                      _vm._f("couponScopeFilter")(
                        _vm.couponDetailData.coupon.couponScope
                      )
                    )
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            { staticStyle: { "margin-top": "15px" } },
            [
              _c("el-col", { attrs: { span: 8 } }, [
                _vm._v(
                  "优惠类型:" +
                    _vm._s(
                      _vm._f("couponTypeFilter")(
                        _vm.couponDetailData.coupon.couponType
                      )
                    )
                )
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8 } }, [
                _vm._v(
                  _vm._s(
                    _vm.couponDetailData.coupon.couponType == 2
                      ? "满减：" + _vm.couponDetailData.coupon.direct
                      : "折扣：" + _vm.couponDetailData.coupon.discount
                  )
                )
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 8 } }, [
                _vm._v(
                  "使用条件:" +
                    _vm._s(
                      _vm.couponDetailData.coupon.receivePrice == 0
                        ? "无使用条件"
                        : "满" +
                            _vm.couponDetailData.coupon.receivePrice +
                            "可用"
                    )
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "title" }, [_vm._v("领取统计")]),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "queryForm",
          staticStyle: { "margin-top": "20px" },
          attrs: {
            model: _vm.queryParams,
            size: "small",
            inline: true,
            "label-width": "100px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "领取时间" } },
            [
              _c("el-date-picker", {
                staticStyle: { width: "380px" },
                attrs: {
                  size: "small",
                  "value-format": "yyyy-MM-dd",
                  format: "yyyy-MM-dd",
                  type: "daterange",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期"
                },
                model: {
                  value: _vm.value1,
                  callback: function($$v) {
                    _vm.value1 = $$v
                  },
                  expression: "value1"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "使用时间" } },
            [
              _c("el-date-picker", {
                staticStyle: { width: "380px" },
                attrs: {
                  size: "small",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  format: "yyyy-MM-dd HH:mm:ss",
                  type: "datetimerange",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期"
                },
                model: {
                  value: _vm.value2,
                  callback: function($$v) {
                    _vm.value2 = $$v
                  },
                  expression: "value2"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "用户手机号", prop: "phone" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入用户手机号", clearable: "" },
                nativeOn: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.handleQuery($event)
                  }
                },
                model: {
                  value: _vm.queryParams.phone,
                  callback: function($$v) {
                    _vm.$set(_vm.queryParams, "phone", $$v)
                  },
                  expression: "queryParams.phone"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    icon: "el-icon-search",
                    size: "mini"
                  },
                  on: { click: _vm.handleQuery }
                },
                [_vm._v("搜索")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-refresh", size: "mini" },
                  on: { click: _vm.resetQuery }
                },
                [_vm._v("重置")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "hasPermi",
                      rawName: "v-hasPermi",
                      value: ["activity:coupon:detail:export"],
                      expression: "['activity:coupon:detail:export']"
                    }
                  ],
                  attrs: {
                    type: "warning",
                    icon: "el-icon-download",
                    size: "mini"
                  },
                  on: { click: _vm.handleExport }
                },
                [_vm._v("导出")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          staticStyle: { "margin-top": "15px" },
          attrs: { data: _vm.couponList }
        },
        [
          _vm.couponDetailData.coupon.couponScope == 2
            ? _c("el-table-column", {
                attrs: { label: "班车票id", align: "center", prop: "ticketId" }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "领取时间", align: "center", prop: "receiveTime" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "用户手机号", align: "center", prop: "phone" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "使用时间", align: "center", prop: "useTime" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "实际抵扣金额",
              align: "center",
              prop: "discountedPrice"
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "订单号", align: "center", prop: "orderNo" }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: "验票状态", align: "center", prop: "checkStatus" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm.couponDetailData.coupon.couponScope == 2
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              !scope.row.checkStatus &&
                                scope.row.checkStatus != 0
                                ? ""
                                : scope.row.checkStatus == 0
                                ? "未验票"
                                : "已验票"
                            )
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.couponDetailData.coupon.couponScope == 3
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("checkStatusFilter")(scope.row.checkStatus)
                            )
                          )
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _vm.couponDetailData.coupon.couponScope == 3
            ? _c("el-table-column", {
                attrs: { label: "支付状态", align: "center", prop: "status" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm._f("statusFilter")(scope.row.status))
                            )
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3239357375
                )
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0"
          }
        ],
        attrs: {
          total: _vm.total,
          page: _vm.queryParams.pageNum,
          limit: _vm.queryParams.pageSize
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.queryParams, "pageNum", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.queryParams, "pageSize", $event)
          },
          pagination: _vm.getList
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }