"use strict";

var _interopRequireDefault = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireDefault");

var _interopRequireWildcard = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var echarts = _interopRequireWildcard(require("echarts"));

var _resize = _interopRequireDefault(require("./mixins/resize"));

//
//
//
//
require("echarts/theme/macarons"); // echarts theme


var _default = {
  mixins: [_resize.default],
  props: {
    className: {
      type: String,
      default: "chart"
    },
    width: {
      type: String,
      default: "100%"
    },
    height: {
      type: String,
      default: "500px"
    },
    chartData: {
      type: Object
    }
  },
  data: function data() {
    return {
      chart: null
    };
  },
  watch: {
    chartData: {
      deep: true,
      handler: function handler(val) {
        this.setOptions(val);
      }
    }
  },
  mounted: function mounted() {
    var _this = this;

    this.$nextTick(function () {
      _this.initChart();
    });
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }

    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart: function initChart() {
      this.chart = echarts.init(this.$el);
      this.chart.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999"
            }
          }
        },
        legend: {
          data: ["座次", "实售票数", "上座率"],
          textStyle: {
            color: "#fff"
          }
        },
        xAxis: [{
          type: "category",
          axisPointer: {
            type: "shadow"
          }
        }],
        yAxis: [{
          type: "value",
          name: "实售票数",
          axisLabel: {
            formatter: "{value} 张"
          }
        }, {
          type: "value",
          name: "上座率",
          axisLabel: {
            formatter: "{value} %"
          }
        }]
      });
    },
    // 设置数据
    setOptions: function setOptions(val) {
      this.chart.setOption({
        xAxis: [{
          type: "category",
          axisPointer: {
            type: "shadow"
          }
        }],
        series: [{
          name: "座次",
          type: "bar",
          tooltip: {
            valueFormatter: function valueFormatter(value) {
              return value + " 次";
            }
          },
          data: this.chartData.totalSeat
        }, {
          name: "实售票数",
          type: "bar",
          tooltip: {
            valueFormatter: function valueFormatter(value) {
              return value + " 张";
            }
          },
          data: this.chartData.saleSeat
        }, {
          name: "上座率",
          type: "line",
          smooth: true,
          yAxisIndex: 1,
          tooltip: {
            valueFormatter: function valueFormatter(value) {
              return value + " %";
            }
          },
          data: this.chartData.pct
        }]
      });
    }
  }
};
exports.default = _default;