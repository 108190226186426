import _objectSpread from "D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime-corejs2/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { getDriverInfo, getDriverOrder, getDriverBillList, freezeDriver } from "@/api/system/driver";
export default {
  name: "Detail",
  data: function data() {
    return {
      user: {
        baseInfo: {},
        accountInfo: {}
      },
      tableData: [],
      tableData2: [],
      // 总条数
      total: 0,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10
      },
      // 总条数
      billTotal: 0,
      // 查询参数
      queryBillParams: {
        pageNum: 1,
        pageSize: 10
      },
      id: null,
      companyId: null,
      activeTab: "order"
    };
  },
  created: function created() {
    this.id = this.$route.query.id;
    this.companyId = this.$route.query.companyId;
    this.getDetail();
    this.geOrdertList();
    this.getBillList();
  },
  methods: {
    filterChannel: function filterChannel(value, scanCode) {
      switch (value) {
        case "CUSTOM":
          return "客服代买票";

        case "WECHAT_MINI_APP":
          if (scanCode == 1) {
            return '扫码购票';
          }

          return "小程序买票";

        case "WECHAT_MINI_APP_SO":
          return "拼车扫码下单";

        default:
          return "暂无";
      }
    },
    getDetail: function getDetail() {
      var _this = this;

      getDriverInfo({
        driverId: this.id,
        companyId: this.companyId
      }).then(function (response) {
        _this.user = response.data;
      });
    },
    geOrdertList: function geOrdertList() {
      var _this2 = this;

      getDriverOrder(_objectSpread({}, this.queryParams, {
        driverId: this.id,
        companyId: this.companyId
      })).then(function (response) {
        _this2.tableData = response.rows;
        _this2.total = response.total;
      });
    },
    getBillList: function getBillList() {
      var _this3 = this;

      getDriverBillList(_objectSpread({}, this.queryBillParams, {
        driverId: this.id
      })).then(function (response) {
        _this3.tableData2 = response.rows;
        _this3.billTotal = response.total;
      });
    },
    // 操作账户是否冻结
    disableAccount: function disableAccount() {
      var _this4 = this;

      var params = {
        driverId: this.id,
        companyId: this.companyId,
        freeze: this.user.accountInfo.fundStatus ? 0 : 1 //1-冻结 0-解冻 是否冻结司机,不让司机提现

      };
      freezeDriver(params).then(function (response) {
        if (response.code === 200) {
          _this4.getDetail();

          _this4.msgSuccess("操作成功");
        } else {
          _this4.msgError(response.msg);
        }
      });
    },
    viewDetails: function viewDetails(row) {
      this.$router.push("/carpoolManage/orderManage/orderDetail?orderNo=" + row.orderNo);
    }
  }
};