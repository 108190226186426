"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _coupon = require("@/api/system/coupon");

var _index = require("@/utils/index");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "CouponDetail",
  data: function data() {
    return {
      // 遮罩层
      loading: false,
      // 总条数
      total: 0,
      // 优惠券表格数据
      couponList: [],
      // 表单参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        startTime: null,
        endTime: null,
        couponId: null,
        useStartTime: null,
        useEndTime: null,
        phone: null
      },
      couponId: null,
      couponDetailData: {
        coupon: {}
      },
      value1: "",
      value2: ""
    };
  },
  filters: {
    checkStatusFilter: function checkStatusFilter(status) {
      var name = '';

      switch (status) {
        case 0:
          name = "未指派";
          break;

        case 1:
          name = "已指派";
          break;

        case 2:
          name = "去接人";
          break;

        case 3:
          name = "已到达上车点";
          break;

        case 4:
          name = "乘客已上车";
          break;

        case 5:
          name = "开始送人";
          break;

        case 6:
          name = "订单已完成";
          break;
      }

      return name;
    },
    statusFilter: function statusFilter(status) {
      var name = '';

      switch (status) {
        case 100:
          name = "未支付";
          break;

        case 200:
          name = "已支付";
          break;

        case 400:
          name = "已取消";
          break;

        case 450:
          name = "退款中";
          break;

        case 460:
          name = "退款失败";
          break;

        case 500:
          name = "已退款";
          break;
      }

      return name;
    }
  },
  mounted: function mounted() {
    console.log(this.$route.query.id);
    this.couponId = this.$route.query.id;
    this.queryParams.couponId = this.couponId;
    this.getDetail();
  },
  methods: {
    /** 重置按钮操作 */
    resetQuery: function resetQuery() {
      this.resetForm("queryForm");
      this.value1 = undefined;
      this.value2 = undefined;
      this.handleQuery();
    },

    /** 搜索按钮操作 */
    handleQuery: function handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },

    /** 获取优惠券详情 */
    getDetail: function getDetail() {
      var _this = this;

      (0, _coupon.couponDetail)({
        couponId: this.couponId
      }).then(function (response) {
        if (response.code == 200) {
          _this.couponDetailData = response.data;

          _this.getList();
        }
      });
    },

    /** 领取记录 */
    getList: function getList() {
      var _this2 = this;

      this.loading = true;

      if (this.value1 && this.value1.length) {
        this.queryParams.startTime = this.value1[0];
        this.queryParams.endTime = this.value1[1];
      } else {
        this.queryParams.startTime = null;
        this.queryParams.endTime = null;
      }

      if (this.value2 && this.value2.length) {
        this.queryParams.useStartTime = this.value2[0];
        this.queryParams.useEndTime = this.value2[1];
      } else {
        this.queryParams.useStartTime = null;
        this.queryParams.useEndTime = null;
      }

      console.log(this.queryParams); // 班车

      if (this.couponDetailData.coupon.couponScope == 2) {
        (0, _coupon.couponDetailList)(this.queryParams).then(function (res) {
          _this2.loading = false;

          if (res.code == 200) {
            _this2.total = res.total;
            _this2.couponList = res.rows;
          }
        }).catch(function () {
          _this2.loading = false;
        });
      } else if (this.couponDetailData.coupon.couponScope == 3) {
        // 拼车
        (0, _coupon.carpoolCouponDetailList)(this.queryParams).then(function (res) {
          _this2.loading = false;

          if (res.code == 200) {
            _this2.total = res.total;
            _this2.couponList = res.rows;
          }
        }).catch(function () {
          _this2.loading = false;
        });
      }
    },

    /** 导出按钮操作 */
    handleExport: function handleExport() {
      var _this3 = this;

      var queryParams = this.queryParams;

      if (this.value1 && this.value1.length) {
        queryParams.startTime = this.value1[0];
        queryParams.endTime = this.value1[1];
      } else {
        queryParams.startTime = null;
        queryParams.endTime = null;
      }

      if (this.value2 && this.value2.length) {
        queryParams.useStartTime = this.value2[0];
        queryParams.useEndTime = this.value2[1];
      } else {
        queryParams.useStartTime = null;
        queryParams.useEndTime = null;
      }

      this.$confirm("是否确认导出所有领取记录?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        // 班车
        if (_this3.couponDetailData.coupon.couponScope == 2) {
          return (0, _coupon.couponDetailListExport)(queryParams);
        } else if (_this3.couponDetailData.coupon.couponScope == 3) {
          // 拼车
          return (0, _coupon.carpoolCouponDetailListExport)(queryParams);
        }
      }).then(function (response) {
        _this3.download(response.msg);
      }).catch(function (err) {
        console.log(err);
      });
    }
  }
};
exports.default = _default;