"use strict";

var _interopRequireDefault = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime-corejs2/helpers/objectSpread2"));

var _axios = _interopRequireDefault(require("axios"));

var _operationScopeDialog = _interopRequireDefault(require("./operationScopeDialog.vue"));

var _linePriceDialog = _interopRequireDefault(require("./linePriceDialog.vue"));

var _timeDialog = _interopRequireDefault(require("./timeDialog.vue"));

var _dateDialog = _interopRequireDefault(require("./dateDialog.vue"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    operationScopeDialog: _operationScopeDialog.default,
    timeDialog: _timeDialog.default,
    linePriceDialog: _linePriceDialog.default,
    dateDialog: _dateDialog.default
  },
  data: function data() {
    return {
      lineId: undefined,
      openOperationScopeDialog: false,
      openLinePriceDialog: false,
      openTimeDialog: false,
      openDateDialog: false,
      openCancelRulesDialog: false,
      configData: {},
      operationData: {
        type: "",
        cityCode: "",
        countryCode: "",
        lineId: "",
        startCityName: "",
        endCityName: ""
      },
      lists: [{
        config: "运营范围配置",
        type: "openOperationScopeDialog"
      }, {
        config: "价格配置",
        type: "openLinePriceDialog"
      }, {
        config: "运营时间配置",
        type: "openTimeDialog"
      }, {
        config: "跨城闪送运营周期",
        type: "openDateDialog"
      }],
      userName: ""
    };
  },
  mounted: function mounted(area) {
    var _this = this;

    this.lineId = this.$route.query.lineId;
    this.startCityName = this.$route.query.startCityName;
    this.endCityName = this.$route.query.endCityName;

    _axios.default.get(this.parcelUrl + "/examinationClient/lineConfig/getInfo", {
      params: {
        lineId: this.lineId
      }
    }).then(function (response) {
      _this.configData = response.data.data;
    });

    area = {};
    area.cityCode = "130100";
    area.countryCode = "130183";
    this.operationData = {
      type: "edit",
      cityCode: area.cityCode,
      countryCode: area.countryCode,
      lineId: this.lineId,
      startCityName: this.startCityName,
      endCityName: this.endCityName
    };
  },
  methods: {
    closeOperationScopeDialog: function closeOperationScopeDialog() {
      this.openOperationScopeDialog = false;
    },
    closeLinePriceDialog: function closeLinePriceDialog() {
      this.openLinePriceDialog = false;
    },
    closeTimeDialog: function closeTimeDialog() {
      this.openTimeDialog = false;
    },
    closeDateDialog: function closeDateDialog() {
      this.openDateDialog = false;
    },
    closeCancelRulesDialog: function closeCancelRulesDialog() {
      this.openCancelRulesDialog = false;
    },
    submitOperationScopeDialog: function submitOperationScopeDialog(row) {
      console.log(row);
    },
    submitCallBack: function submitCallBack(row) {
      console.log(Object.assign(this.configData, row), "----configData----");
      console.log((0, _objectSpread2.default)({}, this.configData, {}, row), "------callback--------");

      _axios.default.post(this.parcelUrl + "/examinationClient/lineConfig/updateLineConfig", (0, _objectSpread2.default)({}, this.configData, {}, row)).then(function (res) {
        if (res.data.code === 10000) {
          console.log(res, "-------callback------");
        }
      });
    },
    // submitTimeDialog(row) {
    //   console.log(row);
    // },
    // submitCancelRulesDialog(row) {
    //   console.log(row);
    // },
    // submitServiceDialog(row) {
    //   console.log(row);
    // },
    goDetails: function goDetails(type) {
      this[type] = true;
    }
  }
};
exports.default = _default;