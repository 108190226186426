"use strict";

var _interopRequireDefault = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

var _PanelGroup = _interopRequireDefault(require("./dashboard/PanelGroup"));

var _LineChart = _interopRequireDefault(require("./dashboard/LineChart"));

var _RaddarChart = _interopRequireDefault(require("./dashboard/RaddarChart"));

var _PieChart = _interopRequireDefault(require("./dashboard/PieChart"));

var _BarChart = _interopRequireDefault(require("./dashboard/BarChart"));

var _server = require("@/api/monitor/server");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var lineChartData = {
  newVisitis: {
    expectedData: [100, 120, 161, 134, 105, 160, 165],
    actualData: [120, 82, 91, 154, 162, 140, 145]
  },
  messages: {
    expectedData: [200, 192, 120, 144, 160, 130, 140],
    actualData: [180, 160, 151, 106, 145, 150, 130]
  },
  purchases: {
    expectedData: [80, 100, 121, 104, 105, 90, 100],
    actualData: [120, 90, 100, 138, 142, 130, 130]
  },
  shoppings: {
    expectedData: [130, 140, 141, 142, 145, 150, 160],
    actualData: [120, 82, 91, 154, 162, 140, 130]
  }
};
var _default = {
  name: 'Index',
  components: {
    PanelGroup: _PanelGroup.default,
    LineChart: _LineChart.default,
    RaddarChart: _RaddarChart.default,
    PieChart: _PieChart.default,
    BarChart: _BarChart.default
  },
  data: function data() {
    return {
      countData: {
        count1: {
          text: '总收益',
          count: 0
        },
        count2: {
          text: '总客量(票)',
          count: 0
        },
        count3: {
          text: '总订单',
          count: 0
        }
      },
      chartData: {
        title: {
          text: "客流量趋势图",
          textStyle: {
            color: "#333",
            fontWeight: "bolder"
          }
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: ["周一", "周二", "周三", "周四", "周五", "周六", "周日"]
        },
        yAxis: {
          type: "value"
        },
        tooltip: {
          trigger: "axis"
        },
        legend: {
          data: ["扫码", "APP", "公众号", "小件速运"]
        },
        series: [{
          name: "扫码",
          type: "line",
          smooth: false,
          data: [0, 0, 0, 0, 0, 0, 0]
        }, {
          name: "APP",
          type: "line",
          smooth: false,
          data: [0, 0, 0, 0, 0, 0, 0]
        }, {
          name: "公众号",
          type: "line",
          smooth: false,
          data: [525, 460, 626, 554, 534, 245, 0]
        }, {
          name: "小件速运",
          type: "line",
          smooth: false,
          data: [200, 100, 100, 100, 100, 101, 200]
        }]
      },
      chartData2: {
        title: {
          text: "收益趋势图",
          textStyle: {
            color: "#333",
            fontWeight: "bolder"
          }
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: ["周一", "周二", "周三", "周四", "周五", "周六", "周日"]
        },
        yAxis: {
          type: "value"
        },
        tooltip: {
          trigger: "axis"
        },
        legend: {
          data: ["扫码", "APP", "公众号", "小件速运"]
        },
        series: [{
          name: "扫码",
          type: "line",
          smooth: false,
          data: [0, 0, 0, 0, 0, 0, 0]
        }, {
          name: "APP",
          type: "line",
          smooth: false,
          data: [0, 0, 0, 0, 0, 0, 0]
        }, {
          name: "公众号",
          type: "line",
          smooth: false,
          data: [525, 460, 626, 554, 534, 245, 0]
        }, {
          name: "小件速运",
          type: "line",
          smooth: false,
          data: [200, 100, 100, 100, 100, 101, 200]
        }]
      },
      jinRiKeLiang: {},
      jinRiKeShouYi: {},
      options: [{
        value: 0,
        label: '本周'
      }, {
        value: 1,
        label: '本月'
      }, {
        value: 2,
        label: '三个月'
      }],
      types: ['本周', '本月', '三个月'],
      value1: 0,
      value2: 0,
      lineChartData: lineChartData.newVisitis,
      smsDetail: {}
    };
  },
  created: function created() {
    var _this = this;

    this.getKeLiang();
    this.getShouYi();
    (0, _server.defaultMsg)().then(function (res) {
      _this.countData.count1.count = res.data.zongShouYi;
      _this.countData.count2.count = res.data.zongKeLiang;
      _this.countData.count3.count = res.data.zongDingDan;
    });
    this.handleSetLineChartData1();
    this.handleSetLineChartData2();
  },
  methods: {
    getShouYi: function getShouYi() {
      var _this2 = this;

      (0, _server.shouYiKeLiang)().then(function (res) {
        _this2.jinRiKeShouYi = res.data;
      });
    },
    getKeLiang: function getKeLiang() {
      var _this3 = this;

      (0, _server.jinRiKeLiang)().then(function (res) {
        _this3.jinRiKeLiang = res.data;
      });
    },
    handleSetLineChartData1: function handleSetLineChartData1(type) {
      var _this4 = this;

      (0, _server.rongLiangQuShiTu)({
        type: this.types[this.value1]
      }).then(function (res) {
        res.data.series.forEach(function (item) {
          delete item.stack;
        });
        _this4.chartData.legend.data = res.data.legend;
        _this4.chartData.series = res.data.series;
        _this4.chartData.xAxis.data = res.data.xAxis;
      });
    },
    handleSetLineChartData2: function handleSetLineChartData2(type) {
      var _this5 = this;

      (0, _server.shouYiQuShiTu)({
        type: this.types[this.value2]
      }).then(function (res) {
        res.data.series.forEach(function (item) {
          delete item.stack;
        });
        _this5.chartData2.legend.data = res.data.legend;
        _this5.chartData2.series = res.data.series;
        _this5.chartData2.xAxis.data = res.data.xAxis;
      });
    },
    gotoData: function gotoData() {
      this.$router.push({
        path: "/dataAnalysis"
      });
    },
    gotoCarpoolData: function gotoCarpoolData() {
      this.$router.push({
        path: "/dataAnalysisCarpool"
      });
    }
  }
};
exports.default = _default;