exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".fanganFormData .el-table .cell{overflow:inherit}.el-dialog__body .el-form--inline .el-form-item{margin-right:60px}.el-dialog__body .el-form--inline .el-form-item.mr120 .el-form-item__label{width:120px !important}.el-dialog__body .el-form--inline .el-form-item.mr140 .el-form-item__label{width:140px !important}.showpiaoSetting .el-dialog__body .el-form--inline .el-form-item{margin-right:150px}\n", ""]);

// exports
exports.locals = {
	"menuText": "#bfcbd9",
	"menuActiveText": "#409EFF",
	"subMenuActiveText": "#f4f4f5",
	"menuBg": "#304156",
	"menuHover": "#263445",
	"subMenuBg": "#1f2d3d",
	"subMenuHover": "#001528",
	"sideBarWidth": "200px"
};