"use strict";

var _interopRequireDefault = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _createForOfIteratorHelper2 = _interopRequireDefault(require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime-corejs2/helpers/createForOfIteratorHelper"));

require("core-js/modules/es6.array.find-index");

require("core-js/modules/es6.number.constructor");

var _defineProperty2 = _interopRequireDefault(require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime-corejs2/helpers/defineProperty"));

var _utils = require("@/utils");

var _carpool = require("@/api/business/carpool");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      carTypeList: [],
      formCar: (0, _defineProperty2.default)({
        updateStatus: 0,
        // 1-修改 0-新增 数据传递到后台用于识别该条数据是新增的数据还是需要修改的数据
        carType: null,
        activeName: "城际拼车",
        carpoolLineConfigCJPC: {
          // 城际拼车
          isLadderMoney: 0,
          money: "",
          specialAreaIsMarkup: 0
        },
        carpoolLinePayConfigListCJPC: [// 城际拼车阶梯票价
        {
          personNum: 1,
          unitPrice: ""
        }],
        ladderCJPCDel: [],
        // 删除的拼车阶梯模式 阶梯费用
        carpoolLinePayConfigTimeIsLadderListCJPC: [],
        // 城际拼车阶梯票价(分时段)
        ladderTimeCJPCDel: [],
        // 删除的拼车阶梯模式 分时段费用
        carpoolLinePayConfigTimeListCJPC: [],
        // 城际拼车固定票价
        fixedTimeCJPCDel: [],
        // 删除的拼车固定模式 分时段费用
        carpoolElectricFenceListUpCJPC: null,
        // 城际拼车上车点
        carpoolElectricFenceListDownCJPC: null,
        // 城际拼车下车点
        carpoolLineConfigCJBC: {
          // 城际包车
          isLadderMoney: 0,
          money: "",
          specialAreaIsMarkup: 0
        },
        carpoolLinePayConfigTimeListCJBC: [],
        // 城际包车分时段计价
        fixedTimeCJBCDel: [],
        // 删除的包车固定模式 分时段费用
        carpoolElectricFenceListUpCJBC: null,
        // 城际包车上车点
        carpoolElectricFenceListDownCJBC: null,
        // 城际包车下车点
        scheduleType: 0
      }, "updateStatus", 0),
      deleteCarpoolScheduleCarList: [],
      carpoolElectricFenceListUpCJPC: [],
      // 拼车上车点
      carpoolElectricFenceListDownCJPC: [],
      // 拼车下车点
      carpoolElectricFenceListUpCJBC: [],
      // 包车上车点
      carpoolElectricFenceListDownCJBC: [],
      // 包车下车点
      loading: false
    };
  },
  props: {
    openScheduleCarDialog: {
      type: Boolean,
      default: false
    },
    operationData: {
      type: Object,
      required: true
    },
    templateScheduleId: {
      type: Number
    }
  },
  components: {},
  created: function created() {
    var _this = this;

    this.getCarpoolElectricFenceList(); // 获取车辆列表

    (0, _carpool.getCarType)({
      companyId: this.operationData.companyId
    }).then(function (res) {
      if (res.code == 200) {
        _this.carTypeList = res.data; //   有Id， 修改进来的

        if (_this.templateScheduleId) {
          // 修改1
          _this.formCar.updateStatus = 1;
          (0, _carpool.selectCarpoolScheduleCarById)({
            templateScheduleId: _this.templateScheduleId
          }).then(function (res) {
            var item = res.data;
            item.carType = _this.carTypeList.findIndex(function (car) {
              return car.seriesId === item.seriesId;
            });
            item.activeName = Number(item.serviceTypeCode) == 0 ? '城际拼车' : "城际包车";
            item.carpoolLinePayConfigTimeIsLadderListCJPC = _this.generateDataString(item.carpoolLinePayConfigTimeIsLadderListCJPC);
            item.carpoolLinePayConfigTimeListCJPC = _this.generateDataString(item.carpoolLinePayConfigTimeListCJPC);
            item.carpoolLinePayConfigTimeListCJBC = _this.generateDataString(item.carpoolLinePayConfigTimeListCJBC); // 不限

            if (item.scheduleType == 0) {
              item.totalSeat = 0;
              item.totalSeatCjpc = 0;
              item.totalSeatCjbc = 0;
            } else if (item.scheduleType == 1) {
              //区分类型
              item.totalSeat = 0;
            } else if (item.scheduleType == 2) {
              // 不区分类型
              item.totalSeatCjpc = 0;
              item.totalSeatCjbc = 0;
            }

            _this.formCar = item;
          });
        }
      }
    });
  },
  mounted: function mounted() {},
  methods: {
    // 转换json
    generateDataString: function generateDataString(obj) {
      if (obj) {
        obj.map(function (item, index) {
          if ("dataString" in item) {
            item.dataString = JSON.parse(item.dataString);
          } else {
            item.dataString = [];
          }
        });
      } else {
        // obj = [{ dataString: [{ personNum: 1 }] }];
        obj = [];
      }

      return obj;
    },
    // 获取上下车点区域
    getCarpoolElectricFenceList: function getCarpoolElectricFenceList() {
      var _this2 = this;

      // 上车点
      (0, _carpool.carpoolElectricFenceList)({
        lineId: this.operationData.id,
        serviceType: "城际拼车",
        upOrDown: 1
      }).then(function (res) {
        if (res.code == 200) {
          _this2.formCar.carpoolElectricFenceListUpCJPC = res.rows; // 城际拼车上车点
        }
      }); // 下车点

      (0, _carpool.carpoolElectricFenceList)({
        lineId: this.operationData.id,
        serviceType: "城际拼车",
        upOrDown: 3
      }).then(function (res) {
        if (res.code == 200) {
          _this2.formCar.carpoolElectricFenceListDownCJPC = res.rows;
        }
      }); // 上车点

      (0, _carpool.carpoolElectricFenceList)({
        lineId: this.operationData.id,
        serviceType: "城际包车",
        upOrDown: 1
      }).then(function (res) {
        if (res.code == 200) {
          _this2.formCar.carpoolElectricFenceListUpCJBC = res.rows;
        }
      }); // 下车点

      (0, _carpool.carpoolElectricFenceList)({
        lineId: this.operationData.id,
        serviceType: "城际包车",
        upOrDown: 3
      }).then(function (res) {
        if (res.code == 200) {
          _this2.formCar.carpoolElectricFenceListDownCJBC = res.rows;
        }
      });
    },
    // 确定,保存车型
    okScheduleTemplateFn: function okScheduleTemplateFn() {
      var _this3 = this;

      var formCar = this.formCar;
      console.log(formCar);

      if (!formCar.templateName) {
        this.msgError("请输入模板名称");
        return;
      }

      var carType = this.carTypeList[formCar.carType];

      if (!formCar.carType && formCar.carType != 0) {
        this.msgError("请选择车型");
        return;
      }

      formCar.lineId = this.operationData.id;
      formCar.lineName = this.operationData.lineName;
      formCar.serviceTypeCode = formCar.activeName == '城际拼车' ? 0 : 1;
      formCar.serviceType = formCar.activeName;
      formCar.scheduleCarIdList = this.scheduleCarIdList; // 拼车

      if (formCar.serviceTypeCode == 0) {
        // 阶梯模式
        if (formCar.carpoolLineConfigCJPC.isLadderMoney == 1) {
          var _iterator = (0, _createForOfIteratorHelper2.default)(formCar.carpoolLinePayConfigListCJPC),
              _step;

          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var payConfigCJPC = _step.value;

              if (payConfigCJPC.personNum <= 0) {
                this.msgError("请填写人数");
                return;
              }

              if (payConfigCJPC.unitPrice <= 0) {
                this.msgError("请填写价格");
                return;
              }
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }
        } else {
          // 固定模式
          if (formCar.carpoolLineConfigCJPC.money <= 0) {
            this.msgError("请填写价格");
            return;
          }
        }
      } else {
        // 包车
        if (formCar.carpoolLineConfigCJBC.money <= 0) {
          this.msgError("请填写价格");
          return;
        }
      }

      formCar.carpoolLinePayConfigTimeListCJBC.map(function (item, index) {
        if ("dataString" in item && typeof item.dataString != "string") {
          item.dataString = JSON.stringify(item.dataString);
        }
      });
      formCar.fixedTimeCJBCDel.map(function (item, index) {
        if ("dataString" in item && typeof item.dataString != "string") {
          item.dataString = JSON.stringify(item.dataString);
        }
      });
      formCar.carpoolLinePayConfigTimeListCJPC.map(function (item, index) {
        if ("dataString" in item && typeof item.dataString != "string") {
          item.dataString = JSON.stringify(item.dataString);
        }
      });
      formCar.fixedTimeCJPCDel.map(function (item, index) {
        if ("dataString" in item && typeof item.dataString != "string") {
          item.dataString = JSON.stringify(item.dataString);
        }
      });
      formCar.carpoolLinePayConfigTimeIsLadderListCJPC.map(function (item, index) {
        if ("dataString" in item && typeof item.dataString != "string") {
          item.dataString = JSON.stringify(item.dataString);
        }
      });
      formCar.ladderTimeCJPCDel.map(function (item, index) {
        if ("dataString" in item && typeof item.dataString != "string") {
          item.dataString = JSON.stringify(item.dataString);
        }
      });
      formCar.brandId = carType.brandId;
      formCar.brandName = carType.brandName;
      formCar.seriesId = carType.seriesId;
      formCar.seriesName = carType.seriesName;
      formCar.comfort = carType.comfort;
      formCar.scheduleCarId; // 新增车型配置模板

      this.loading = true;
      (0, _carpool.insertScheduleAndConfigTemplate)(formCar).then(function (res) {
        _this3.loading = false;

        if (res.code == 200) {
          _this3.$emit("closepop"); //通知父组件改变。

        }
      }).catch(function (res) {
        _this3.loading = false;
      });
    },
    // 关闭弹框
    handleDialogClose: function handleDialogClose() {
      this.$emit("closepop"); //通知父组件改变。
    },
    // 城际拼车新增阶梯
    addItem: function addItem() {
      this.formCar.carpoolLinePayConfigListCJPC.push({
        unitPrice: "",
        personNum: ""
      });
    },
    // 城际拼车删除阶梯
    delItem: function delItem(idx) {
      // 添加到删除集合
      this.formCar.ladderCJPCDel.push(this.formCar.carpoolLinePayConfigListCJPC[idx]);
      this.formCar.carpoolLinePayConfigListCJPC.splice(idx, 1);
    },
    // 城际拼车新增分时段阶梯票价
    addTimeItemJieTi: function addTimeItemJieTi() {
      this.formCar.carpoolLinePayConfigTimeIsLadderListCJPC.push({
        startTime: "",
        endTime: "",
        dataString: [{
          personNum: 1
        }]
      });
    },
    // 城际拼车删除分时段阶梯票价
    delTimeItemJieTi: function delTimeItemJieTi(idx) {
      // 添加到删除集合
      this.formCar.ladderTimeCJPCDel.push(this.formCar.carpoolLinePayConfigTimeIsLadderListCJPC[idx]);
      this.formCar.carpoolLinePayConfigTimeIsLadderListCJPC.splice(idx, 1);
    },
    // 城际拼车删除分时段阶梯票价，添加人数配置
    addTimeItemConfigJieTi: function addTimeItemConfigJieTi(i) {
      console.log(i, "----");
      this.formCar.carpoolLinePayConfigTimeIsLadderListCJPC[i].dataString.push({
        personNum: "",
        unitPrice: ""
      });
    },
    // 城际拼车删除分时段阶梯票价，删除人数配置
    delTimeItemConfigJieTi: function delTimeItemConfigJieTi(i, ii) {
      this.formCar.carpoolLinePayConfigTimeIsLadderListCJPC[i].dataString.splice(ii, 1);
    },
    // 城际拼车删除分时段阶梯票价（固定模式）
    addTimeItem: function addTimeItem() {
      this.formCar.carpoolLinePayConfigTimeListCJPC.push({
        startTime: "",
        endTime: "",
        dataString: [{
          personNum: 1
        }]
      });
    },
    // 城际拼车删除分时段阶梯票价（固定模式）
    delTimeItem: function delTimeItem(idx) {
      // 添加到删除集合
      this.formCar.fixedTimeCJPCDel.push(this.formCar.carpoolLinePayConfigTimeListCJPC[idx]);
      this.formCar.carpoolLinePayConfigTimeListCJPC.splice(idx, 1);
    },
    // 城际包车新增分时段计价
    addTimeItemBc: function addTimeItemBc() {
      this.formCar.carpoolLinePayConfigTimeListCJBC.push({
        startTime: "",
        endTime: "",
        dataString: [{
          personNum: 1
        }]
      });
    },
    // 城际包车删除分时段计价
    delTimeItemBc: function delTimeItemBc(idx) {
      // 添加到删除集合
      this.formCar.fixedTimeCJBCDel.push(this.formCar.carpoolLinePayConfigTimeListCJBC[idx]);
      this.formCar.carpoolLinePayConfigTimeListCJBC.splice(idx, 1);
    }
  }
};
exports.default = _default;