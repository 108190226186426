"use strict";

var _interopRequireDefault = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _axios = _interopRequireDefault(require("axios"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  props: {
    openDateDialog: {
      type: Boolean,
      default: false
    },
    rawData: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      activeName: "first",
      // 表单校验
      rules: {
        startTime: [{
          required: true,
          message: "请选择最早揽件时间",
          trigger: "change"
        }],
        endTime: [{
          required: true,
          message: "请选择最晚揽件时间",
          trigger: "change"
        }]
      },
      weeks: [{
        label: '周一',
        value: 'mon'
      }, {
        label: '周二',
        value: 'tues'
      }, {
        label: '周三',
        value: 'wed'
      }, {
        label: '周四',
        value: 'thurs'
      }, {
        label: '周五',
        value: 'fri'
      }, {
        label: '周六',
        value: 'sat'
      }, {
        label: '周日',
        value: 'sun'
      }],
      checkList: [],
      id: 0
    };
  },
  mounted: function mounted() {
    console.log(this.openDateDialog);
    this.getList();
  },
  methods: {
    handleDialogClose: function handleDialogClose() {
      this.$emit("closepop"); //通知父组件改变。
    },
    // 获取列表
    getList: function getList() {
      var _this = this;

      this.loading = true;

      _axios.default.get(this.parcelUrl + "/examination/lineTimeConfig/selectByLineId", {
        params: {
          lineId: this.rawData.lineId
        }
      }).then(function (response) {
        if (response.data.code == 200) {
          var res = response.data.data[0];
          _this.id = res.id;
          console.log(res);

          if (res.mon) {
            _this.checkList.push('mon');
          }

          if (res.tues) {
            _this.checkList.push('tues');
          }

          if (res.wed) {
            _this.checkList.push('wed');
          }

          if (res.thurs) {
            _this.checkList.push('thurs');
          }

          if (res.fri) {
            _this.checkList.push('fri');
          }

          if (res.sat) {
            _this.checkList.push('sat');
          }

          if (res.sun) {
            _this.checkList.push('sun');
          }

          console.log(_this.checkList);
        }

        _this.loading = false;
      });
    },

    /** 保存运营周期设置 */
    onDateFormSubmit: function onDateFormSubmit() {
      var _this2 = this;

      console.log(this.checkList);
      var params = {
        lineId: this.rawData.lineId,
        mon: this.checkList.indexOf('mon') == -1 ? 0 : 1,
        tues: this.checkList.indexOf('tues') == -1 ? 0 : 1,
        wed: this.checkList.indexOf('wed') == -1 ? 0 : 1,
        thurs: this.checkList.indexOf('thurs') == -1 ? 0 : 1,
        fri: this.checkList.indexOf('fri') == -1 ? 0 : 1,
        sat: this.checkList.indexOf('sat') == -1 ? 0 : 1,
        sun: this.checkList.indexOf('sun') == -1 ? 0 : 1
      };

      if (this.id) {
        params.id = this.id;
      }

      console.log(params);

      _axios.default.post(this.parcelUrl + "/examination/lineTimeConfig/insertOrUpdate", params).then(function (response) {
        if (response.data.code == 200) {
          _this2.handleDialogClose();
        }

        _this2.loading = false;
      });
    }
  }
};
exports.default = _default2;