"use strict";

var _interopRequireDefault = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.listPassenger = listPassenger;
exports.getPassenger = getPassenger;
exports.addPassenger = addPassenger;
exports.updatePassenger = updatePassenger;
exports.delPassenger = delPassenger;
exports.exportPassenger = exportPassenger;
exports.exportSummary = exportSummary;
exports.orderList = orderList;
exports.friendList = friendList;
exports.financeExport = financeExport;
exports.analyse = analyse;
exports.analyseExport = analyseExport;

var _request = _interopRequireDefault(require("@/utils/request"));

// 查询乘客列表
function listPassenger(query) {
  return (0, _request.default)({
    url: "/system/passenger/list",
    method: "get",
    params: query
  });
} // 查询乘客详细


function getPassenger(id) {
  return (0, _request.default)({
    url: "/system/passenger/" + id,
    method: "get"
  });
} // 新增乘客


function addPassenger(data) {
  return (0, _request.default)({
    url: "/system/passenger",
    method: "post",
    data: data
  });
} // 修改乘客


function updatePassenger(data) {
  return (0, _request.default)({
    url: "/system/passenger",
    method: "put",
    data: data
  });
} // 删除乘客


function delPassenger(id) {
  return (0, _request.default)({
    url: "/system/passenger/" + id,
    method: "delete"
  });
} // 导出乘客


function exportPassenger(query) {
  return (0, _request.default)({
    url: "/system/passenger/export",
    method: "get",
    params: query
  });
} // 导出订单管理


function exportSummary(query) {
  return (0, _request.default)({
    url: "/order/order/exportSummary",
    method: "get",
    params: query
  });
} // 订单列表


function orderList(query) {
  return (0, _request.default)({
    url: "/system/passenger/orderList",
    method: "get",
    params: query
  });
}

function friendList(query) {
  return (0, _request.default)({
    url: "/system/passenger/friendList",
    method: "get",
    params: query
  });
} // 导出乘客


function financeExport(query) {
  return (0, _request.default)({
    url: "/system/finance/report/export",
    method: "get",
    params: query
  });
} // 乘客分析列表


function analyse(query) {
  return (0, _request.default)({
    url: "/system/passenger/analyse",
    method: "get",
    params: query
  });
} // 乘客分析列表导出


function analyseExport(query) {
  return (0, _request.default)({
    url: "/system/passenger/analyseExport",
    method: "get",
    params: query
  });
}