"use strict";

var _interopRequireDefault = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireDefault");

var _interopRequireWildcard = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var echarts = _interopRequireWildcard(require("echarts"));

var _resize = _interopRequireDefault(require("./mixins/resize"));

//
//
//
//
// require("echarts/theme/macarons"); // echarts theme
var _default = {
  mixins: [_resize.default],
  props: {
    className: {
      type: String,
      default: "chart"
    },
    width: {
      type: String,
      default: "100%"
    },
    height: {
      type: String,
      default: "400px"
    },
    chartData: {
      type: Array
    }
  },
  data: function data() {
    return {
      chart: null
    };
  },
  watch: {
    chartData: {
      deep: true,
      handler: function handler(val) {
        this.setOptions(val);
      }
    }
  },
  mounted: function mounted() {
    var _this = this;

    this.$nextTick(function () {
      _this.initChart();
    });
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }

    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart: function initChart() {
      this.chart = echarts.init(this.$el);
      this.chart.setOption({
        tooltip: {
          trigger: "item",
          formatter: "{b} : {d}%"
        },
        series: [{
          name: "Funnel",
          type: "funnel",
          right: "5",
          top: 20,
          bottom: 20,
          width: "65%",
          height: "65%",
          min: 0,
          max: 100,
          minSize: "0%",
          maxSize: "100%",
          sort: "descending",
          gap: 2,
          label: {
            show: true,
            position: "inside"
          },
          labelLine: {
            length: 10,
            lineStyle: {
              width: 1,
              type: "solid"
            }
          },
          itemStyle: {
            borderColor: "#fff",
            borderWidth: 1
          },
          emphasis: {
            label: {
              fontSize: 20
            }
          },
          data: []
        }]
      });
    },
    // 设置数据
    setOptions: function setOptions(val) {
      this.chart.setOption({
        series: [{
          data: this.chartData
        }]
      });
    }
  }
};
exports.default = _default;