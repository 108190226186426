"use strict";

var _interopRequireDefault = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.listConfig = listConfig;
exports.getConfig = getConfig;
exports.getConfigKey = getConfigKey;
exports.addConfig = addConfig;
exports.updateConfig = updateConfig;
exports.delConfig = delConfig;
exports.exportConfig = exportConfig;

var _request = _interopRequireDefault(require("@/utils/request"));

// 查询参数列表
function listConfig(query) {
  return (0, _request.default)({
    url: '/system/config/list',
    method: 'get',
    params: query
  });
} // 查询参数详细


function getConfig(configId) {
  return (0, _request.default)({
    url: '/system/config/' + configId,
    method: 'get'
  });
} // 根据参数键名查询参数值


function getConfigKey(configKey) {
  return (0, _request.default)({
    url: '/system/config/configKey/' + configKey,
    method: 'get'
  });
} // 新增参数配置


function addConfig(data) {
  return (0, _request.default)({
    url: '/system/config',
    method: 'post',
    data: data
  });
} // 修改参数配置


function updateConfig(data) {
  return (0, _request.default)({
    url: '/system/config',
    method: 'put',
    data: data
  });
} // 删除参数配置


function delConfig(configId) {
  return (0, _request.default)({
    url: '/system/config/' + configId,
    method: 'delete'
  });
} // 导出参数


function exportConfig(query) {
  return (0, _request.default)({
    url: '/system/config/export',
    method: 'get',
    params: query
  });
}