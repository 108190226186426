"use strict";

var _interopRequireDefault = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireDefault");

var _interopRequireWildcard = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var echarts = _interopRequireWildcard(require("echarts"));

var _resize = _interopRequireDefault(require("./mixins/resize"));

//
//
//
//
// require("echarts/theme/macarons"); // echarts theme
var _default = {
  mixins: [_resize.default],
  props: {
    className: {
      type: String,
      default: "chart"
    },
    width: {
      type: String,
      default: "100%"
    },
    height: {
      type: String,
      default: "500px"
    },
    chartData: {
      type: Array
    }
  },
  data: function data() {
    return {
      chart: null
    };
  },
  watch: {
    chartData: {
      deep: true,
      handler: function handler(val) {
        this.setOptions(val);
      }
    }
  },
  mounted: function mounted() {
    var _this = this;

    this.$nextTick(function () {
      _this.initChart();
    });
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }

    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart: function initChart() {
      this.chart = echarts.init(this.$el);
      this.chart.setOption({
        tooltip: {
          trigger: "item",
          formatter: '{b} : {c} ({d}%)'
        },
        series: [{
          type: "pie",
          radius: "50%",
          label: {
            color: "#fff"
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)"
            }
          }
        }]
      });
    },
    // 设置数据
    setOptions: function setOptions(val) {
      this.chart.setOption({
        series: [{
          data: this.chartData
        }]
      });
    }
  }
};
exports.default = _default;