var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "genInfoForm",
      attrs: { model: _vm.info, rules: _vm.rules, "label-width": "150px" }
    },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "tplCategory" } },
                [
                  _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                    _vm._v("生成模板")
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      model: {
                        value: _vm.info.tplCategory,
                        callback: function($$v) {
                          _vm.$set(_vm.info, "tplCategory", $$v)
                        },
                        expression: "info.tplCategory"
                      }
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "单表（增删改查）", value: "crud" }
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "树表（增删改查）", value: "tree" }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "packageName" } },
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _vm._v("\n          生成包路径\n          "),
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            content:
                              "生成在哪个java包下，例如 com.ruoyi.system",
                            placement: "top"
                          }
                        },
                        [_c("i", { staticClass: "el-icon-question" })]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-input", {
                    model: {
                      value: _vm.info.packageName,
                      callback: function($$v) {
                        _vm.$set(_vm.info, "packageName", $$v)
                      },
                      expression: "info.packageName"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "moduleName" } },
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _vm._v("\n          生成模块名\n          "),
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            content: "可理解为子系统名，例如 system",
                            placement: "top"
                          }
                        },
                        [_c("i", { staticClass: "el-icon-question" })]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-input", {
                    model: {
                      value: _vm.info.moduleName,
                      callback: function($$v) {
                        _vm.$set(_vm.info, "moduleName", $$v)
                      },
                      expression: "info.moduleName"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "businessName" } },
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _vm._v("\n          生成业务名\n          "),
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            content: "可理解为功能英文名，例如 user",
                            placement: "top"
                          }
                        },
                        [_c("i", { staticClass: "el-icon-question" })]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-input", {
                    model: {
                      value: _vm.info.businessName,
                      callback: function($$v) {
                        _vm.$set(_vm.info, "businessName", $$v)
                      },
                      expression: "info.businessName"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "functionName" } },
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _vm._v("\n          生成功能名\n          "),
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            content: "用作类描述，例如 用户",
                            placement: "top"
                          }
                        },
                        [_c("i", { staticClass: "el-icon-question" })]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-input", {
                    model: {
                      value: _vm.info.functionName,
                      callback: function($$v) {
                        _vm.$set(_vm.info, "functionName", $$v)
                      },
                      expression: "info.functionName"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.info.tplCategory == "tree",
              expression: "info.tplCategory == 'tree'"
            }
          ]
        },
        [
          _c("h4", { staticClass: "form-header" }, [_vm._v("其他信息")]),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _vm._v("\n          树编码字段\n          "),
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            content: "树显示的编码字段名， 如：dept_id",
                            placement: "top"
                          }
                        },
                        [_c("i", { staticClass: "el-icon-question" })]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.info.treeCode,
                        callback: function($$v) {
                          _vm.$set(_vm.info, "treeCode", $$v)
                        },
                        expression: "info.treeCode"
                      }
                    },
                    _vm._l(_vm.info.columns, function(column) {
                      return _c("el-option", {
                        key: column.columnName,
                        attrs: {
                          label:
                            column.columnName + "：" + column.columnComment,
                          value: column.columnName
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _vm._v("\n          树父编码字段\n          "),
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            content: "树显示的父编码字段名， 如：parent_Id",
                            placement: "top"
                          }
                        },
                        [_c("i", { staticClass: "el-icon-question" })]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.info.treeParentCode,
                        callback: function($$v) {
                          _vm.$set(_vm.info, "treeParentCode", $$v)
                        },
                        expression: "info.treeParentCode"
                      }
                    },
                    _vm._l(_vm.info.columns, function(column) {
                      return _c("el-option", {
                        key: column.columnName,
                        attrs: {
                          label:
                            column.columnName + "：" + column.columnComment,
                          value: column.columnName
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c(
                "el-form-item",
                [
                  _c(
                    "span",
                    { attrs: { slot: "label" }, slot: "label" },
                    [
                      _vm._v("\n          树名称字段\n          "),
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            content: "树节点的显示名称字段名， 如：dept_name",
                            placement: "top"
                          }
                        },
                        [_c("i", { staticClass: "el-icon-question" })]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.info.treeName,
                        callback: function($$v) {
                          _vm.$set(_vm.info, "treeName", $$v)
                        },
                        expression: "info.treeName"
                      }
                    },
                    _vm._l(_vm.info.columns, function(column) {
                      return _c("el-option", {
                        key: column.columnName,
                        attrs: {
                          label:
                            column.columnName + "：" + column.columnComment,
                          value: column.columnName
                        }
                      })
                    }),
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }