"use strict";

var _interopRequireDefault = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireDefault");

var _interopRequireWildcard = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var echarts = _interopRequireWildcard(require("echarts"));

var _resize = _interopRequireDefault(require("./mixins/resize"));

//
//
//
//
require("echarts/theme/macarons"); // echarts theme


var _default = {
  mixins: [_resize.default],
  props: {
    className: {
      type: String,
      default: "chart"
    },
    width: {
      type: String,
      default: "100%"
    },
    height: {
      type: String,
      default: "500px"
    },
    chartData: {
      type: Array
    }
  },
  data: function data() {
    return {
      chart: null
    };
  },
  watch: {
    chartData: {
      deep: true,
      handler: function handler(val) {
        this.setOptions(val);
      }
    }
  },
  mounted: function mounted() {
    var _this = this;

    this.$nextTick(function () {
      _this.initChart();
    });
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }

    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart: function initChart() {
      this.chart = echarts.init(this.$el);
      this.setOptions(); // this.chart.setOption({
      //   tooltip: {
      //     trigger: "axis",
      //     axisPointer: {
      //       // Use axis to trigger tooltip
      //       type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
      //     },
      //   },
      //   legend: {
      //     textStyle: {
      //       color: "#fff",
      //     },
      //   },
      //   grid: {
      //     top: '15%',
      //     left: '1%',
      //     right: '10%',
      //     containLabel: true,
      //   },
      //   xAxis: {
      //     type: "value",
      //     axisLabel: {
      //       color: "#fff",
      //       show: false
      //     },
      //   },
      //   yAxis: {
      //     type: "category",
      //     axisLabel: {
      //       color: "#fff",
      //     },
      //   },
      //   dataZoom: [
      //     {
      //       type: "inside",
      //       start: 0,
      //       end: 100,
      //       zoomLock: true,
      //     },
      //     {
      //       type: "slider",
      //       show: true,
      //       yAxisIndex: 0,
      //       filterMode: "empty",
      //       width: 30,
      //       handleSize: 8,
      //       showDataShadow: false,
      //       left: "93%",
      //     },
      //   ],
      //   series: []
      // });
    },
    // 设置数据
    setOptions: function setOptions(val) {
      if (!this.chart) {
        return;
      }

      this.chart.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // Use axis to trigger tooltip
            type: "shadow" // 'shadow' as default; can also be 'line' or 'shadow'

          }
        },
        legend: {
          textStyle: {
            color: "#fff"
          }
        },
        grid: {
          top: '15%',
          left: '1%',
          right: '10%',
          containLabel: true
        },
        xAxis: {
          type: "value",
          axisLabel: {
            color: "#fff",
            show: false
          }
        },
        yAxis: {
          type: "category",
          axisLabel: {
            color: "#fff"
          }
        },
        dataZoom: [{
          type: "inside",
          start: 0,
          end: 100,
          zoomLock: true
        }, {
          type: "slider",
          show: true,
          yAxisIndex: 0,
          filterMode: "empty",
          width: 30,
          handleSize: 8,
          showDataShadow: false,
          left: "93%"
        }],
        series: this.chartData
      }, true);
    }
  }
};
exports.default = _default;