"use strict";

var _interopRequireDefault = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _logo = _interopRequireDefault(require("@/assets/logo/logo.png"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data: function data() {
    return {
      title: '后台管理系统',
      logo: _logo.default
    };
  }
};
exports.default = _default;