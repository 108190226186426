"use strict";

var _interopRequireDefault = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.listTable = listTable;
exports.listDbTable = listDbTable;
exports.getGenTable = getGenTable;
exports.updateGenTable = updateGenTable;
exports.importTable = importTable;
exports.previewTable = previewTable;
exports.delTable = delTable;

var _request = _interopRequireDefault(require("@/utils/request"));

// 查询生成表数据
function listTable(query) {
  return (0, _request.default)({
    url: '/tool/gen/list',
    method: 'get',
    params: query
  });
} // 查询db数据库列表


function listDbTable(query) {
  return (0, _request.default)({
    url: '/tool/gen/db/list',
    method: 'get',
    params: query
  });
} // 查询表详细信息


function getGenTable(tableId) {
  return (0, _request.default)({
    url: '/tool/gen/' + tableId,
    method: 'get'
  });
} // 修改代码生成信息


function updateGenTable(data) {
  return (0, _request.default)({
    url: '/tool/gen',
    method: 'put',
    data: data
  });
} // 导入表


function importTable(data) {
  return (0, _request.default)({
    url: '/tool/gen/importTable',
    method: 'post',
    params: data
  });
} // 预览生成代码


function previewTable(tableId) {
  return (0, _request.default)({
    url: '/tool/gen/preview/' + tableId,
    method: 'get'
  });
} // 删除表数据


function delTable(tableId) {
  return (0, _request.default)({
    url: '/tool/gen/' + tableId,
    method: 'delete'
  });
}