"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _data = require("@/api/system/dict/data");

var _type = require("@/api/system/dict/type");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "Data",
  data: function data() {
    return {
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      // 字典表格数据
      dataList: [],
      // 默认字典类型
      defaultDictType: "",
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 状态数据字典
      statusOptions: [],
      // 类型数据字典
      typeOptions: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        dictName: undefined,
        dictType: undefined,
        status: undefined
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {
        dictLabel: [{
          required: true,
          message: "数据标签不能为空",
          trigger: "blur"
        }],
        dictValue: [{
          required: true,
          message: "数据键值不能为空",
          trigger: "blur"
        }],
        dictSort: [{
          required: true,
          message: "数据顺序不能为空",
          trigger: "blur"
        }]
      }
    };
  },
  created: function created() {
    var _this = this;

    var dictId = this.$route.params && this.$route.params.dictId;
    this.getType(dictId);
    this.getTypeList();
    this.getDicts("sys_normal_disable").then(function (response) {
      _this.statusOptions = response.data;
    });
  },
  methods: {
    /** 查询字典类型详细 */
    getType: function getType(dictId) {
      var _this2 = this;

      (0, _type.getType)(dictId).then(function (response) {
        _this2.queryParams.dictType = response.data.dictType;
        _this2.defaultDictType = response.data.dictType;

        _this2.getList();
      });
    },

    /** 查询字典类型列表 */
    getTypeList: function getTypeList() {
      var _this3 = this;

      (0, _type.listType)().then(function (response) {
        _this3.typeOptions = response.rows;
      });
    },

    /** 查询字典数据列表 */
    getList: function getList() {
      var _this4 = this;

      this.loading = true;
      (0, _data.listData)(this.queryParams).then(function (response) {
        _this4.dataList = response.rows;
        _this4.total = response.total;
        _this4.loading = false;
      });
    },
    // 数据状态字典翻译
    statusFormat: function statusFormat(row, column) {
      return this.selectDictLabel(this.statusOptions, row.status);
    },
    // 取消按钮
    cancel: function cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset: function reset() {
      this.form = {
        dictCode: undefined,
        dictLabel: undefined,
        dictValue: undefined,
        dictSort: 0,
        status: "0",
        remark: undefined
      };
      this.resetForm("form");
    },

    /** 搜索按钮操作 */
    handleQuery: function handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },

    /** 重置按钮操作 */
    resetQuery: function resetQuery() {
      this.resetForm("queryForm");
      this.queryParams.dictType = this.defaultDictType;
      this.handleQuery();
    },

    /** 新增按钮操作 */
    handleAdd: function handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加字典数据";
      this.form.dictType = this.queryParams.dictType;
    },
    // 多选框选中数据
    handleSelectionChange: function handleSelectionChange(selection) {
      this.ids = selection.map(function (item) {
        return item.dictCode;
      });
      this.single = selection.length != 1;
      this.multiple = !selection.length;
    },

    /** 修改按钮操作 */
    handleUpdate: function handleUpdate(row) {
      var _this5 = this;

      this.reset();
      var dictCode = row.dictCode || this.ids;
      (0, _data.getData)(dictCode).then(function (response) {
        _this5.form = response.data;
        _this5.open = true;
        _this5.title = "修改字典数据";
      });
    },

    /** 提交按钮 */
    submitForm: function submitForm() {
      var _this6 = this;

      this.$refs["form"].validate(function (valid) {
        if (valid) {
          if (_this6.form.dictCode != undefined) {
            (0, _data.updateData)(_this6.form).then(function (response) {
              if (response.code === 200) {
                _this6.msgSuccess("修改成功");

                _this6.open = false;

                _this6.getList();
              } else {
                _this6.msgError(response.msg);
              }
            });
          } else {
            (0, _data.addData)(_this6.form).then(function (response) {
              if (response.code === 200) {
                _this6.msgSuccess("新增成功");

                _this6.open = false;

                _this6.getList();
              } else {
                _this6.msgError(response.msg);
              }
            });
          }
        }
      });
    },

    /** 删除按钮操作 */
    handleDelete: function handleDelete(row) {
      var _this7 = this;

      var dictCodes = row.dictCode || this.ids;
      this.$confirm('是否确认删除字典编码为"' + dictCodes + '"的数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return (0, _data.delData)(dictCodes);
      }).then(function () {
        _this7.getList();

        _this7.msgSuccess("删除成功");
      }).catch(function () {});
    },

    /** 导出按钮操作 */
    handleExport: function handleExport() {
      var _this8 = this;

      var queryParams = this.queryParams;
      this.$confirm('是否确认导出所有数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return (0, _data.exportData)(queryParams);
      }).then(function (response) {
        _this8.download(response.msg);
      }).catch(function () {});
    }
  }
};
exports.default = _default;