"use strict";

var _interopRequireDefault = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.regexp.to-string");

require("core-js/modules/es6.regexp.split");

var _scheduleItem = _interopRequireDefault(require("./components/scheduleItem.vue"));

var _carpool = require("@/api/business/carpool");

var _utils = require("@/utils");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  created: function created() {
    this.getLists();
  },
  props: {
    openScheduleModuleDialog: {
      type: Boolean,
      default: false
    },
    operationData: {
      type: Object,
      required: true
    }
  },
  // components: {
  //   scheduleItem
  // },
  methods: {
    getLists: function getLists() {
      var _this = this;

      (0, _carpool.carpoolScheduleDeputyLists)({
        lineId: this.operationData.id
      }).then(function (res) {
        console.log(res, "=====");
        res.rows.map(function (item) {
          // if (isString(item)) {
          item.serviceType = item.serviceType.split(","); // }
        });
        _this.formDialog.dataLists = res.rows;
      });
    },
    removeItem: function removeItem(item) {
      this.formDialog.dataLists.splice(item, 1);
    },
    addDomain: function addDomain() {
      this.formDialog.dataLists.push({
        startTime: "",
        endTime: "",
        lineId: this.operationData.id,
        lineName: this.operationData.lineName,
        scheduleType: 0,
        serviceType: [],
        status: 1
      });
    },
    handleSubmit: function handleSubmit() {
      var _this2 = this;

      console.log(this.formDialog);
      this.$refs["formDialog"].validate(function (valid) {
        if (valid) {
          _this2.loading = true;

          _this2.formDialog.dataLists.map(function (item) {
            console.log(item, (0, _utils.isArray)(item), Object.prototype.toString.call(item), "itemeeeee");

            if ((0, _utils.isArray)(item.serviceType)) {
              item.serviceType = item.serviceType.join(",");
            }
          });

          (0, _carpool.addCarpoolScheduleDeputyS)(_this2.formDialog.dataLists).then(function (res) {
            if (res.code === 200) {
              _this2.$message.success(res.msg);
            } else {
              _this2.$message.error(res.msg);
            }

            _this2.loading = false;

            _this2.$emit("closepop");
          });
        }
      });
    },
    handleDialogClose: function handleDialogClose() {
      this.$emit("closepop");
    },
    reload: function reload(obj) {
      console.log(obj, "obj");
      this.formDialog = obj;
    }
  },
  data: function data() {
    return {
      loading: false,
      formDialog: {
        dataLists: [{
          startTime: "",
          endTime: "",
          lineId: this.operationData.id,
          lineName: this.operationData.lineName,
          scheduleType: 0,
          serviceType: [],
          status: 1
        }]
      }
    };
  }
};
exports.default = _default;