var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {
    class: _vm.className,
    style: {
      height: _vm.height,
      width: _vm.width,
      background: _vm.background,
      padding: _vm.padding
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }