"use strict";

var _interopRequireDefault = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireDefault");

var _interopRequireWildcard = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var echarts = _interopRequireWildcard(require("echarts"));

var _resize = _interopRequireDefault(require("./mixins/resize"));

//
//
//
//
//
//
//
//
//
//
//
//
require("echarts/theme/macarons"); // echarts theme


var _default = {
  mixins: [_resize.default],
  props: {
    className: {
      type: String,
      default: "chart"
    },
    width: {
      type: String,
      default: "100%"
    },
    height: {
      type: String,
      default: "380px"
    },
    padding: {
      type: String,
      default: "15px 0 15px 15px"
    },
    background: {
      type: String,
      default: "#fff"
    },
    autoResize: {
      type: Boolean,
      default: true
    },
    chartData: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      chart: null
    };
  },
  watch: {
    chartData: {
      deep: true,
      handler: function handler(val) {
        this.setOptions(val);
      }
    }
  },
  mounted: function mounted() {
    var _this = this;

    this.$nextTick(function () {
      _this.initChart();
    });
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }

    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart: function initChart() {
      this.chart = echarts.init(this.$el);
      this.setOptions();
    },
    setOptions: function setOptions() {
      this.chart.setOption(this.chartData); // this.chart.setOption(JSON.parse(data));
    }
  }
};
exports.default = _default;