"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _carpool = require("@/api/business/carpool");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    openDialogCancelConfig: {
      type: Boolean,
      default: false
    },
    operationData: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      generalForm: {
        carpoolLineConfigCJBC: {},
        carpoolLineConfigCJPC: {}
      },
      form: {
        lineName: "",
        serviceType: []
      },
      rules: {
        serviceType: [{
          required: true,
          message: "请选择线路类型",
          trigger: "blur"
        }]
      },
      activeName: "城际拼车"
    };
  },
  created: function created() {
    this.getConfig();
  },
  methods: {
    getConfig: function getConfig() {
      var _this = this;

      (0, _carpool.lineConfigList)({
        lineId: this.operationData.id
      }).then(function (res) {
        if (res.code === 200) {
          _this.generalForm = res.data;

          if (_this.generalForm.carpoolLineConfigCJPC) {
            _this.activeName = "城际拼车";
          }

          if (!_this.generalForm.carpoolLineConfigCJPC && _this.generalForm.carpoolLineConfigCJBC) {
            _this.activeName = "城际包车";
          }

          if (!_this.generalForm.carpoolLineConfigCJPC && !_this.generalForm.carpoolLineConfigCJBC) {
            _this.$message.error("服务类型数据返回有误");

            _this.activeName = "";
          }
        } else {
          _this.$message.error(res.msg);
        }
      });
    },
    handleDialogClose: function handleDialogClose() {
      this.$emit("closepop"); //通知父组件改变。
    },
    onSubmit: function onSubmit() {
      var _this2 = this;

      (0, _carpool.editLineConfigList)(this.generalForm).then(function (res) {
        console.log(res, "----");

        if (res.code === 200) {
          _this2.$message.success(res.msg);
        } else {
          _this2.$message.error(res.msg);
        }

        _this2.handleDialogClose();
      });
    }
  }
};
exports.default = _default;