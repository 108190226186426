"use strict";

var _interopRequireDefault = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.listBusCityConfig = listBusCityConfig;
exports.getBusCityConfig = getBusCityConfig;
exports.addBusCityConfig = addBusCityConfig;
exports.updateBusCityConfig = updateBusCityConfig;
exports.delBusCityConfig = delBusCityConfig;
exports.exportBusCityConfig = exportBusCityConfig;
exports.deptList = deptList;

var _request = _interopRequireDefault(require("@/utils/request"));

// 查询班车业务不同公司城市配置列表
function listBusCityConfig(query) {
  return (0, _request.default)({
    url: '/system/busCityConfig/list',
    method: 'get',
    params: query
  });
} // 查询班车业务不同公司城市配置详细


function getBusCityConfig(id) {
  return (0, _request.default)({
    url: '/system/busCityConfig/' + id,
    method: 'get'
  });
} // 新增班车业务不同公司城市配置


function addBusCityConfig(data) {
  return (0, _request.default)({
    url: '/system/busCityConfig',
    method: 'post',
    data: data
  });
} // 修改班车业务不同公司城市配置


function updateBusCityConfig(data) {
  return (0, _request.default)({
    url: '/system/busCityConfig',
    method: 'put',
    data: data
  });
} // 删除班车业务不同公司城市配置


function delBusCityConfig(id) {
  return (0, _request.default)({
    url: '/system/busCityConfig/' + id,
    method: 'delete'
  });
} // 导出班车业务不同公司城市配置


function exportBusCityConfig(query) {
  return (0, _request.default)({
    url: '/system/busCityConfig/export',
    method: 'get',
    params: query
  });
} // 获取所有公司


function deptList() {
  return (0, _request.default)({
    url: '/system/dept/accessibleDepts',
    method: 'get'
  });
}