"use strict";

var _interopRequireDefault = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.has = has;
exports.formatDate = formatDate;
exports.perfectTransform = perfectTransform;
exports.formatTime = formatTime;
exports.getQueryObject = getQueryObject;
exports.byteLength = byteLength;
exports.cleanArray = cleanArray;
exports.param = param;
exports.param2Obj = param2Obj;
exports.html2Text = html2Text;
exports.objectMerge = objectMerge;
exports.toggleClass = toggleClass;
exports.getTime = getTime;
exports.debounce = debounce;
exports.isArray = isArray;
exports.isString = isString;
exports.isNumber = isNumber;
exports.objDeepCopy = objDeepCopy;
exports.equalsObj = equalsObj;
exports.getMonthDay = getMonthDay;
exports.formateArrData = formateArrData;
exports.deepClone = deepClone;
exports.uniqueArr = uniqueArr;
exports.createUniqueString = createUniqueString;
exports.hasClass = hasClass;
exports.addClass = addClass;
exports.removeClass = removeClass;
exports.makeMap = makeMap;
exports.titleCase = titleCase;
exports.camelCase = camelCase;
exports.isNumberStr = isNumberStr;
exports.getCurrentDay = getCurrentDay;
exports.beautifierConf = exports.exportDefault = void 0;

require("core-js/modules/es6.regexp.constructor");

require("core-js/modules/es6.regexp.match");

require("core-js/modules/es6.set");

require("core-js/modules/es6.string.iterator");

require("core-js/modules/es6.array.from");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/es6.number.constructor");

require("core-js/modules/es6.regexp.to-string");

var _typeof2 = _interopRequireDefault(require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime-corejs2/helpers/typeof"));

require("core-js/modules/es6.regexp.split");

function has(obj, key) {
  return key.split(".").every(function (x) {
    if ((0, _typeof2.default)(obj) != "object" || obj === null || !x in obj) return false;
    obj = obj[x];
    return true;
  });
}

function formatDate(cellValue) {
  if (cellValue == null || cellValue == "") return "";
  var date = new Date(cellValue);
  var year = date.getFullYear();
  var month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
  var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  var hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
  var minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
  var seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
  return year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
}

function perfectTransform(arg1, arg2) {
  if (!arg1 || !arg2) {
    return 0;
  }

  var m = 0,
      s1 = arg1.toString(),
      s2 = arg2.toString();

  try {
    m += s1.split(".")[1].length;
  } catch (e) {}

  try {
    m += s2.split(".")[1].length;
  } catch (e) {}

  return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m);
}
/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */


function formatTime(time, option) {
  if (("" + time).length === 10) {
    time = parseInt(time) * 1000;
  } else {
    time = +time;
  }

  var d = new Date(time);
  var now = Date.now();
  var diff = (now - d) / 1000;

  if (diff < 30) {
    return "刚刚";
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + "分钟前";
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + "小时前";
  } else if (diff < 3600 * 24 * 2) {
    return "1天前";
  }

  if (option) {
    return parseTime(time, option);
  } else {
    return d.getMonth() + 1 + "月" + d.getDate() + "日" + d.getHours() + "时" + d.getMinutes() + "分";
  }
}
/**
 * @param {string} url
 * @returns {Object}
 */


function getQueryObject(url) {
  url = url == null ? window.location.href : url;
  var search = url.substring(url.lastIndexOf("?") + 1);
  var obj = {};
  var reg = /([^?&=]+)=([^?&=]*)/g;
  search.replace(reg, function (rs, $1, $2) {
    var name = decodeURIComponent($1);
    var val = decodeURIComponent($2);
    val = String(val);
    obj[name] = val;
    return rs;
  });
  return obj;
}
/**
 * @param {string} input value
 * @returns {number} output value
 */


function byteLength(str) {
  // returns the byte length of an utf8 string
  var s = str.length;

  for (var i = str.length - 1; i >= 0; i--) {
    var code = str.charCodeAt(i);
    if (code > 0x7f && code <= 0x7ff) s++;else if (code > 0x7ff && code <= 0xffff) s += 2;
    if (code >= 0xdc00 && code <= 0xdfff) i--;
  }

  return s;
}
/**
 * @param {Array} actual
 * @returns {Array}
 */


function cleanArray(actual) {
  var newArray = [];

  for (var i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i]);
    }
  }

  return newArray;
}
/**
 * @param {Object} json
 * @returns {Array}
 */


function param(json) {
  if (!json) return "";
  return cleanArray(Object.keys(json).map(function (key) {
    if (json[key] === undefined) return "";
    return encodeURIComponent(key) + "=" + encodeURIComponent(json[key]);
  })).join("&");
}
/**
 * @param {string} url
 * @returns {Object}
 */


function param2Obj(url) {
  var search = url.split("?")[1];

  if (!search) {
    return {};
  }

  return JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"').replace(/\+/g, " ") + '"}');
}
/**
 * @param {string} val
 * @returns {string}
 */


function html2Text(val) {
  var div = document.createElement("div");
  div.innerHTML = val;
  return div.textContent || div.innerText;
}
/**
 * Merges two objects, giving the last one precedence
 * @param {Object} target
 * @param {(Object|Array)} source
 * @returns {Object}
 */


function objectMerge(target, source) {
  if ((0, _typeof2.default)(target) !== "object") {
    target = {};
  }

  if (Array.isArray(source)) {
    return source.slice();
  }

  Object.keys(source).forEach(function (property) {
    var sourceProperty = source[property];

    if ((0, _typeof2.default)(sourceProperty) === "object") {
      target[property] = objectMerge(target[property], sourceProperty);
    } else {
      target[property] = sourceProperty;
    }
  });
  return target;
}
/**
 * @param {HTMLElement} element
 * @param {string} className
 */


function toggleClass(element, className) {
  if (!element || !className) {
    return;
  }

  var classString = element.className;
  var nameIndex = classString.indexOf(className);

  if (nameIndex === -1) {
    classString += "" + className;
  } else {
    classString = classString.substr(0, nameIndex) + classString.substr(nameIndex + className.length);
  }

  element.className = classString;
}
/**
 * @param {string} type
 * @returns {Date}
 */


function getTime(type) {
  if (type === "start") {
    return new Date().getTime() - 3600 * 1000 * 24 * 90;
  } else {
    return new Date(new Date().toDateString());
  }
}
/**
 * @param {Function} func
 * @param {number} wait
 * @param {boolean} immediate
 * @return {*}
 */


function debounce(func, wait, immediate) {
  var timeout, args, context, timestamp, result;

  var later = function later() {
    // 据上一次触发时间间隔
    var last = +new Date() - timestamp; // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait

    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last);
    } else {
      timeout = null; // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用

      if (!immediate) {
        result = func.apply(context, args);
        if (!timeout) context = args = null;
      }
    }
  };

  return function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    context = this;
    timestamp = +new Date();
    var callNow = immediate && !timeout; // 如果延时不存在，重新设定延时

    if (!timeout) timeout = setTimeout(later, wait);

    if (callNow) {
      result = func.apply(context, args);
      context = args = null;
    }

    return result;
  };
}
/**
 * 判断此对象是否是Object类型
 * @param {Object} obj
 */


function isObject(obj) {
  return Object.prototype.toString.call(obj) === "[object Object]";
}
/**
 * 判断此类型是否是Array类型
 * @param {Array} arr
 */


function isArray(arr) {
  return Object.prototype.toString.call(arr) === "[object Array]";
}

function isString(arr) {
  return Object.prototype.toString.call(arr) === "[object String]";
}

function isNumber(arr) {
  return Object.prototype.toString.call(arr) === "[object Number]";
} // 数组对象深拷贝


function objDeepCopy(source) {
  var sourceCopy = JSON.stringify(source);
  return JSON.parse(sourceCopy);
}
/**
 *  深度比较两个对象是否相同
 * @param {Object} oldData
 * @param {Object} newData
 */


function equalsObj(oldData, newData) {
  // 类型为基本类型时,如果相同,则返回true
  if (oldData === newData) return true;

  if (isObject(oldData) && isObject(newData) && Object.keys(oldData).length === Object.keys(newData).length) {
    // 类型为对象并且元素个数相同
    // 遍历所有对象中所有属性,判断元素是否相同
    for (var key in oldData) {
      if (oldData.hasOwnProperty(key)) {
        if (!equalsObj(oldData[key], newData[key])) // 对象中具有不相同属性 返回false
          return false;
      }
    }
  } else if (isArray(oldData) && isArray(newData) && oldData.length === newData.length) {
    // 类型为数组并且数组长度相同
    for (var i = 0, length = oldData.length; i < length; i++) {
      if (!equalsObj(oldData[i], newData[i])) // 如果数组元素中具有不相同元素,返回false
        return false;
    }
  } else {
    // 其它类型,均返回false
    return false;
  } // 走到这里,说明数组或者对象中所有元素都相同,返回true


  return true;
} // 获取某个月的最后一天


function getMonthDay(year, month) {
  var days = new Date(year, month, 0).getDate();
  return days;
}

function formateArrData(initialArr, name, newArr) {
  // newArr是承接分类的新数组，整个initialArr处理完成之后，会根据不同的name生成一个二维数组
  // 判定传参是否符合规则
  if (!(initialArr instanceof Array) || !(newArr instanceof Array)) {
    return '请传入正确格式的数组';
  }

  if (!name) {
    return '请传入对象属性';
  } // 每一个类型的单独数组，注意此处不能return出每个alikeArr，
  // 因为递归的返回值只返回最后一次的值


  var alikeArr = [];
  var propertyName = '';

  if (initialArr.length > 0) {
    propertyName = initialArr[0]["".concat(name)];
    var tempArr = []; // 将拥有共同propertyName属性的对象放到此次遍历的alikeArr中，
    // 将其他的对象放入到tempArr中，等待下次遍历

    initialArr.forEach(function (val, key) {
      if (val["".concat(name)] === propertyName) {
        alikeArr.push(val);
      } else {
        tempArr.push(val);
      }
    });
    newArr.push(alikeArr);
    initialArr = tempArr;
    return formateArrData(initialArr, name, newArr);
  } else {
    return newArr;
  }
}
/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 * @param {Object} source
 * @returns {Object}
 */


function deepClone(source) {
  if (!source && (0, _typeof2.default)(source) !== "object") {
    throw new Error("error arguments", "deepClone");
  }

  var targetObj = source.constructor === Array ? [] : {};
  Object.keys(source).forEach(function (keys) {
    if (source[keys] && (0, _typeof2.default)(source[keys]) === "object") {
      targetObj[keys] = deepClone(source[keys]);
    } else {
      targetObj[keys] = source[keys];
    }
  });
  return targetObj;
}
/**
 * @param {Array} arr
 * @returns {Array}
 */


function uniqueArr(arr) {
  return Array.from(new Set(arr));
}
/**
 * @returns {string}
 */


function createUniqueString() {
  var timestamp = +new Date() + "";
  var randomNum = parseInt((1 + Math.random()) * 65536) + "";
  return (+(randomNum + timestamp)).toString(32);
}
/**
 * Check if an element has a class
 * @param {HTMLElement} elm
 * @param {string} cls
 * @returns {boolean}
 */


function hasClass(ele, cls) {
  return !!ele.className.match(new RegExp("(\\s|^)" + cls + "(\\s|$)"));
}
/**
 * Add class to element
 * @param {HTMLElement} elm
 * @param {string} cls
 */


function addClass(ele, cls) {
  if (!hasClass(ele, cls)) ele.className += " " + cls;
}
/**
 * Remove class from element
 * @param {HTMLElement} elm
 * @param {string} cls
 */


function removeClass(ele, cls) {
  if (hasClass(ele, cls)) {
    var reg = new RegExp("(\\s|^)" + cls + "(\\s|$)");
    ele.className = ele.className.replace(reg, " ");
  }
}

function makeMap(str, expectsLowerCase) {
  var map = Object.create(null);
  var list = str.split(",");

  for (var i = 0; i < list.length; i++) {
    map[list[i]] = true;
  }

  return expectsLowerCase ? function (val) {
    return map[val.toLowerCase()];
  } : function (val) {
    return map[val];
  };
}

var exportDefault = "export default ";
exports.exportDefault = exportDefault;
var beautifierConf = {
  html: {
    indent_size: "2",
    indent_char: " ",
    max_preserve_newlines: "-1",
    preserve_newlines: false,
    keep_array_indentation: false,
    break_chained_methods: false,
    indent_scripts: "separate",
    brace_style: "end-expand",
    space_before_conditional: true,
    unescape_strings: false,
    jslint_happy: false,
    end_with_newline: true,
    wrap_line_length: "110",
    indent_inner_html: true,
    comma_first: false,
    e4x: true,
    indent_empty_lines: true
  },
  js: {
    indent_size: "2",
    indent_char: " ",
    max_preserve_newlines: "-1",
    preserve_newlines: false,
    keep_array_indentation: false,
    break_chained_methods: false,
    indent_scripts: "normal",
    brace_style: "end-expand",
    space_before_conditional: true,
    unescape_strings: false,
    jslint_happy: true,
    end_with_newline: true,
    wrap_line_length: "110",
    indent_inner_html: true,
    comma_first: false,
    e4x: true,
    indent_empty_lines: true
  }
}; // 首字母大小

exports.beautifierConf = beautifierConf;

function titleCase(str) {
  return str.replace(/( |^)[a-z]/g, function (L) {
    return L.toUpperCase();
  });
} // 下划转驼峰


function camelCase(str) {
  return str.replace(/-[a-z]/g, function (str1) {
    return str1.substr(-1).toUpperCase();
  });
}

function isNumberStr(str) {
  return /^[+-]?(0|([1-9]\d*))(\.\d+)?$/g.test(str);
}

function getCurrentDay() {
  var date = new Date();
  var year = date.getFullYear(); //  返回的是年份

  var month = date.getMonth() + 1; //  返回的月份上个月的月份，记得+1才是当月

  var day = date.getDate(); //  返回的是几号

  return year + '-' + month + '-' + day;
}