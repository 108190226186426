var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container carpoolTripDetail" },
    [
      _c(
        "el-row",
        [
          _c("el-col", { staticClass: "pr20", attrs: { span: 14 } }, [
            _c("div", { staticClass: "title" }, [_vm._v("订单信息")]),
            _vm._v(" "),
            _c("table", { staticClass: "infoTable mb40" }, [
              _c("tr", [
                _c("td", { staticClass: "tr bold", attrs: { width: "10%" } }, [
                  _vm._v("行程编号")
                ]),
                _vm._v(" "),
                _c("td", { attrs: { width: "30%" } }, [
                  _vm._v(_vm._s(_vm.orderDetail.carpoolTravelInfo.id))
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "tr bold", attrs: { width: "10%" } }, [
                  _vm._v("行程状态")
                ]),
                _vm._v(" "),
                _c("td", { attrs: { width: "30%" } }, [
                  _vm._v(_vm._s(_vm.orderDetail.carpoolTravelInfo.statusText))
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", { staticClass: "tr bold" }, [_vm._v("行程金额")]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "\n            总：" +
                      _vm._s(_vm.orderDetail.carpoolTravelInfo.allMoney) +
                      " 实付：" +
                      _vm._s(_vm.orderDetail.carpoolTravelInfo.allMoney) +
                      " 退款：" +
                      _vm._s(
                        _vm.orderDetail.carpoolTravelInfo.refundMoney
                          ? _vm.orderDetail.carpoolTravelInfo.refundMoney
                          : 0
                      ) +
                      "\n          "
                  )
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "tr bold" }, [_vm._v("司机")]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.orderDetail.carpoolTravelInfo.driverPhone +
                          _vm.tripItem.driverRealName
                      ) +
                      "\n          "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", { staticClass: "tr bold" }, [_vm._v("服务类型")]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(_vm._s(_vm.orderDetail.carpoolTravelInfo.serviceType))
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "tr bold" }, [_vm._v("车型")]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.orderDetail.carpoolTravelInfo.carNo) +
                      "/" +
                      _vm._s(_vm.orderDetail.carpoolTravelInfo.carNote) +
                      "\n          "
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "title" }, [_vm._v("行程追踪")]),
            _vm._v(" "),
            _c("div", { staticClass: "clearfix" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "cardBox" },
              _vm._l(_vm.orderDetail.carpoolTravelRecord, function(item) {
                return _c("div", { key: item.id, staticClass: "cardItem" }, [
                  _c("div", { staticClass: "cardItemTop" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.parseTime(item.created)) +
                        "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "cardItemCont" }, [
                    _c("div", { staticClass: "infoItem" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(item.travelMessage) +
                          "\n            "
                      )
                    ])
                  ])
                ])
              }),
              0
            )
          ]),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 10 } },
            [
              _c(
                "div",
                { staticClass: "title", staticStyle: { "margin-top": "52px" } },
                [_vm._v("订单路线")]
              ),
              _vm._v(" "),
              _vm.showMap
                ? _c("mapLineComponents", {
                    ref: "mapLineComponents",
                    staticClass: "mb40",
                    attrs: {
                      status: _vm.tripItem.status === 3,
                      orderDetail: _vm.orderDetail
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "title" }, [_vm._v("同行订单")]),
              _vm._v(" "),
              _c(
                "el-tabs",
                {
                  staticClass: "carpoolTabs",
                  attrs: { "tab-position": "left" }
                },
                _vm._l(_vm.orderDetail.carpoolOrderSameList, function(item) {
                  return _c(
                    "el-tab-pane",
                    { key: "carpoolOrderSameList" + item.id },
                    [
                      _c("span", { attrs: { slot: "label" }, slot: "label" }, [
                        _c("img", {
                          staticClass: "avatar",
                          attrs: { src: item.avatar }
                        })
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "tabContent" }, [
                        _c("p", [
                          _vm._v("\n              订单号："),
                          _c("span", { staticClass: "corBlue" }, [
                            _vm._v(_vm._s(item.orderNo))
                          ]),
                          _vm._v(
                            "（" +
                              _vm._s(item.personNum) +
                              "人拼车）\n            "
                          )
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v("订单状态：" + _vm._s(item.orderStatusText))
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            "乘车人：" + _vm._s(_vm.phoneFormat(item.userPhone))
                          )
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            "\n              出发地：" +
                              _vm._s(item.startProvince) +
                              _vm._s(item.startDetailsAddress) +
                              "\n            "
                          )
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            "目的地：" +
                              _vm._s(item.endProvince) +
                              _vm._s(item.endDetailsAddress)
                          )
                        ]),
                        _vm._v(" "),
                        _c("p", [
                          _vm._v(
                            "用车时间：" +
                              _vm._s(item.scheduleDay) +
                              " " +
                              _vm._s(item.startTime)
                          )
                        ]),
                        _vm._v(" "),
                        _c("p", [_vm._v("订单金额(元)：" + _vm._s(item.money))])
                      ])
                    ]
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }