"use strict";

var _interopRequireDefault = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime-corejs2/helpers/objectSpread2"));

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

require("core-js/modules/es6.regexp.split");

var _carpool = require("@/api/business/carpool");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    openServiceDialog: {
      type: Boolean,
      default: false
    },
    operationData: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      lineForm: {
        serviceType: []
      },
      rules: {
        lineName: [{
          required: true,
          message: "请输入线路名称",
          trigger: "blur"
        }],
        serviceType: [{
          required: true,
          message: "请选择线路类型",
          trigger: "blur"
        }]
      }
    };
  },
  created: function created() {
    this.getConfig();
  },
  methods: {
    getConfig: function getConfig() {
      var _this = this;

      (0, _carpool.getCarpoolLine)(this.operationData.id).then(function (response) {
        _this.lineForm = response.data;
        _this.lineForm.serviceType = response.data.serviceType.split(",");
      });
    },
    handleDialogClose: function handleDialogClose() {
      this.$emit("closepop"); //通知父组件改变。
    },
    onSubmit: function onSubmit() {
      var _this2 = this;

      this.$refs["lineForm"].validate(function (valid) {
        if (valid) {
          if (_this2.lineForm.serviceType.includes("城际拼车") && _this2.lineForm.serviceType.includes("城际包车")) {
            _this2.lineForm.serviceType = "城际拼车,城际包车";
            _this2.lineForm.serviceTypeCode = 2;
          } else if (_this2.lineForm.serviceType.includes("城际拼车")) {
            _this2.lineForm.serviceType = "城际拼车";
            _this2.lineForm.serviceTypeCode = 0;
          } else if (_this2.lineForm.serviceType.includes("城际包车")) {
            _this2.lineForm.serviceType = "城际包车";
            _this2.lineForm.serviceTypeCode = 1;
          }

          (0, _carpool.updateLine)((0, _objectSpread2.default)({}, _this2.lineForm, {
            companyId: _this2.dept.deptId
          })).then(function (response) {
            if (response.code === 200) {
              _this2.msgSuccess("修改成功");
            } else {
              _this2.msgError(response.msg);
            }

            _this2.handleDialogClose();
          });
        }
      });
    }
  }
};
exports.default = _default;