"use strict";

var _interopRequireDefault = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

var _objectSpread2 = _interopRequireDefault(require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime-corejs2/helpers/objectSpread2"));

var _RightPanel = _interopRequireDefault(require("@/components/RightPanel"));

var _components = require("./components");

var _ResizeHandler = _interopRequireDefault(require("./mixin/ResizeHandler"));

var _vuex = require("vuex");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Layout',
  components: {
    AppMain: _components.AppMain,
    Navbar: _components.Navbar,
    RightPanel: _RightPanel.default,
    Settings: _components.Settings,
    Sidebar: _components.Sidebar,
    TagsView: _components.TagsView
  },
  data: function data() {
    return {
      webSocket: null,
      dialogVisible: false,
      remindLineList: [],
      timer: null
    };
  },
  mixins: [_ResizeHandler.default],
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapState)({
    sidebar: function sidebar(state) {
      return state.app.sidebar;
    },
    device: function device(state) {
      return state.app.device;
    },
    showSettings: function showSettings(state) {
      return state.settings.showSettings;
    },
    needTagsView: function needTagsView(state) {
      return state.settings.tagsView;
    },
    fixedHeader: function fixedHeader(state) {
      return state.settings.fixedHeader;
    }
  }), {
    classObj: function classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile'
      };
    }
  }),
  mounted: function mounted() {
    var _this = this;

    this.$nextTick(function () {
      var text = _this.userName + " " + _this.loginIp;
      console.log(text); // 设置水印

      _this.$watermark.set(text, _this.$refs.mainContainer);
    });
    this.initWebSocket();
  },
  methods: {
    handleClickOutside: function handleClickOutside() {
      this.$store.dispatch('app/closeSideBar', {
        withoutAnimation: false
      });
    },
    // 连接websocket
    initWebSocket: function initWebSocket() {
      var _this2 = this;

      this.webSocket = new WebSocket("wss://mangerapihellofreepre.hellouxing.com/ws/asset/" + this.dept.deptId);

      this.webSocket.onopen = function () {
        _this2.timer = setInterval(function () {
          _this2.webSocket.send('heartbeat');
        }, 5000);
      };

      this.webSocket.onmessage = function (event) {
        console.log(event, "onmemesg***ssage");

        try {
          var msg;
          event.data.includes("连接") || event.data.includes("heartbeat") ? msg = {
            type: "000"
          } : msg = JSON.parse(event.data);
          console.log(msg, "onmessage");

          switch (msg.type) {
            case "000":
              console.log("建立实时连接成功!");
              break;

            case "ticketRemind":
              // 有余票不足提醒
              _this2.remindLineList = msg.data;
              _this2.dialogVisible = true;
              break;

            default:
              console.log("default websocket");
              break;
          }
        } catch (error) {
          console.log(error, "socket数据有误!!!");
        }
      };

      this.webSocket.onclose = function () {
        clearInterval(_this2.timer);
        _this2.timer = null;
        debounce(function () {
          _this2.initWebsocket();
        }, 10000);
      };

      this.webSocket.onerror = function (err) {
        console.log(err, "errr");
        clearInterval(_this2.timer);
        _this2.timer = null;
        debounce(function () {
          _this2.initWebsocket();
        }, 10000);
      };
    },
    // 跳转到线路
    gotoLine: function gotoLine(item) {
      this.dialogVisible = false;
      this.$router.push({
        path: "/yewuguanli/busCountry/1/line",
        query: {
          remindItem: JSON.stringify(item),
          timestamp: new Date().getTime()
        }
      });
    }
  }
};
exports.default = _default;