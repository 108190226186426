"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "BasicInfoForm",
  props: {
    info: {
      type: Object,
      default: null
    }
  },
  data: function data() {
    return {
      rules: {
        tplCategory: [{
          required: true,
          message: "请选择生成模板",
          trigger: "blur"
        }],
        packageName: [{
          required: true,
          message: "请输入生成包路径",
          trigger: "blur"
        }],
        moduleName: [{
          required: true,
          message: "请输入生成模块名",
          trigger: "blur"
        }],
        businessName: [{
          required: true,
          message: "请输入生成业务名",
          trigger: "blur"
        }],
        functionName: [{
          required: true,
          message: "请输入生成功能名",
          trigger: "blur"
        }]
      }
    };
  },
  created: function created() {}
};
exports.default = _default;