"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  props: {
    openTimeDialog: {
      type: Boolean,
      default: false
    },
    rawData: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      activeName: "first",
      // rawData: {
      //   startTime: "",
      //   endTime: "",
      //   dateRange: [
      //     this.supplementZero(new Date().getHours()) +
      //       ":" +
      //       this.supplementZero(new Date().getMinutes()),
      //     this.supplementZero(new Date().getHours()) +
      //       ":" +
      //       this.supplementZero(new Date().getMinutes())
      //   ]
      // },
      // 表单校验
      rules: {
        startTime: [{
          required: true,
          message: "请选择最早揽件时间",
          trigger: "change"
        }],
        endTime: [{
          required: true,
          message: "请选择最晚揽件时间",
          trigger: "change"
        }]
      },
      openTimeFormDialog: false
    };
  },
  methods: {
    handleDialogClose: function handleDialogClose() {
      this.$emit("closepop"); //通知父组件改变。
    },
    onPickerChange: function onPickerChange(type) {
      console.log(type);
      this.rawData.endTime = "";
    },
    onTimeFormSubmit: function onTimeFormSubmit() {
      var _this = this;

      console.log(this.rawData);
      this.$refs["rawData"].validate(function (valid) {
        if (valid) {
          _this.$emit("submit", _this.rawData);

          _this.handleDialogClose();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleAdd: function handleAdd(type) {
      this.openTimeFormDialog = true;
    }
  }
};
exports.default = _default2;