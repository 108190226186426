var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          ref: "queryForm",
          attrs: { model: _vm.queryParams, inline: true, "label-width": "68px" }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "线路名称", prop: "lineId" } },
            [
              _c(
                "el-select",
                {
                  attrs: {
                    placeholder: "请选择线路",
                    clearable: "",
                    size: "mini"
                  },
                  model: {
                    value: _vm.queryParams.lineId,
                    callback: function($$v) {
                      _vm.$set(_vm.queryParams, "lineId", $$v)
                    },
                    expression: "queryParams.lineId"
                  }
                },
                _vm._l(_vm.lineList, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.lineName, value: item.id }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "查询周期", prop: "type" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择查询周期", size: "mini" },
                  model: {
                    value: _vm.queryParams.type,
                    callback: function($$v) {
                      _vm.$set(_vm.queryParams, "type", $$v)
                    },
                    expression: "queryParams.type"
                  }
                },
                _vm._l(_vm.statusOptions, function(dict) {
                  return _c("el-option", {
                    key: dict.dictValue,
                    attrs: { label: dict.dictLabel, value: dict.dictValue }
                  })
                }),
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    icon: "el-icon-search",
                    size: "mini"
                  },
                  on: { click: _vm.handleQuery }
                },
                [_vm._v("搜索")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { icon: "el-icon-refresh", size: "mini" },
                  on: { click: _vm.resetQuery }
                },
                [_vm._v("重置")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "mb8", attrs: { gutter: 10 } },
        [
          _c(
            "el-col",
            { attrs: { span: 1.5 } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "warning",
                    icon: "el-icon-download",
                    size: "mini"
                  },
                  on: { click: _vm.handleExport }
                },
                [_vm._v("导出")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          attrs: { data: _vm.typeList }
        },
        [
          _c("el-table-column", {
            attrs: { type: "index", align: "center", label: "排行" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          (_vm.queryParams.pageNum - 1) *
                            _vm.queryParams.pageSize +
                            scope.$index +
                            1
                        )
                      )
                    ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "线路名称",
              align: "center",
              prop: "lineName",
              "show-overflow-tooltip": true
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "站点名称",
              align: "center",
              prop: "stationName",
              "show-overflow-tooltip": true
            }
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: "客量",
              align: "center",
              prop: "total",
              "show-overflow-tooltip": true
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0"
          }
        ],
        attrs: {
          total: _vm.total,
          page: _vm.queryParams.pageNum,
          limit: _vm.queryParams.pageSize
        },
        on: {
          "update:page": function($event) {
            return _vm.$set(_vm.queryParams, "pageNum", $event)
          },
          "update:limit": function($event) {
            return _vm.$set(_vm.queryParams, "pageSize", $event)
          },
          pagination: _vm.getList
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }