"use strict";

var _interopRequireDefault = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.listUser = listUser;
exports.getUser = getUser;
exports.addUser = addUser;
exports.updateUser = updateUser;
exports.delUser = delUser;
exports.exportUser = exportUser;
exports.resetUserPwd = resetUserPwd;
exports.changeUserStatus = changeUserStatus;
exports.getUserProfile = getUserProfile;
exports.updateUserProfile = updateUserProfile;
exports.updateUserPwd = updateUserPwd;
exports.uploadAvatar = uploadAvatar;
exports.uploadAvata = uploadAvata;
exports.importTemplate = importTemplate;

var _request = _interopRequireDefault(require("@/utils/request"));

var _ruoyi = require("@/utils/ruoyi");

// 查询用户列表
function listUser(query) {
  return (0, _request.default)({
    url: '/system/user/list',
    method: 'get',
    params: query
  });
} // 查询用户详细


function getUser(userId) {
  return (0, _request.default)({
    url: '/system/user/' + (0, _ruoyi.praseStrEmpty)(userId),
    method: 'get'
  });
} // 新增用户


function addUser(data) {
  return (0, _request.default)({
    url: '/system/user',
    method: 'post',
    data: data
  });
} // 修改用户


function updateUser(data) {
  return (0, _request.default)({
    url: '/system/user',
    method: 'put',
    data: data
  });
} // 删除用户


function delUser(userId) {
  return (0, _request.default)({
    url: '/system/user/' + userId,
    method: 'delete'
  });
} // 导出用户


function exportUser(query) {
  return (0, _request.default)({
    url: '/system/user/export',
    method: 'get',
    params: query
  });
} // 用户密码重置


function resetUserPwd(userId, password) {
  var data = {
    userId: userId,
    password: password
  };
  return (0, _request.default)({
    url: '/system/user/resetPwd',
    method: 'put',
    data: data
  });
} // 用户状态修改


function changeUserStatus(userId, status) {
  var data = {
    userId: userId,
    status: status
  };
  return (0, _request.default)({
    url: '/system/user/changeStatus',
    method: 'put',
    data: data
  });
} // 查询用户个人信息


function getUserProfile() {
  return (0, _request.default)({
    url: '/system/user/profile',
    method: 'get'
  });
} // 修改用户个人信息


function updateUserProfile(data) {
  return (0, _request.default)({
    url: '/system/user/profile',
    method: 'put',
    data: data
  });
} // 用户密码重置


function updateUserPwd(oldPassword, newPassword) {
  var data = {
    oldPassword: oldPassword,
    newPassword: newPassword
  };
  return (0, _request.default)({
    url: '/system/user/profile/updatePwd',
    method: 'put',
    params: data
  });
} // 用户头像上传


function uploadAvatar(data) {
  return (0, _request.default)({
    url: '/system/user/profile/avatar',
    method: 'post',
    data: data
  });
} // 用户头像上传


function uploadAvata(data) {
  return (0, _request.default)({
    url: '/uploadfile/upload',
    method: 'post',
    data: data
  });
} // 下载用户导入模板


function importTemplate() {
  return (0, _request.default)({
    url: '/system/user/importTemplate',
    method: 'get'
  });
}