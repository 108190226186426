"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _line = require("@/api/business/line");

var _server = require("@/api/monitor/server");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "Dict",
  data: function data() {
    return {
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      // 字典表格数据
      typeList: [],
      // 状态数据字典
      statusOptions: [{
        dictValue: 0,
        dictLabel: "今日"
      }, {
        dictValue: 1,
        dictLabel: "本周"
      }, {
        dictValue: 2,
        dictLabel: "本月"
      }, {
        dictValue: 3,
        dictLabel: "三个月"
      }],
      // 日期范围
      dateRange: [],
      lineList: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        lineId: undefined,
        type: 3
      }
    };
  },
  created: function created() {
    var _this = this;

    this.getList();
    (0, _line.listLine)().then(function (response) {
      _this.lineList = response.rows;
    });
  },
  methods: {
    /** 查询字典类型列表 */
    getList: function getList() {
      var _this2 = this;

      this.loading = true;
      (0, _server.stationRankList)(this.queryParams).then(function (res) {
        console.log(res, "-------");

        if (res.code === 200) {
          _this2.typeList = res.rows;
          _this2.total = res.total;
        }

        _this2.loading = false;
      });
    },

    /** 搜索按钮操作 */
    handleQuery: function handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },

    /** 重置按钮操作 */
    resetQuery: function resetQuery() {
      this.dateRange = [];
      this.resetForm("queryForm");
      this.handleQuery();
    },

    /** 导出按钮操作 */
    handleExport: function handleExport() {
      var _this3 = this;

      var queryParams = this.queryParams;
      this.$confirm("是否确认导出所有类型数据项?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return (0, _server.exportRankList)(queryParams);
      }).then(function (response) {
        _this3.download(response.msg);
      }).catch(function () {});
    }
  }
};
exports.default = _default;