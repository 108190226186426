var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box-card" },
    [
      _c("div", { staticClass: "header-title" }, [_vm._v("线路营运状态")]),
      _vm._v(" "),
      _c("div", { staticClass: "tips" }, [
        _vm._v("\n    线路名称："),
        _c("span", [_vm._v(_vm._s(_vm.operationData.lineName))])
      ]),
      _vm._v(" "),
      _vm._l(_vm.lists, function(item, index) {
        return _c(
          "div",
          { key: index, staticClass: "dashboard-text" },
          [
            _c("span", [_vm._v(_vm._s(item.config))]),
            _vm._v(" "),
            _c(
              "el-button",
              {
                staticStyle: { color: "#67C23A" },
                attrs: { type: "text" },
                on: {
                  click: function($event) {
                    return _vm.goDetails(item.type)
                  }
                }
              },
              [_vm._v("查看")]
            )
          ],
          1
        )
      }),
      _vm._v(" "),
      _vm.openScheduleOrderDialog
        ? _c("dialogScheduleOrderDialog", {
            attrs: {
              operationData: _vm.operationData,
              openScheduleOrderDialog: _vm.openScheduleOrderDialog
            },
            on: { closepop: _vm.closeScheduleOrderDialog }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.openScheduleModuleDialog
        ? _c("dialogScheduleModuleDialog", {
            attrs: {
              operationData: _vm.operationData,
              openScheduleModuleDialog: _vm.openScheduleModuleDialog
            },
            on: { closepop: _vm.closeScheduleModuleDialog }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.openOperationScopeDialog
        ? _c("dialogOperationScope", {
            key: _vm.timeRefresh,
            attrs: {
              lnglatData: _vm.lnglatData,
              operationData: _vm.operationData,
              openOperationScopeDialog: _vm.openOperationScopeDialog
            },
            on: { reload: _vm.reLoad, closepop: _vm.closeOperationScopeDialog }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.openLinePriceDialog
        ? _c("dialogLinePrice", {
            attrs: {
              openLinePriceDialog: _vm.openLinePriceDialog,
              operationData: _vm.operationData
            },
            on: {
              submit: _vm.submitLinePriceDialog,
              closepop: _vm.closeLinePriceDialog
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.openServiceDialog
        ? _c("dialogServiceConfig", {
            attrs: {
              openServiceDialog: _vm.openServiceDialog,
              operationData: _vm.operationData
            },
            on: {
              submit: _vm.submitServiceDialog,
              closepop: _vm.closeServiceDialog
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.openGeneralConfigDialog
        ? _c("dialogGeneralConfig", {
            attrs: {
              openGeneralConfigDialog: _vm.openGeneralConfigDialog,
              operationData: _vm.operationData
            },
            on: {
              submit: _vm.submitGeneralConfigDialog,
              closepop: _vm.closeGeneralConfigDialog
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.openDialogCancelConfig
        ? _c("dialogCancelConfig", {
            attrs: {
              operationData: _vm.operationData,
              openDialogCancelConfig: _vm.openDialogCancelConfig
            },
            on: { closepop: _vm.closeDialogCancelConfig }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.openCarConfigDialog
        ? _c("dialogCarConfig", {
            attrs: {
              operationData: _vm.operationData,
              openCarConfigDialog: _vm.openCarConfigDialog
            },
            on: { closepop: _vm.closeDialogCarConfig }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }