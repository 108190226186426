"use strict";

var _interopRequireDefault = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _userAvatar = _interopRequireDefault(require("./userAvatar"));

var _userInfo = _interopRequireDefault(require("./userInfo"));

var _resetPwd = _interopRequireDefault(require("./resetPwd"));

var _user = require("@/api/system/user");

var _server = require("@/api/monitor/server");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "Profile",
  components: {
    userAvatar: _userAvatar.default,
    userInfo: _userInfo.default,
    resetPwd: _resetPwd.default
  },
  data: function data() {
    return {
      user: {},
      roleGroup: {},
      postGroup: {},
      activeTab: "userinfo",
      smsDetail: {}
    };
  },
  created: function created() {
    this.getUser();
  },
  methods: {
    getUser: function getUser() {
      var _this = this;

      (0, _user.getUserProfile)().then(function (response) {
        _this.user = response.data;
        _this.roleGroup = response.roleGroup;
        _this.postGroup = response.postGroup;
      }); // 公司下月短信总数

      (0, _server.countSms)({
        companyId: this.dept.deptId
      }).then(function (res) {
        if (res.code == 200) {
          _this.smsDetail = res.data;
        }
      });
    }
  }
};
exports.default = _default;