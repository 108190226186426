"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'AppMain',
  computed: {
    cachedViews: function cachedViews() {
      if (this.$route.name == 'OrderList' || this.$route.name == 'TripList' || this.$route.name == 'Coupon') {
        return true;
      } else {
        return false;
      } // return this.$store.state.tagsView.cachedViews

    },
    key: function key() {
      if (this.cachedViews) {
        return this.$route.path;
      } else {
        return this.$route.path + new Date();
      }
    }
  }
};
exports.default = _default;