"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.array.find");

require("core-js/modules/es6.function.name");

require("core-js/modules/es6.regexp.to-string");

var _coupon = require("@/api/system/coupon");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "SendCoupon",
  data: function data() {
    return {
      couponSendType: 1,
      // 表单参数
      form: {
        phone: null,
        giftId: [],
        remark: null,
        service: ''
      },
      // 表单校验
      rules: {
        remark: [{
          required: true,
          message: "请输入备注",
          trigger: "blur"
        }]
      },
      file: null,
      fileList: [],
      tableData: [],
      listAllCoupon: [],
      couponList: [],
      sms: 1,
      buttonClicked: false
    };
  },
  mounted: function mounted() {
    console.log(this.$route.query.id);
    this.getListAllCoupon();
  },
  methods: {
    /** 提交按钮 */
    submitForm: function submitForm() {
      var _this = this;

      if (this.buttonClicked) {
        return;
      }

      this.$refs["form"].validate(function (valid) {
        if (valid) {
          // 单个发放手机号不能为空
          if (_this.couponSendType == 1 && !_this.form.phone) {
            _this.msgError("请输入手机号");

            return;
          } // 手机号格式


          if (_this.couponSendType == 1 && _this.form.phone && _this.form.phone.length != 11) {
            _this.msgError("请输入正确的手机号");

            return;
          } // 手机号格式


          if (_this.couponSendType == 2 && !_this.file) {
            _this.msgError("请上传文件");

            return;
          } // 选择优惠券


          if (_this.couponList) {
            _this.form.giftId = _this.couponList.toString();
          } else {
            _this.msgError("请选择优惠券");

            return;
          } // 防止重复点击


          _this.buttonClicked = true; // 单个用户

          if (_this.couponSendType == 1) {
            var params = new FormData();
            params.append('phone', _this.form.phone);
            params.append('giftId', _this.form.giftId);
            params.append('remark', _this.form.remark);
            params.append('service', ""); // 发放优惠券

            (0, _coupon.sendGiftCouponToUser)(params).then(function (res) {
              if (res.code == 200) {
                _this.msgSuccess("发放成功");

                _this.reset();

                _this.buttonClicked = false;
              }
            }).catch(function () {
              _this.reset();

              _this.buttonClicked = false;
            });
          } else {
            // 批量导入
            var _params = new FormData();

            _params.append('file', _this.file.raw);

            _params.append('giftId', _this.form.giftId);

            _params.append('remark', _this.form.remark);

            _params.append('service', ""); // 发放优惠券


            (0, _coupon.sendGiftCouponToUserList)(_params).then(function (res) {
              if (res.code == 200) {
                _this.msgSuccess("发放成功");

                _this.reset();

                _this.buttonClicked = false;
              }
            }).catch(function () {
              _this.reset();

              _this.buttonClicked = false;
            });
          }
        }
      });
    },
    reset: function reset() {
      this.couponList = null;
      this.form.couponIds = null;
      this.form.phone = null;
      this.form.remark = null;
    },
    // 查询所有优惠券
    getListAllCoupon: function getListAllCoupon() {
      var _this2 = this;

      (0, _coupon.selectList)().then(function (response) {
        _this2.listAllCoupon = response.data;
      });
    },
    // 导入成功时执行
    handleChange: function handleChange(res, file, fileList) {
      // 判断格式
      if (!/.(xls|xlsx)$/.test(fileList[0].name.toLowerCase())) {
        this.$refs.upload.clearFiles();
        this.$message.error('上传格式不正确，请上传xls或xlsx格式');
        return;
      }

      this.file = file;
      console.log(file);
    },
    // 选择优惠券
    selectCouponChange: function selectCouponChange(e) {
      var coupon = this.listAllCoupon.find(function (item) {
        return item.id == e;
      });

      switch (coupon.couponScope) {
        case 2:
          this.form.service = '定制班车';
          break;

        case 3:
          this.form.service = '城际拼车';
          break;

        case 4:
          this.form.service = '校园巴士';
          break;
      }
    }
  }
};
exports.default = _default;