"use strict";

var _interopRequireDefault = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getServer = getServer;
exports.rongLiangQuShiTu = rongLiangQuShiTu;
exports.stationRankList = stationRankList;
exports.exportRankList = exportRankList;
exports.shouYiQuShiTu = shouYiQuShiTu;
exports.shouYiKeLiang = shouYiKeLiang;
exports.revenueStatistics = revenueStatistics;
exports.revenueStatisticsShouYiQuShiTu = revenueStatisticsShouYiQuShiTu;
exports.orderStatistics = orderStatistics;
exports.orderStatisticsShouYiQuShiTu = orderStatisticsShouYiQuShiTu;
exports.capacityStatistics = capacityStatistics;
exports.capacityStatisticsShouYiQuShiTu = capacityStatisticsShouYiQuShiTu;
exports.jinRiKeLiang = jinRiKeLiang;
exports.defaultMsg = defaultMsg;
exports.countSms = countSms;

var _request = _interopRequireDefault(require("@/utils/request"));

// 查询服务器详细
function getServer() {
  return (0, _request.default)({
    url: '/monitor/server',
    method: 'get'
  });
}

function rongLiangQuShiTu(data) {
  return (0, _request.default)({
    url: '/system/main/keLiangQuShiTu',
    method: 'get',
    params: data
  });
}

function stationRankList(data) {
  return (0, _request.default)({
    url: '/system/stationRank/list',
    method: 'get',
    params: data
  });
}

function exportRankList(data) {
  return (0, _request.default)({
    url: '/system/stationRank/export',
    method: 'get',
    params: data
  });
}

function shouYiQuShiTu(data) {
  return (0, _request.default)({
    url: '/system/main/shouYiQuShiTu',
    method: 'get',
    params: data
  });
}

function shouYiKeLiang(data) {
  return (0, _request.default)({
    url: '/system/main/jinRiShouYi',
    method: 'get',
    params: data
  });
}

function revenueStatistics(data) {
  return (0, _request.default)({
    url: '/system/revenueStatistics/defaultMsg',
    method: 'get',
    params: data
  });
}

function revenueStatisticsShouYiQuShiTu(data) {
  return (0, _request.default)({
    url: '/system/revenueStatistics/shouYiQuShiTu',
    method: 'get',
    params: data
  });
}

function orderStatistics(data) {
  return (0, _request.default)({
    url: '/system/orderStatistics/defaultMsg',
    method: 'get',
    params: data
  });
}

function orderStatisticsShouYiQuShiTu(data) {
  return (0, _request.default)({
    url: '/system/orderStatistics/dingDanQuShiTu',
    method: 'get',
    params: data
  });
}

function capacityStatistics(data) {
  return (0, _request.default)({
    url: '/system/capacityStatistics/defaultMsg',
    method: 'get',
    params: data
  });
}

function capacityStatisticsShouYiQuShiTu(data) {
  return (0, _request.default)({
    url: '/system/capacityStatistics/keLiangQuShiTu',
    method: 'get',
    params: data
  });
}

function jinRiKeLiang(data) {
  return (0, _request.default)({
    url: '/system/main/jinRiKeLiang',
    method: 'get',
    params: data
  });
}

function defaultMsg(data) {
  return (0, _request.default)({
    url: '/system/main/defaultMsg',
    method: 'get',
    params: data
  });
} // 公司下月短信总数


function countSms(data) {
  return (0, _request.default)({
    url: '/system/sms/countSms',
    method: 'get',
    params: data
  });
}