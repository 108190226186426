"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var watermark = {};

var setWatermark = function setWatermark(text, sourceBody) {
  var id = Math.random() * 10000 + '-' + Math.random() * 10000 + '/' + Math.random() * 10000;

  if (document.getElementById(id) !== null) {
    document.body.removeChild(document.getElementById(id));
  }

  var can = document.createElement('canvas');
  can.width = 300;
  can.height = 220;
  var cans = can.getContext('2d');
  cans.rotate(-20 * Math.PI / 180);
  cans.font = '15px Vedana';
  cans.fillStyle = 'rgba(0, 0, 0, .1)';
  cans.textAlign = 'left';
  cans.textBaseline = 'Middle';
  cans.fillText(text, can.width / 20, can.height);
  var water_div = document.createElement('div');
  water_div.id = id;
  water_div.style.pointerEvents = 'none';
  water_div.style.background = 'url(' + can.toDataURL('image/png') + ') left top repeat';

  if (sourceBody) {
    water_div.style.width = '100%';
    water_div.style.height = '100%';
    water_div.style.position = 'fixed';
    water_div.style.zIndex = '1';
    water_div.style.top = '0';
    water_div.style.left = '0';
    sourceBody.appendChild(water_div);
  } else {
    water_div.style.top = '3px';
    water_div.style.left = '0px';
    water_div.style.position = 'fixed';
    water_div.style.zIndex = '100000';
    water_div.style.width = document.documentElement.clientWidth + 'px';
    water_div.style.height = document.documentElement.clientHeight + 'px';
    document.body.appendChild(water_div);
  }

  return id;
};
/**
 *  该方法只允许调用一次
 *  @param:
 *  @text == 水印内容
 *  @sourceBody == 水印添加在哪里，不传就是body
 * */


watermark.set = function (text, sourceBody) {
  var id = setWatermark(text, sourceBody);
  setInterval(function () {
    if (document.getElementById(id) === null) {
      id = setWatermark(text, sourceBody);
    }
  }, 2000); // 窗体改变重新绘制，暂时不用
  //   window.onresize = () => {
  //     setWatermark(text, sourceBody)
  //   }
};

var _default = watermark;
exports.default = _default;