"use strict";

var _interopRequireDefault = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.string.iterator");

var _gen = require("@/api/tool/gen");

var _type = require("@/api/system/dict/type");

var _basicInfoForm = _interopRequireDefault(require("./basicInfoForm"));

var _genInfoForm = _interopRequireDefault(require("./genInfoForm"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "GenEdit",
  components: {
    basicInfoForm: _basicInfoForm.default,
    genInfoForm: _genInfoForm.default
  },
  data: function data() {
    return {
      // 选中选项卡的 name
      activeName: "cloum",
      // 表格的高度
      tableHeight: document.documentElement.scrollHeight - 245 + "px",
      // 表列信息
      cloumns: [],
      // 字典信息
      dictOptions: [],
      // 表详细信息
      info: {}
    };
  },
  beforeCreate: function beforeCreate() {
    var _this = this;

    var tableId = this.$route.query.tableId;

    if (tableId) {
      // 获取表详细信息
      (0, _gen.getGenTable)(tableId).then(function (res) {
        _this.cloumns = res.data.rows;
        _this.info = res.data.info;
      });
      /** 查询字典下拉列表 */

      (0, _type.optionselect)().then(function (response) {
        _this.dictOptions = response.data;
      });
    }
  },
  methods: {
    /** 提交按钮 */
    submitForm: function submitForm() {
      var _this2 = this;

      var basicForm = this.$refs.basicInfo.$refs.basicInfoForm;
      var genForm = this.$refs.genInfo.$refs.genInfoForm;
      Promise.all([basicForm, genForm].map(this.getFormPromise)).then(function (res) {
        var validateResult = res.every(function (item) {
          return !!item;
        });

        if (validateResult) {
          var genTable = Object.assign({}, basicForm.model, genForm.model);
          genTable.columns = _this2.cloumns;
          genTable.params = {
            treeCode: genTable.treeCode,
            treeName: genTable.treeName,
            treeParentCode: genTable.treeParentCode
          };
          (0, _gen.updateGenTable)(genTable).then(function (res) {
            _this2.msgSuccess(res.msg);

            if (res.code === 200) {
              _this2.close();
            }
          });
        } else {
          _this2.msgError("表单校验未通过，请重新检查提交内容");
        }
      });
    },
    getFormPromise: function getFormPromise(form) {
      return new Promise(function (resolve) {
        form.validate(function (res) {
          resolve(res);
        });
      });
    },

    /** 关闭按钮 */
    close: function close() {
      this.$store.dispatch("tagsView/delView", this.$route);
      this.$router.push({
        path: "/tool/gen",
        query: {
          t: Date.now()
        }
      });
    }
  }
};
exports.default = _default;