"use strict";

var _interopRequireDefault = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime-corejs2/helpers/defineProperty"));

var _jobLog = require("@/api/monitor/jobLog");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "JobLog",
  data: function data() {
    return (0, _defineProperty2.default)({
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      // 调度日志表格数据
      jobLogList: [],
      // 是否显示弹出层
      open: false,
      // 日期范围
      dateRange: [],
      // 表单参数
      form: {},
      // 执行状态字典
      statusOptions: [],
      // 任务组名字典
      jobGroupOptions: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        jobName: undefined,
        jobGroup: undefined,
        status: undefined
      }
    }, "form", {});
  },
  created: function created() {
    var _this = this;

    this.getList();
    this.getDicts("sys_job_status").then(function (response) {
      _this.statusOptions = response.data;
    });
    this.getDicts("sys_job_group").then(function (response) {
      _this.jobGroupOptions = response.data;
    });
  },
  methods: {
    /** 查询调度日志列表 */
    getList: function getList() {
      var _this2 = this;

      this.loading = true;
      (0, _jobLog.listJobLog)(this.addDateRange(this.queryParams, this.dateRange)).then(function (response) {
        _this2.jobLogList = response.rows;
        _this2.total = response.total;
        _this2.loading = false;
      });
    },
    // 执行状态字典翻译
    statusFormat: function statusFormat(row, column) {
      return this.selectDictLabel(this.statusOptions, row.status);
    },
    // 任务组名字典翻译
    jobGroupFormat: function jobGroupFormat(row, column) {
      return this.selectDictLabel(this.jobGroupOptions, row.jobGroup);
    },

    /** 搜索按钮操作 */
    handleQuery: function handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },

    /** 重置按钮操作 */
    resetQuery: function resetQuery() {
      this.dateRange = [];
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange: function handleSelectionChange(selection) {
      this.ids = selection.map(function (item) {
        return item.jobLogId;
      });
      this.multiple = !selection.length;
    },

    /** 详细按钮操作 */
    handleView: function handleView(row) {
      this.open = true;
      this.form = row;
    },

    /** 删除按钮操作 */
    handleDelete: function handleDelete(row) {
      var _this3 = this;

      var jobLogIds = this.ids;
      this.$confirm('是否确认删除调度日志编号为"' + jobLogIds + '"的数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return (0, _jobLog.delJobLog)(jobLogIds);
      }).then(function () {
        _this3.getList();

        _this3.msgSuccess("删除成功");
      }).catch(function () {});
    },

    /** 清空按钮操作 */
    handleClean: function handleClean() {
      var _this4 = this;

      this.$confirm("是否确认清空所有调度日志数据项?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return (0, _jobLog.cleanJobLog)();
      }).then(function () {
        _this4.getList();

        _this4.msgSuccess("清空成功");
      }).catch(function () {});
    },

    /** 导出按钮操作 */
    handleExport: function handleExport() {
      var _this5 = this;

      var queryParams = this.queryParams;
      this.$confirm("是否确认导出所有调度日志数据项?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return (0, _jobLog.exportJobLog)(queryParams);
      }).then(function (response) {
        _this5.download(response.msg);
      }).catch(function () {});
    }
  }
};
exports.default = _default;