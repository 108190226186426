"use strict";

var _interopRequireDefault = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.login = login;
exports.getInfo = getInfo;
exports.logout = logout;
exports.getCodeImg = getCodeImg;

var _request = _interopRequireDefault(require("@/utils/request"));

// 登录方法
function login(username, password, code, uuid) {
  var data = {
    username: username,
    password: password,
    code: code,
    uuid: uuid
  };
  return (0, _request.default)({
    url: '/login',
    method: 'post',
    params: data
  });
} // 获取用户详细信息


function getInfo() {
  return (0, _request.default)({
    url: '/getInfo',
    method: 'get'
  });
} // 退出方法


function logout() {
  return (0, _request.default)({
    url: '/logout',
    method: 'post'
  });
} // 获取验证码


function getCodeImg() {
  return (0, _request.default)({
    url: '/captchaImage',
    method: 'get'
  });
}