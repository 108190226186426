"use strict";

var _interopRequireDefault = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _store = _interopRequireDefault(require("@/store"));

var _axios = _interopRequireDefault(require("axios"));

var _carpool = require("@/api/business/carpool");

var _mapLineComponents = _interopRequireDefault(require("../newParcel/components/mapLineComponents"));

var _utils = require("@/utils");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    mapLineComponents: _mapLineComponents.default
  },
  data: function data() {
    return {
      showMap: false,
      orderNo: null,
      orderDetail: {
        carpoolOrderInfo: {
          travelId: "",
          orderNo: "",
          orderStatusText: "",
          driverPhone: "",
          driverNickName: "",
          money: 0,
          refundMoney: 0,
          carNo: "",
          carNote: ""
        },
        carpoolTravelRecord: [],
        carpoolOrderSameList: []
      },
      opneOrderRecordAudio: false,
      // 打开行程录音弹框
      audioSrc: '' // 行程录音链接

    };
  },
  mounted: function mounted() {},
  created: function created() {
    this.orderNo = this.$route.query.orderNo;
    this.getDetail();
  },
  methods: {
    filterChannel: function filterChannel(value, scanCode) {
      switch (value) {
        case "CUSTOM":
          return "客服代买票";

        case "WECHAT_MINI_APP":
          if (scanCode == 1) {
            return '扫码购票';
          }

          return "小程序买票";

        case "WECHAT_MINI_APP_SO":
          return "拼车扫码下单";

        default:
          return "暂无";
      }
    },
    statusText: function statusText(data) {
      if (data.status == 200) {
        switch (data.orderStatus) {
          case 0:
            data.orderStatusText = "预约成功";
            break;

          case 1:
            data.orderStatusText = "待出发";
            break;

          case 2:
            data.orderStatusText = "接驾中";
            break;

          case 3:
            data.orderStatusText = "司机已到达上车点";
            break;

          case 4:
            data.orderStatusText = "已上车";
            break;

          case 5:
            data.orderStatusText = "已出发";
            break;

          case 6:
            data.orderStatusText = "已完成";
            break;

          default:
            break;
        }
      }

      if (data.status == 400 || data.status == 450 || data.status == 460 || data.status == 500) {
        data.orderStatusText = "已取消";
        data.cancelTime = (0, _utils.formatDate)(data.created * 1000);
      }

      if (data.status == 100) {
        data.orderStatusText = "未支付";
      }
    },

    /** 获取订单详情 */
    getDetail: function getDetail() {
      var _this = this;

      (0, _carpool.carpoolOrderDetail)({
        orderNo: this.orderNo
      }).then(function (res) {
        _this.statusText(res.data.carpoolOrderInfo);

        _this.orderDetail = res.data;
        _this.orderDetail.carpoolOrderInfo.orderCreateTime = new Date(_this.orderDetail.carpoolOrderInfo.orderCreateTime).getTime(); // 前端上传的是进行中的id，所以使用travelHotId

        var travelId;

        if (_this.orderDetail.carpoolOrderInfo.status == 200 && _this.orderDetail.carpoolOrderInfo.orderStatus == 6) {
          travelId = _this.orderDetail.carpoolOrderInfo.travelHotId;
        } else {
          travelId = _this.orderDetail.carpoolOrderInfo.travelId;
        }

        _this.audioSrc = 'https://file.hellouxing.com/uploadTravelRecord-' + _this.orderDetail.carpoolOrderInfo.travelHotId + '.mp3';

        if (_this.orderDetail.carpoolTravelRecord && _this.orderDetail.carpoolTravelRecord.length > 0) {
          var newArray = [];
          _this.orderDetail.carpoolTravelRecord = _this.orderDetail.carpoolTravelRecord.filter(function (item) {
            switch (item.orderStatus) {
              case 0:
                item.orderStatusText = "下单成功";
                break;

              case 1:
                item.orderStatusText = "已指派";
                break;

              case 2:
                item.orderStatusText = "司机去接乘客";
                break;

              case 3:
                item.orderStatusText = "司机已到达乘客上车点";
                break;

              case 4:
                item.orderStatusText = "乘客已上车";
                break;

              case 5:
                item.orderStatusText = "司机开始送乘客";
                break;

              case 6:
                item.orderStatusText = "已将乘客送达";
                break;

              default:
                break;
            }

            item.personNum = _this.orderDetail.carpoolOrderInfo.personNum;
            item.startDetailsAddress = _this.orderDetail.carpoolOrderInfo.startDetailsAddress;
            item.endDetailsAddress = _this.orderDetail.carpoolOrderInfo.endDetailsAddress;
            item.scheduleDay = _this.orderDetail.carpoolOrderInfo.scheduleDay;
            item.orderTime = _this.orderDetail.carpoolOrderInfo.orderTime;
            return item;
          });

          if (_this.orderDetail.carpoolOrderSameList && _this.orderDetail.carpoolOrderSameList.length > 0) {
            _this.orderDetail.carpoolOrderSameList.map(function (item) {
              _this.statusText(item);
            });
          }

          _this.showMap = true;
        }
      });
    },
    handleCancelOrder: function handleCancelOrder() {
      var _this2 = this;

      this.$confirm("\u662F\u5426\u786E\u8BA4\u53D6\u6D88\u8BA2\u5355\u4E3A".concat(this.orderDetail.carpoolOrderInfo.orderNo, "\u7684\u8BA2\u5355\uFF1F"), {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        (0, _carpool.cancelExpressOrder)({
          orderNo: _this2.orderDetail.carpoolOrderInfo.orderNo
        }).then(function (res) {
          if (res.code === 200) {
            _this2.msgSuccess(res.msg);
          } else {
            _this2.$message.error(res.msg);
          }

          _this2.getDetail();
        });
      });
    }
  }
};
exports.default = _default;