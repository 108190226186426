var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-row",
        [
          _c("el-col", { staticClass: "pr20", attrs: { span: 14 } }, [
            _vm.orderDetail.carpoolOrderInfo.status == 100 ||
            _vm.orderDetail.carpoolOrderInfo.status == 200
              ? _c(
                  "h2",
                  { staticClass: "operationBox" },
                  [
                    _vm._v("\n        订单操作：\n        "),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "small" },
                        on: {
                          click: function($event) {
                            _vm.opneOrderRecordAudio = true
                          }
                        }
                      },
                      [_vm._v("行程录音")]
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "title" }, [_vm._v("订单信息")]),
            _vm._v(" "),
            _c("table", { staticClass: "infoTable mb40" }, [
              _c("tr", [
                _c("td", { staticClass: "tr bold", attrs: { width: "10%" } }, [
                  _vm._v("行程编号")
                ]),
                _vm._v(" "),
                _c("td", { attrs: { width: "30%" } }, [
                  _vm._v(_vm._s(_vm.orderDetail.carpoolOrderInfo.travelId))
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "tr bold", attrs: { width: "10%" } }, [
                  _vm._v("行程状态")
                ]),
                _vm._v(" "),
                _c("td", { attrs: { width: "30%" } }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.orderDetail.carpoolOrderInfo.orderStatusText) +
                      "\n          "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", { staticClass: "tr bold" }, [_vm._v("订单金额")]),
                _vm._v(" "),
                _c("td", { attrs: { colspan: "3" } }, [
                  _vm._v(
                    "\n            总金额：" +
                      _vm._s(_vm.orderDetail.carpoolOrderInfo.orderMoney) +
                      " \n            实付金额：" +
                      _vm._s(
                        _vm.orderDetail.carpoolOrderInfo.channel == "CUSTOM"
                          ? _vm.orderDetail.carpoolOrderInfo.status == 200 ||
                            _vm.orderDetail.carpoolOrderInfo.status == 460
                            ? _vm.orderDetail.carpoolOrderInfo.realMoney
                            : 0
                          : _vm.orderDetail.carpoolOrderInfo.isRealPay
                          ? _vm.orderDetail.carpoolOrderInfo.realMoney
                          : 0
                      ) +
                      " \n            优惠金额：" +
                      _vm._s(_vm.orderDetail.carpoolOrderInfo.directPrice) +
                      " \n            退款金额：" +
                      _vm._s(
                        _vm.orderDetail.carpoolOrderInfo.refundMoney
                          ? _vm.orderDetail.carpoolOrderInfo.refundMoney
                          : 0
                      ) +
                      " \n            退款手续费：" +
                      _vm._s(_vm.orderDetail.carpoolOrderInfo.serviceCharge) +
                      "\n          "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", { staticClass: "tr bold" }, [_vm._v("优惠承担金额")]),
                _vm._v(" "),
                _c("td", { attrs: { colspan: "3" } }, [
                  _vm._v(
                    "\n            平台：" +
                      _vm._s(
                        _vm.orderDetail.carpoolOrderInfo.couponPlatformMoney ||
                          "--"
                      ) +
                      " \n            加盟商：" +
                      _vm._s(
                        _vm.orderDetail.carpoolOrderInfo.couponCompanyMoney ||
                          "--"
                      ) +
                      "\n          "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", { staticClass: "tr bold" }, [_vm._v("订单退款时间")]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    _vm._s(
                      _vm.orderDetail.carpoolOrderInfo.isRealPay &&
                        _vm.orderDetail.carpoolOrderInfo.status == 500
                        ? _vm.orderDetail.carpoolOrderInfo.cancelTime
                        : ""
                    )
                  )
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "tr bold" }, [_vm._v("订单取消时间")]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.orderDetail.carpoolOrderInfo.cancelTime) +
                      "\n          "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", { staticClass: "tr bold" }, [_vm._v("订单取消方式")]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(_vm._s(_vm.orderDetail.carpoolOrderInfo.operator))
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "tr bold" }, [_vm._v("司机")]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.orderDetail.carpoolOrderInfo.driverPhone) +
                      " " +
                      _vm._s(_vm.orderDetail.carpoolOrderInfo.driverRealName) +
                      "\n          "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", { staticClass: "tr bold" }, [_vm._v("订单号")]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(_vm._s(_vm.orderDetail.carpoolOrderInfo.orderNo))
                ]),
                _vm._v(" "),
                _c("td", { staticClass: "tr bold" }, [_vm._v("车型")]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.orderDetail.carpoolOrderInfo.carNo) +
                      "/" +
                      _vm._s(_vm.orderDetail.carpoolOrderInfo.carNote) +
                      "\n          "
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "title" }, [_vm._v("订单追踪")]),
            _vm._v(" "),
            _c("div", { staticClass: "clearfix" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "cardBox" },
              [
                _c("div", { staticClass: "cardItem" }, [
                  _c("div", { staticClass: "cardItemTop" }, [
                    _c("span", [_vm._v("下单成功")]),
                    _vm._v(
                      _vm._s(
                        _vm.parseTime(
                          _vm.orderDetail.carpoolOrderInfo.orderCreateTime
                        )
                      ) + "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "cardItemCont" }, [
                    _c("div", { staticClass: "infoItem" }, [
                      _c("span", { staticClass: "mr20" }, [
                        _c("span", {
                          staticClass: "iconfont icon-account-fill"
                        }),
                        _vm._v(
                          _vm._s(
                            _vm.phoneFormat(
                              _vm.orderDetail.carpoolOrderInfo.userPhone
                            )
                          ) + "\n              "
                        )
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "mr20" }, [
                        _vm._v(
                          "拼车" +
                            _vm._s(_vm.orderDetail.carpoolOrderInfo.personNum) +
                            "人"
                        )
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "mr10" }, [
                        _c("i", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.filterChannel(
                                  _vm.orderDetail.carpoolOrderInfo.channel,
                                  _vm.orderDetail.carpoolOrderInfo.scanCode
                                )
                              )
                          )
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "infoItem" }, [
                      _c("span", {
                        staticClass: "mr10",
                        staticStyle: {
                          width: "5px",
                          height: "5px",
                          "border-radius": "100%",
                          background: "green"
                        }
                      }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.orderDetail.carpoolOrderInfo.startDetailsAddress
                          )
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "infoItem" }, [
                      _c("span", {
                        staticClass: "mr10",
                        staticStyle: {
                          width: "5px",
                          height: "5px",
                          "border-radius": "100%",
                          background: "red"
                        }
                      }),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.orderDetail.carpoolOrderInfo.endDetailsAddress
                          )
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "infoItem" }, [
                      _vm._v(
                        "\n              用车时间：" +
                          _vm._s(
                            _vm.orderDetail.carpoolOrderInfo.scheduleDay +
                              " " +
                              _vm.orderDetail.carpoolOrderInfo.orderTime
                          ) +
                          "\n            "
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _vm._l(_vm.orderDetail.carpoolTravelRecord, function(item) {
                  return _c("div", { key: item.id, staticClass: "cardItem" }, [
                    _c("div", { staticClass: "cardItemTop" }, [
                      _c("span", [_vm._v(_vm._s(item.orderStatusText))]),
                      _vm._v(
                        _vm._s(_vm.parseTime(item.created)) + "\n          "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "cardItemCont" }, [
                      item.orderStatus == 0
                        ? _c("div", { staticClass: "infoItem" }, [
                            _c("span", { staticClass: "mr20" }, [
                              _c("span", {
                                staticClass: "iconfont icon-account-fill"
                              }),
                              _vm._v(_vm._s(_vm.phoneFormat(item.userPhone)))
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "mr20" }, [
                              _vm._v("乘客ID：" + _vm._s(item.userId))
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "mr10" }, [
                              _c("i", [_vm._v("拼车1人")])
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      item.orderStatus == 1
                        ? _c("div", { staticClass: "infoItem" }, [
                            _c("span", { staticClass: "mr20" }, [
                              _c("span", {
                                staticClass: "iconfont icon-account-fill"
                              }),
                              _vm._v(_vm._s(_vm.phoneFormat(item.driverPhone)))
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "mr20" }, [
                              _vm._v(_vm._s(item.driverRealName))
                            ])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      item.orderStatus == 0
                        ? _c("ul", { staticClass: "SEAddress" }, [
                            _c("li", [
                              _vm._v(_vm._s(item.startDetailsAddress))
                            ]),
                            _vm._v(" "),
                            _c("li", [_vm._v(_vm._s(item.endDetailsAddress))])
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      item.orderStatus == 0
                        ? _c("div", { staticClass: "infoItem" }, [
                            _vm._v(
                              "\n                用车时间：" +
                                _vm._s(item.scheduleDay) +
                                " " +
                                _vm._s(item.orderTime) +
                                "\n            "
                            )
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      item.orderStatus == 1
                        ? _c("div", { staticClass: "infoItem" }, [
                            _c("span", {
                              staticClass: "iconfont icon-auto mr10"
                            }),
                            _vm._v(_vm._s(item.carNo) + "\n            ")
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      item.orderStatus > 1
                        ? _c("div", { staticClass: "infoItem" }, [
                            _c("span", { staticClass: "iconfont icon-ditu" }),
                            _vm._v(
                              _vm._s(item.currentAddress) + "\n            "
                            )
                          ])
                        : _vm._e()
                    ])
                  ])
                })
              ],
              2
            )
          ]),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 10 } },
            [
              _c(
                "div",
                { staticClass: "title", staticStyle: { "margin-top": "52px" } },
                [_vm._v("订单路线")]
              ),
              _vm._v(" "),
              _vm.showMap
                ? _c("mapLineComponents", {
                    ref: "mapLineComponents",
                    staticClass: "mb40",
                    attrs: { orderDetail: _vm.orderDetail }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "title" }, [_vm._v("同行订单")]),
              _vm._v(" "),
              _c("table", { staticClass: "infoTable mt10" }, [
                _c("thead", [
                  _c("tr", [
                    _c("th", [_vm._v("订单号")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("下单人")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("乘车人数")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("订单状态")])
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(_vm.orderDetail.carpoolOrderSameList, function(item) {
                    return _c("tr", { key: "carpoolOrderSameList" + item.id }, [
                      _c("td", { attrs: { align: "center" } }, [
                        _vm._v(_vm._s(item.orderNo))
                      ]),
                      _vm._v(" "),
                      _c("td", { attrs: { align: "center" } }, [
                        _vm._v(
                          "尾号" + _vm._s(_vm.lastSubstring(item.userPhone, 4))
                        )
                      ]),
                      _vm._v(" "),
                      _c("td", { attrs: { align: "center" } }, [
                        _vm._v(_vm._s(item.personNum))
                      ]),
                      _vm._v(" "),
                      _c("td", { attrs: { align: "center" } }, [
                        _vm._v(_vm._s(item.orderStatusText))
                      ])
                    ])
                  }),
                  0
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.opneOrderRecordAudio
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "行程录音",
                visible: _vm.opneOrderRecordAudio,
                "close-on-click-modal": false,
                "append-to-body": true,
                width: "70%"
              },
              on: {
                "update:visible": function($event) {
                  _vm.opneOrderRecordAudio = $event
                }
              }
            },
            [
              _c("audio", {
                ref: "audio",
                attrs: { id: "", src: _vm.audioSrc, controls: "" }
              })
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }