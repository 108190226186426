"use strict";

var _interopRequireDefault = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _defineProperty2 = _interopRequireDefault(require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime-corejs2/helpers/defineProperty"));

require("core-js/modules/es6.number.constructor");

var _auth = require("@/utils/auth");

var _vueQuillEditor = require("vue-quill-editor");

require("quill/dist/quill.core.css");

require("quill/dist/quill.snow.css");

require("quill/dist/quill.bubble.css");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 工具栏配置
var toolbarOptions = [["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
["blockquote", "code-block"], // 引用  代码块
[{
  list: "ordered"
}, {
  list: "bullet"
}], // 有序、无序列表
[{
  indent: "-1"
}, {
  indent: "+1"
}], // 缩进
[{
  size: ["small", false, "large", "huge"]
}], // 字体大小
[{
  header: [1, 2, 3, 4, 5, 6, false]
}], // 标题
[{
  color: []
}, {
  background: []
}], // 字体颜色、字体背景颜色
[{
  align: []
}], // 对齐方式
["clean"], // 清除文本格式
["link", "image", "video"] // 链接、图片、视频
];
var _default = {
  props: {
    /* 编辑器的内容 */
    value: {
      type: String
    },

    /* 图片大小 */
    maxSize: {
      type: Number,
      default: 4000 //kb

    }
  },
  components: {
    quillEditor: _vueQuillEditor.quillEditor
  },
  data: function data() {
    var _editorOption, _ref;

    return _ref = {
      content: this.value,
      uploadImgUrl: "",
      editorOption: (_editorOption = {
        placeholder: "",
        theme: "snow"
      }, (0, _defineProperty2.default)(_editorOption, "placeholder", "请输入内容"), (0, _defineProperty2.default)(_editorOption, "modules", {
        toolbar: {
          container: toolbarOptions,
          handlers: {
            image: function image(value) {
              if (value) {
                // 触发input框选择图片文件
                document.querySelector(".quill-img input").click();
              } else {
                this.quill.format("image", false);
              }
            }
          }
        }
      }), _editorOption)
    }, (0, _defineProperty2.default)(_ref, "uploadImgUrl", process.env.VUE_APP_UPLOAD + "/common/upload"), (0, _defineProperty2.default)(_ref, "headers", {
      Authorization: 'Bearer ' + (0, _auth.getToken)()
    }), _ref;
  },
  watch: {
    value: function value() {
      this.content = this.value;
    }
  },
  methods: {
    onEditorBlur: function onEditorBlur() {//失去焦点事件
    },
    onEditorFocus: function onEditorFocus() {//获得焦点事件
    },
    onEditorChange: function onEditorChange(e) {
      //内容改变事件
      this.$emit("input", this.content);
    },
    // 富文本图片上传前
    quillImgBefore: function quillImgBefore(file) {
      if (!this.maxSize) {
        this.$message.error('此功能不允许上传图片！');
        return false;
      }

      var fileType = file.type;

      if (fileType === 'image/jpeg' || fileType === 'image/png') {
        return true;
      } else {
        this.$message.error('请插入图片类型文件(jpg/jpeg/png)');
        return false;
      }
    },
    quillImgSuccess: function quillImgSuccess(res, file) {
      // res为图片服务器返回的数据
      // 获取富文本组件实例
      var quill = this.$refs.quillEditor.quill; // 如果上传成功

      if (res.code == 10000) {
        // 获取光标所在位置
        console.log(quill.getSelection());
        var length = 1;

        if (quill && quill.getSelection()) {
          length = quill.getSelection().index;
        } // 插入图片  res.url为服务器返回的图片地址


        quill.insertEmbed(length, "image", res.data); // 调整光标到最后

        quill.setSelection(length + 1);
      } else {
        this.$message.error("图片插入失败");
      }
    },
    // 富文本图片上传失败
    uploadError: function uploadError() {
      // loading动画消失
      this.$message.error("图片插入失败");
    }
  }
};
exports.default = _default;