"use strict";

var _interopRequireDefault = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime-corejs2/helpers/objectSpread2"));

var _carpool = require("@/api/business/carpool");

var _dialogScheduleCarConfigDialog = _interopRequireDefault(require("./dialogScheduleCarConfigDialog.vue"));

var _utils = require("@/utils");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    openCarConfigDialog: {
      type: Boolean,
      default: false
    },
    operationData: {
      type: Object,
      required: true
    }
  },
  components: {
    dialogScheduleCarConfigDialog: _dialogScheduleCarConfigDialog.default
  },
  data: function data() {
    return {
      loading: false,
      carList: [],
      total: 0,
      queryParams: {
        pageNum: 1,
        pageSize: 10
      },
      scheduleCarConfigShow: false,
      templateScheduleId: 0
    };
  },
  created: function created() {
    this.queryParams.lineId = this.operationData.id;
    this.getList();
  },
  methods: {
    handleDialogClose: function handleDialogClose() {
      this.$emit("closepop"); //通知父组件改变。
    },
    // 查询线路下车型模板配置
    getList: function getList() {
      var _this = this;

      (0, _carpool.selectCarpoolScheduleCarByLineId)((0, _objectSpread2.default)({}, this.queryParams)).then(function (res) {
        _this.carList = res.rows;
        _this.total = res.total;
      });
    },
    handleUpdate: function handleUpdate(row) {
      this.templateScheduleId = row.id;
      this.scheduleCarConfigShow = true;
    },
    handleDelete: function handleDelete(row) {
      var _this2 = this;

      var id = row.id;
      this.$confirm('是否确认删除模板名称为"' + row.templateName + '"的数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return (0, _carpool.deleteTemplateById)({
          templateId: id
        });
      }).then(function () {
        _this2.getList();

        _this2.msgSuccess("删除成功");
      }).catch(function () {});
    },
    // 打开车型模板弹框
    openScheduleCarConfig: function openScheduleCarConfig() {
      this.templateScheduleId = 0;
      this.scheduleCarConfigShow = true;
    },
    // 关闭车型模板弹框
    closeScheduleCarConfig: function closeScheduleCarConfig() {
      this.scheduleCarConfigShow = false;
      this.getList();
    }
  }
};
exports.default = _default;