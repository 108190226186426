"use strict";

var _interopRequireDefault = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _axios = _interopRequireDefault(require("axios"));

var _jsCookie = _interopRequireDefault(require("js-cookie"));

var _mapComponents = _interopRequireDefault(require("./components/mapComponents"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    mapComponents: _mapComponents.default
  },
  props: {
    openOperationScopeDialog: {
      type: Boolean,
      default: false
    },
    // lnglatData: {
    //   type: Array,
    //   default() {
    //     return [];
    //   }
    // },
    operationData: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      timeRefresh1: "",
      timeRefresh2: "",
      activeName: "first",
      rules: {
        electricFenceName: [{
          required: true,
          message: "请输入围栏名称",
          trigger: "blur"
        }, {
          min: 3,
          max: 15,
          message: "长度在 3 到 15 个字符",
          trigger: "blur"
        }],
        stations: [{
          required: true,
          message: "请输入活动区域",
          trigger: "blur"
        }]
      }
    };
  },
  methods: {
    reLoad: function reLoad() {
      console.log(this.activeName, "triggerReload");

      switch (this.activeName) {
        case "first":
          this.timeRefresh1 = new Date().getTime();
          break;

        case "second":
          this.timeRefresh2 = new Date().getTime();
          break;

        default:
          break;
      }
    },
    handleDialogClose: function handleDialogClose(formName) {
      _jsCookie.default.remove("fenceCityCode");

      _jsCookie.default.remove("fenceCountryCode");

      this.$emit("closepop"); //通知父组件改变。

      this.$emit("reloadList");
    }
  }
};
exports.default = _default;