"use strict";

var _interopRequireDefault = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.listConifg = listConifg;
exports.getUpdateConifg = getUpdateConifg;
exports.getConifg = getConifg;
exports.addConifg = addConifg;
exports.updateConifg = updateConifg;
exports.delConifg = delConifg;
exports.exportConifg = exportConifg;
exports.reportList = reportList;
exports.reportSum = reportSum;
exports.dailyList = dailyList;
exports.detailsList = detailsList;
exports.detailsSum = detailsSum;
exports.detailsExport = detailsExport;
exports.verifyExport = verifyExport;
exports.monthlyList = monthlyList;
exports.verifyUpdate = verifyUpdate;
exports.monthlyUpdate = monthlyUpdate;
exports.monthlySettle = monthlySettle;
exports.refundFn = refundFn;
exports.refundList = refundList;
exports.refundSum = refundSum;
exports.countList = countList;
exports.deliveryRefund = deliveryRefund;
exports.deliveryList = deliveryList;
exports.deliveryListUpdate = deliveryListUpdate;
exports.getInvoices = getInvoices;
exports.InvoicesExport = InvoicesExport;
exports.getOrderOfInvoice = getOrderOfInvoice;
exports.invoicing = invoicing;
exports.editInvoice = editInvoice;
exports.travelPDF = travelPDF;
exports.sendEmail = sendEmail;
exports.getInvoiceRecords = getInvoiceRecords;

var _request = _interopRequireDefault(require("@/utils/request"));

// 查询费率配置列表
function listConifg(params) {
  return (0, _request.default)({
    url: '/system/finance/config/bus/list',
    method: 'get',
    params: params
  });
} // 修改配置列表


function getUpdateConifg(params) {
  return (0, _request.default)({
    url: '/system/finance/config/bus/' + params,
    method: 'get'
  });
} // 查询费率配置详细


function getConifg(id) {
  return (0, _request.default)({
    url: '/system/conifg/' + id,
    method: 'get'
  });
} // 新增费率配置


function addConifg(data) {
  return (0, _request.default)({
    url: '/system/conifg',
    method: 'post',
    data: data
  });
} // 修改费率配置


function updateConifg(data) {
  return (0, _request.default)({
    url: '/system/finance/config/bus/update',
    method: 'post',
    data: data
  });
} // 删除费率配置


function delConifg(id) {
  return (0, _request.default)({
    url: '/system/conifg/' + id,
    method: 'delete'
  });
} // 导出费率配置


function exportConifg(params) {
  return (0, _request.default)({
    url: '/system/conifg/export',
    method: 'get',
    params: params
  });
} // 财务模块列表


function reportList(params) {
  return (0, _request.default)({
    url: '/system/finance/report/list',
    method: 'get',
    params: params
  });
} // 财务模块汇总列表


function reportSum(params) {
  return (0, _request.default)({
    url: '/system/finance/report/sum',
    method: 'get',
    params: params
  });
} // 日收支对账汇总


function dailyList(params) {
  return (0, _request.default)({
    url: '/system/finance/verify/daily/list',
    method: 'get',
    params: params
  });
} // 日收支对账明细


function detailsList(params) {
  return (0, _request.default)({
    url: '/system/finance/verify/daily/details/list',
    method: 'get',
    params: params
  });
} // 日收支对账明细汇总


function detailsSum(params) {
  return (0, _request.default)({
    url: '/system/finance/verify/daily/details/sum',
    method: 'get',
    params: params
  });
} // 描述：财务模块-收支对账明细导出接口


function detailsExport(data) {
  return (0, _request.default)({
    url: '/system/finance/verify/details/export',
    method: 'post',
    data: data
  });
} // 描述：财务模块-日收支对账明细导出接口


function verifyExport(data) {
  return (0, _request.default)({
    url: '/system/finance/verify',
    method: 'post',
    data: data
  });
} // 月收支对账明细汇总


function monthlyList(params) {
  return (0, _request.default)({
    url: '/system/finance/verify/monthly/list',
    method: 'get',
    params: params
  });
} // 月收支对账明细汇总 - 重新对账功能接口


function verifyUpdate(data) {
  return (0, _request.default)({
    url: '/system/finance/verify/daily/check',
    method: 'post',
    data: data
  });
} // 描述：财务模块-月度重新对账接口


function monthlyUpdate(data) {
  return (0, _request.default)({
    url: '/system/finance/verify/monthly/check',
    method: 'post',
    data: data
  });
} // 财务模块-结算接口


function monthlySettle(data) {
  return (0, _request.default)({
    url: '/system/finance/verify/monthly/settle',
    method: 'post',
    data: data
  });
} // 描述：财务汇总-异常订单处理-退款接口


function refundFn(data) {
  return (0, _request.default)({
    url: '/system/finance/error/refund',
    method: 'post',
    data: data
  });
} // 描述：财务汇总-异常订单处理-退款列表查询接口


function refundList(params) {
  return (0, _request.default)({
    url: '/system/finance/error/refund/list',
    method: 'get',
    params: params
  });
} // 描述：财务汇总-异常订单处理-退款汇总接口


function refundSum(params) {
  return (0, _request.default)({
    url: '/system/finance/error/refund/sum',
    method: 'get',
    params: params
  });
} // 财务模块-结算列表接口


function countList(params) {
  return (0, _request.default)({
    url: '/system/finance/settle/log/list',
    method: 'get',
    params: params
  });
} // 描述：小件物流退款接口


function deliveryRefund(data) {
  return (0, _request.default)({
    url: '/logistics/bus/refund',
    method: 'post',
    data: data
  });
} // 描述：小件物流列表接口


function deliveryList(params) {
  return (0, _request.default)({
    url: '/logistics/bus/list',
    method: 'get',
    params: params
  });
} // 描述：出入库操作


function deliveryListUpdate(data) {
  return (0, _request.default)({
    url: '/logistics/bus/update',
    method: 'post',
    data: data
  });
}
/* 发票模块 */
// 描述：获取发票列表


function getInvoices(params) {
  return (0, _request.default)({
    url: '/order/orderInvoice/getInvoices',
    method: 'get',
    params: params
  });
} // 描述：导出发票列表


function InvoicesExport(params) {
  return (0, _request.default)({
    url: '/order/orderInvoice/export',
    method: 'get',
    params: params
  });
} // 描述：获取发票中的订单列表


function getOrderOfInvoice(params) {
  return (0, _request.default)({
    url: '/order/orderInvoice/getOrderOfInvoice',
    method: 'get',
    params: params
  });
} // 描述：开票操作


function invoicing(data) {
  return (0, _request.default)({
    url: '/order/orderInvoice/invoicing',
    method: 'post',
    data: data
  });
} // 描述：修改开票操作


function editInvoice(data) {
  return (0, _request.default)({
    url: '/order/orderInvoice/editInvoice',
    method: 'post',
    data: data
  });
} // 描述：查看行程单（PDF）


function travelPDF(params) {
  return (0, _request.default)({
    responseType: 'blob',
    url: '/order/orderInvoice/travelPDF',
    method: 'get',
    params: params
  });
} // 描述：发送邮件


function sendEmail(params) {
  return (0, _request.default)({
    url: '/order/orderInvoice/sendEmail',
    method: 'get',
    params: params
  });
} // 描述：获取发票的修改记录


function getInvoiceRecords(params) {
  return (0, _request.default)({
    url: '/order/orderInvoice/getInvoiceRecords',
    method: 'get',
    params: params
  });
}