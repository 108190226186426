var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    [
      _c(
        "el-tabs",
        {
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "基本信息", name: "basic" } },
            [
              _c("basic-info-form", {
                ref: "basicInfo",
                attrs: { info: _vm.info }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            { attrs: { label: "字段信息", name: "cloum" } },
            [
              _c(
                "el-table",
                { attrs: { data: _vm.cloumns, "max-height": _vm.tableHeight } },
                [
                  _c("el-table-column", {
                    attrs: { label: "序号", type: "index", "min-width": "5%" }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "字段列名",
                      prop: "columnName",
                      "min-width": "10%",
                      "show-overflow-tooltip": true
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "字段描述", "min-width": "10%" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("el-input", {
                              model: {
                                value: scope.row.columnComment,
                                callback: function($$v) {
                                  _vm.$set(scope.row, "columnComment", $$v)
                                },
                                expression: "scope.row.columnComment"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "物理类型",
                      prop: "columnType",
                      "min-width": "10%",
                      "show-overflow-tooltip": true
                    }
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "Java类型", "min-width": "11%" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-select",
                              {
                                model: {
                                  value: scope.row.javaType,
                                  callback: function($$v) {
                                    _vm.$set(scope.row, "javaType", $$v)
                                  },
                                  expression: "scope.row.javaType"
                                }
                              },
                              [
                                _c("el-option", {
                                  attrs: { label: "Long", value: "Long" }
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: { label: "String", value: "String" }
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: { label: "Integer", value: "Integer" }
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: { label: "Double", value: "Double" }
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: {
                                    label: "BigDecimal",
                                    value: "BigDecimal"
                                  }
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: { label: "Date", value: "Date" }
                                })
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "java属性", "min-width": "10%" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("el-input", {
                              model: {
                                value: scope.row.javaField,
                                callback: function($$v) {
                                  _vm.$set(scope.row, "javaField", $$v)
                                },
                                expression: "scope.row.javaField"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "插入", "min-width": "5%" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("el-checkbox", {
                              attrs: { "true-label": "1" },
                              model: {
                                value: scope.row.isInsert,
                                callback: function($$v) {
                                  _vm.$set(scope.row, "isInsert", $$v)
                                },
                                expression: "scope.row.isInsert"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "编辑", "min-width": "5%" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("el-checkbox", {
                              attrs: { "true-label": "1" },
                              model: {
                                value: scope.row.isEdit,
                                callback: function($$v) {
                                  _vm.$set(scope.row, "isEdit", $$v)
                                },
                                expression: "scope.row.isEdit"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "列表", "min-width": "5%" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("el-checkbox", {
                              attrs: { "true-label": "1" },
                              model: {
                                value: scope.row.isList,
                                callback: function($$v) {
                                  _vm.$set(scope.row, "isList", $$v)
                                },
                                expression: "scope.row.isList"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "查询", "min-width": "5%" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("el-checkbox", {
                              attrs: { "true-label": "1" },
                              model: {
                                value: scope.row.isQuery,
                                callback: function($$v) {
                                  _vm.$set(scope.row, "isQuery", $$v)
                                },
                                expression: "scope.row.isQuery"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "查询方式", "min-width": "10%" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-select",
                              {
                                model: {
                                  value: scope.row.queryType,
                                  callback: function($$v) {
                                    _vm.$set(scope.row, "queryType", $$v)
                                  },
                                  expression: "scope.row.queryType"
                                }
                              },
                              [
                                _c("el-option", {
                                  attrs: { label: "=", value: "EQ" }
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: { label: "!=", value: "NE" }
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: { label: ">", value: "GT" }
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: { label: ">=", value: "GTE" }
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: { label: "<", value: "LT" }
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: { label: "<=", value: "LTE" }
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: { label: "LIKE", value: "LIKE" }
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: { label: "BETWEEN", value: "BETWEEN" }
                                })
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "必填", "min-width": "5%" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("el-checkbox", {
                              attrs: { "true-label": "1" },
                              model: {
                                value: scope.row.isRequired,
                                callback: function($$v) {
                                  _vm.$set(scope.row, "isRequired", $$v)
                                },
                                expression: "scope.row.isRequired"
                              }
                            })
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "显示类型", "min-width": "12%" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-select",
                              {
                                model: {
                                  value: scope.row.htmlType,
                                  callback: function($$v) {
                                    _vm.$set(scope.row, "htmlType", $$v)
                                  },
                                  expression: "scope.row.htmlType"
                                }
                              },
                              [
                                _c("el-option", {
                                  attrs: { label: "文本框", value: "input" }
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: { label: "文本域", value: "textarea" }
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: { label: "下拉框", value: "select" }
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: { label: "单选框", value: "radio" }
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: { label: "复选框", value: "checkbox" }
                                }),
                                _vm._v(" "),
                                _c("el-option", {
                                  attrs: {
                                    label: "日期控件",
                                    value: "datetime"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { label: "字典类型", "min-width": "12%" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  clearable: "",
                                  filterable: "",
                                  placeholder: "请选择"
                                },
                                model: {
                                  value: scope.row.dictType,
                                  callback: function($$v) {
                                    _vm.$set(scope.row, "dictType", $$v)
                                  },
                                  expression: "scope.row.dictType"
                                }
                              },
                              _vm._l(_vm.dictOptions, function(dict) {
                                return _c(
                                  "el-option",
                                  {
                                    key: dict.dictType,
                                    attrs: {
                                      label: dict.dictName,
                                      value: dict.dictType
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticStyle: { float: "left" } },
                                      [_vm._v(_vm._s(dict.dictName))]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: {
                                          float: "right",
                                          color: "#8492a6",
                                          "font-size": "13px"
                                        }
                                      },
                                      [_vm._v(_vm._s(dict.dictType))]
                                    )
                                  ]
                                )
                              }),
                              1
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            { attrs: { label: "生成信息", name: "genInfo" } },
            [
              _c("gen-info-form", { ref: "genInfo", attrs: { info: _vm.info } })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-form",
        { attrs: { "label-width": "100px" } },
        [
          _c(
            "el-form-item",
            {
              staticStyle: {
                "text-align": "center",
                "margin-left": "-100px",
                "margin-top": "10px"
              }
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.submitForm()
                    }
                  }
                },
                [_vm._v("提交")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      return _vm.close()
                    }
                  }
                },
                [_vm._v("返回")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }