var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-wrapper", class: _vm.classObj },
    [
      _vm.device === "mobile" && _vm.sidebar.opened
        ? _c("div", {
            staticClass: "drawer-bg",
            on: { click: _vm.handleClickOutside }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("sidebar", { staticClass: "sidebar-container" }),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "mainContainer",
          staticClass: "main-container",
          class: { hasTagsView: _vm.needTagsView }
        },
        [
          _c(
            "div",
            { class: { "fixed-header": _vm.fixedHeader } },
            [
              _c("navbar"),
              _vm._v(" "),
              _vm.needTagsView ? _c("tags-view") : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c("app-main"),
          _vm._v(" "),
          _vm.showSettings ? _c("right-panel", [_c("settings")], 1) : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            center: "",
            title: "班次余票不足提醒",
            visible: _vm.dialogVisible,
            "close-on-click-modal": false,
            width: "50%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            [
              _c("p", [
                _vm._v("系统检测到您的运营线路有班次余票不足，请及时前往查看")
              ]),
              _vm._v(" "),
              _c("p", { staticStyle: { "margin-top": "10px" } }, [
                _vm._v("线路名称：")
              ]),
              _vm._v(" "),
              _vm._l(_vm.remindLineList, function(item) {
                return _c("div", { staticStyle: { "margin-top": "10px" } }, [
                  _c("span", [_vm._v(_vm._s(item[0].lineName))]),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticStyle: { color: "green", "margin-left": "15px" },
                      on: {
                        click: function($event) {
                          return _vm.gotoLine(item)
                        }
                      }
                    },
                    [_vm._v("前往查看")]
                  )
                ])
              })
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogVisible = false
                    }
                  }
                },
                [_vm._v("知道了，稍后查看")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }