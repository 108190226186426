"use strict";

var _interopRequireDefault = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.listCoupon = listCoupon;
exports.listAllCoupon = listAllCoupon;
exports.getCoupon = getCoupon;
exports.addCoupon = addCoupon;
exports.updateCoupon = updateCoupon;
exports.delCoupon = delCoupon;
exports.sendCouponToUser = sendCouponToUser;
exports.sendCouponToUserList = sendCouponToUserList;
exports.couponDetail = couponDetail;
exports.couponDetailList = couponDetailList;
exports.couponDetailListExport = couponDetailListExport;
exports.carpoolCouponDetailList = carpoolCouponDetailList;
exports.carpoolCouponDetailListExport = carpoolCouponDetailListExport;
exports.listGift = listGift;
exports.getGift = getGift;
exports.addGift = addGift;
exports.updateGift = updateGift;
exports.delGift = delGift;
exports.selectList = selectList;
exports.sendGiftCouponToUser = sendGiftCouponToUser;
exports.sendGiftCouponToUserList = sendGiftCouponToUserList;

var _request = _interopRequireDefault(require("@/utils/request"));

// 查询优惠券列表
function listCoupon(query) {
  return (0, _request.default)({
    url: '/activity/coupon/list',
    method: 'get',
    params: query
  });
} // 查询所有优惠券列表


function listAllCoupon() {
  return (0, _request.default)({
    url: '/activity/coupon/getListNoPage',
    method: 'get'
  });
} // 查询优惠券详细


function getCoupon(id) {
  return (0, _request.default)({
    url: '/activity/coupon/' + id,
    method: 'get'
  });
} // 新增优惠券


function addCoupon(data) {
  return (0, _request.default)({
    url: '/activity/coupon',
    method: 'post',
    data: data
  });
} // 修改优惠券


function updateCoupon(data) {
  return (0, _request.default)({
    url: '/activity/coupon',
    method: 'put',
    data: data
  });
} // 删除优惠券


function delCoupon(id) {
  return (0, _request.default)({
    url: '/activity/coupon/' + id,
    method: 'delete'
  });
} // 发放优惠券


function sendCouponToUser(data) {
  return (0, _request.default)({
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    url: '/activity/userCoupon/sendCouponToUser',
    method: 'post',
    data: data
  });
} // 发放优惠券(批量导入)


function sendCouponToUserList(data) {
  return (0, _request.default)({
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    url: '/activity/userCoupon/sendCouponToUserList',
    method: 'post',
    data: data
  });
} // 优惠券详情


function couponDetail(params) {
  return (0, _request.default)({
    url: '/activity/coupon/couponDetail',
    method: 'get',
    params: params
  });
} // 优惠券详情领取列表


function couponDetailList(params) {
  return (0, _request.default)({
    url: '/activity/coupon/couponDetailList',
    method: 'get',
    params: params
  });
} // 优惠券详情领取列表导出


function couponDetailListExport(params) {
  return (0, _request.default)({
    url: '/activity/coupon/couponDetailListExport',
    method: 'get',
    params: params
  });
} // 拼车-优惠券详情领取列表


function carpoolCouponDetailList(params) {
  return (0, _request.default)({
    url: '/activity/coupon/carpoolCouponDetailList',
    method: 'get',
    params: params
  });
} // 拼车-优惠券详情领取列表导出


function carpoolCouponDetailListExport(params) {
  return (0, _request.default)({
    url: '/activity/coupon/carpoolCouponDetailListExport',
    method: 'get',
    params: params
  });
} // 查询礼包列表


function listGift(query) {
  return (0, _request.default)({
    url: '/activityCoupon/gift/list',
    method: 'get',
    params: query
  });
} // 查询礼包详细


function getGift(id) {
  return (0, _request.default)({
    url: '/activityCoupon/gift/' + id,
    method: 'get'
  });
} // 新增礼包


function addGift(data) {
  return (0, _request.default)({
    url: '/activityCoupon/gift',
    method: 'post',
    data: data
  });
} // 修改礼包


function updateGift(data) {
  return (0, _request.default)({
    url: '/activityCoupon/gift',
    method: 'put',
    data: data
  });
} // 删除礼包


function delGift(id) {
  return (0, _request.default)({
    url: '/activityCoupon/gift/' + id,
    method: 'delete'
  });
} // 查询所有优惠券礼包列表


function selectList() {
  return (0, _request.default)({
    url: '/activityCoupon/gift/selectList',
    method: 'get'
  });
} // 发放优惠券礼包


function sendGiftCouponToUser(data) {
  return (0, _request.default)({
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    url: '/activity/userCoupon/sendGiftCouponToUser',
    method: 'post',
    data: data
  });
} // 发放优惠券礼包(批量导入)


function sendGiftCouponToUserList(data) {
  return (0, _request.default)({
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    url: '/activity/userCoupon/sendGiftCouponToUserList',
    method: 'post',
    data: data
  });
}