"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  created: function created() {
    console.log(this.operationData);
  },
  props: {
    operationData: {
      type: Object,
      required: true
    }
  },
  data: function data() {
    return {
      key: "value"
    };
  },
  methods: {
    submitForm: function submitForm(formName) {
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    removeItem: function removeItem(item) {
      this.operationData.dataLists.splice(item, 1);
    },
    addDomain: function addDomain() {
      this.operationData.dataLists.push({
        startTime: "",
        endTime: "",
        scheduleType: "",
        serviceType: [],
        status: "",
        key: Date.now()
      });
    },
    resetForm: function resetForm(formName) {
      this.$refs[formName].resetFields();
    } // {
    //   this.$forceUpdate();
    //   this.$emit("reload", this.operationData);
    //   console.log(data, this.operationData);
    // }

  }
};
exports.default = _default;