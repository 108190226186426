"use strict";

var _interopRequireDefault = require("D:\\Projects\\hello-free-back\\node_modules\\@babel\\runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _axios = _interopRequireDefault(require("axios"));

var _elementUi = require("element-ui");

var _store = _interopRequireDefault(require("@/store"));

var _auth = require("@/utils/auth");

var pending = []; //声明一个数组用于存储每个请求的取消函数和axios标识

_axios.default.defaults.headers["Content-Type"] = "application/json;charset=utf-8"; // 创建axios实例

var service = _axios.default.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 60000
});

var cancelToken = _axios.default.CancelToken;

var removePending = function removePending(config) {
  // console.log(config);
  for (var i in pending) {
    if (pending[i].url === service.defaults.baseURL + config.url) {
      //在当前请求在数组中存在时执行取消函数
      pending[i].f(); //执行取消操作
      //pending.splice(i, 1); 根据具体情况决定是否在这里就把pending去掉

      console.log(pending[i].url);
    }
  }
}; // request拦截器


service.interceptors.request.use(function (config) {
  // console.log(service.defaults.baseURL + config.url);
  if ((0, _auth.getToken)()) {
    config.headers["Authorization"] = "Bearer " + (0, _auth.getToken)(); // 让每个请求携带自定义token 请根据实际情况自行修改
  }

  return config;
}, function (error) {
  console.log(error);
  Promise.reject(error);
}); // axios.interceptors.request.use(
//   config => {
//     // removePending(config); //在一个axios发送前执行一下判定操作，在removePending中执行取消操作
//     console.log(service.defaults.baseURL + config.url);
//     config.cancelToken = new cancelToken(function executor(c) {
//       //本次axios请求的配置添加cancelToken
//       pending.push({
//         url: service.defaults.baseURL + config.url,
//         f: c
//       });
//       //将本次的url添加到pending中，因此对于某个url第一次发起的请求不会被取消，因为还没有配置取消函数
//     });
//     if (getToken()) {
//       config.headers["Authorization"] = "Bearer " + getToken(); // 让每个请求携带自定义token 请根据实际情况自行修改
//     }
//     return Promise.resolve(config);
//   },
//   error => {
//     return Promise.reject(error);
//   }
// );
// 响应拦截器

service.interceptors.response.use(function (res) {
  var code = res.data.code;

  if (res.config.responseType == 'blob') {
    return res.data;
  }

  if (code === 401) {
    console.log(401, res);

    if (_auth.getToken) {
      _store.default.dispatch("LogOut").then(function () {
        setTimeout(function () {
          location.reload(); // 为了重新实例化vue-router对象 避免bug
        }, 2000);
      });
    }
  } else if (code !== 200) {
    (0, _elementUi.Message)({
      message: res.data.msg,
      type: "error",
      duration: 5 * 1000
    });
    return Promise.reject("error");
  } else {
    return res.data;
  }
}, function (error) {
  console.log("err" + error);
  (0, _elementUi.Message)({
    message: error.message,
    type: "error",
    duration: 5 * 1000
  });
  return Promise.reject(error);
});
var _default = service;
exports.default = _default;